import * as React from 'react'

import get from 'lodash-es/get'
import { connectHighlight, HighlightProps } from 'react-instantsearch-core'
import { ICompositeMessage } from '../../../models/algolia/composite'
import { IMessageDoc } from '../../../models/algolia/message'

type Speakers =
  IMessageDoc['speakers']
  | ICompositeMessage['Message']['speakers']

interface IProps extends HighlightProps {
  /** The number of scripture references to show.  No limit if left blank. */
  limit?: number
}

class SpeakersHighlight extends React.PureComponent<IProps> {
  public render() {
    const {
      hit,
      attribute,
      highlight,
      limit,
    } = this.props
    const highlightProperty = this.props.highlightProperty || '_highlightResult'

    let speakers: Speakers = get(hit, attribute, [])

    if (limit) {
      speakers = speakers.slice(0, limit)
    }

    const elements: React.ReactNode[] = []
    function pushParts(att: string, fallback: string) {
      const parts = highlight({
        attribute: att,
        hit,
        highlightProperty,
      })
      if (!parts || parts.length == 0) {
        elements.push(<span key={att + '-part'}>{fallback}</span>)
      } else {
        parts.forEach((item, i) => {
          if (item.isHighlighted) {
            elements.push(
              <em key={att + '-part-' + i}>{item.value}</em>,
            )
          } else {
            elements.push(item.value)
          }
        })
      }
    }

    for (let i = 0; i < speakers.length; i++) {
      if (i > 0) { elements.push('; ')}

      const speaker = speakers[i]
      if ('name' in speaker) {
        pushParts(attribute + `.[${i}].name`, speaker.name)
      } else if ('speaker_name' in speaker) {
        pushParts(attribute + `.[${i}].speaker_name`, speaker.speaker_name)
      } else {
        raiseUnexpectedSpeaker(speaker, attribute)
      }
    }

    return <>{elements}</>
  }
}

export default connectHighlight(SpeakersHighlight)

function raiseUnexpectedSpeaker(speaker: never, attribute: string): never {
  throw new Error(`Unexpected '${attribute}', object has no 'name' or 'speaker_name': ${speaker}`)
}
