import * as React from 'react'
import WCC from 'wcc'

import addSeconds from 'date-fns/addSeconds'
import format from '../../date/format'
import { eachDayOfOccurrence } from '../../models/events'
import { IEventOccurrence, IFeaturedEventOccurrence } from '../../models/events'
import { buildAnalyticsAttributes } from '../algolia-search/insights'

type IProps = {
  className?: string,

  title?: JSX.Element | string
  hit?: { objectID: string },
} & (
  {
    featured: true,
    event: IFeaturedEventOccurrence,
  } | {
    featured?: false,
    event: IEventOccurrence,
  }
)

const campusText = (event: IEventOccurrence | IFeaturedEventOccurrence ) => {
  if (event.campus) {
    return event.campus.id == 1 ? null : <>
      Watermark {event.campus.name}
      <span className='bullet'>&nbsp;•&nbsp;</span>
    </>
  } else {
    return (
      <>
        Off Campus
        <span className='bullet'>&nbsp;•&nbsp;</span>
      </>
    )
  }
}

export default class Event extends React.PureComponent<IProps> {
  public render() {
    const { event, hit } = this.props
    const title = this.props.title || event.title
    const ts = new Date(event.time)
    const endTime = addSeconds(ts, event.duration)
    const past = endTime.getTime() < Date.now()
    const started = !past && ts.getTime() < Date.now()

    const allDays = eachDayOfOccurrence(event)
    const multiday = allDays.length > 1

    const eventIndex = (event.series && event.series.events || [])
      .findIndex((e) => e.id.toString() == event.id.toString())

    const ministry = event.ministry_code ? WCC.ministries[event.ministry_code] : undefined

    return <a
      className={
        `a-block card widget-events-list__event-card
          ${this.props.featured && 'featured-event'}
          ${past && 'past-date'}
          ${started && 'started'}
          ${ this.props.className }`
      }
      href={this.href()}
      key={`event-${event.id}`}
      style={{
        backgroundImage: this.props.featured ?
          `url('${this.props.event.images.featured && this.props.event.images.featured.url}')`
          : undefined,
      }}
      {...(hit && buildAnalyticsAttributes(hit))}
    >
      <div className="row">
        <div className="col widget-events-list__event-card__overline">
          {this.props.featured &&
              <span className="overline">
                featured event
              </span>}
        </div>
      </div>
      <div className="row">
        <div className="col widget-events-list__event-card__date">
          <div className="widget-events-list__event-card__date-box">
            <h6 className="widget-events-list__event-card__date-box__month">{ format(event.time, 'MMM') }</h6>
            <h6 className="widget-events-list__event-card__date-box__day">{ format(event.time, 'dd') }</h6>
          </div>
        </div>
        <div className={
          `col widget-events-list__event-card__meta-box ${this.cancelledClassName() || this.registrationClassName()}`
        }>
          <span className="overline">
            { ministry && ministry.title }&nbsp;
          </span>
          <h5 className="event-title">
            { title }&nbsp;
            <span className="cancelled-notice">(Canceled)</span>
            <span className="closed-notice">(Registration Closed)</span>
          </h5>
          <span className="widget-events-list__event-card__meta-box__data-point event-time">
            { multiday ?
              [
                format(allDays[0], 'iiii, MMM d, yyyy'),
                format(allDays[allDays.length - 1], 'iiii, MMM d, yyyy'),
              ].join(' - ') :
              [ format(allDays[0], 'eeee'),
                format(event.time, 'h:mm a')
              ].join(', ')
            }
            <span className='bullet'>&nbsp;•&nbsp;</span>
          </span>
          { !(event.flags && event.flags.ongoing) && event.series.count > 1 &&
              <span className="widget-events-list__event-card__meta-box__data-point event-location">
                Event {eventIndex + 1} of {event.series.count}
                <span className='bullet'>&nbsp;•&nbsp;</span>
              </span>
          }
          <span className="widget-events-list__event-card__meta-box__data-point event-campus">
            { campusText(event) }
          </span>
          { event.campus &&
              <span className="widget-events-list__event-card__meta-box__data-point event-location">
                { event.location.area.name }
              </span>
          }
        </div>
        <div className="col col-md-3 d-none d-md-block text-center widget-events-list__event-card__view-details">
          {past ?
            <span className="btn btn-sand">Event Finished</span>
            : started ?
              <span className="btn btn-sand">In Progress</span>
              : <span className="btn btn-sand">View Details</span>}
        </div>
      </div>
    </a>
  }

  private href() {
    return `/events/${this.props.event.series.id}-${this.slug()}`
  }

  private slug() {
    const match = this.props.event.slug.match(/\d-(.+)/)

    return (match && match[1]) || ''
  }

  private cancelledClassName() {
    const cancellation = this.props.event.cancellation

    return cancellation ? 'event-cancelled' : null
  }

  private registrationClassName() {
    const reg = this.props.event.registrations && this.props.event.registrations.event
    const now = new Date()

    if (!reg) { return }

    if (reg.opens && (now < new Date(reg.opens))) {
      return 'registration-not-yet-open'
    } else if (reg.closes && now > new Date(reg.closes)) {
      return 'registration-closed'
    } else {
      return 'registration-open'
    }
  }
}
