import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { connectInfiniteHits } from 'react-instantsearch-core'
import { ICompositePost } from '../../models/algolia/composite'
import ConnectedGalleryTableHit from './table-hit'

interface IConnectInfiniteHitsProps {
  hits: Array<Hit<ICompositePost>>
  hasMore?: any
  refineNext?: any
}

const BlogGalleryTableLayout = (({ hits, hasMore, refineNext }: IConnectInfiniteHitsProps) => (
  <div className="infinite-hits w-100 table-view">
    {hits.map((hit) => {
      if (hit.Post) {
        return <ConnectedGalleryTableHit hit={hit} key={hit.objectID} />
      }
    })}
    {hasMore &&
      <div className="d-flex justify-content-center load-more">
        <button className="btn btn-link" onClick={refineNext}>
          MORE
        </button>
      </div>}
  </div>
))

const ConnectedBlogGalleryTableLayout = connectInfiniteHits(BlogGalleryTableLayout)
export default ConnectedBlogGalleryTableLayout
