import template from 'lodash-es/template'
import * as React from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

import { getCampus } from '../../../application/campus'
import { Stringable } from '../../util'
import { IRefinementDefinition } from './tab'

interface IProps {
  defaultFacets: IRefinementDefinition
}

const VirtualRefinementList = connectRefinementList(() => null)

class DefaultFacets extends React.Component<IProps> {

  public render() {
    const { defaultFacets } = this.props

    return <>
      {Object.keys(defaultFacets).map((key) =>
        this.renderFacet(key, defaultFacets[key]))}
    </>
  }

  private renderFacet = (key: string, value: Stringable | Stringable[]) => {
    const defaultRefinement = Array.isArray(value) ? value : [value]
    return <VirtualRefinementList
      key={key}
      attribute={key}
      defaultRefinement={defaultRefinement
        .map((r) => r.toString())
        .map(transformRefinementValue)
        .filter((r) => r && /\S/.test(r))} />
  }
}

const transformRefinementValue = (value: string) => {
  const currentCampus = getCampus()
  return template(value)({
    current_campus_name: currentCampus && currentCampus.name,
    current_campus_code: currentCampus && currentCampus.code,
    current_campus_slug: currentCampus && currentCampus.slug,
    current_campus: currentCampus,
  })
}

export default DefaultFacets
