import * as React from 'react'

import { connectRefinementList } from 'react-instantsearch-core'
import { withTranslations } from '../../../connectors/translation'
import { Reject } from '../../../util/reject'
import { Props as SortedTableViewProps, SortedTableView } from './sorted-table-view'

type Props = Reject<SortedTableViewProps, 'sortItems'>

export class ScriptureTableView extends React.Component<Props> {
  public render() {

    return <SortedTableView {...this.props}
      sortItems={(items) => items.sort(byScripturePosition)} />
  }
}

export default connectRefinementList(
  withTranslations(ScriptureTableView),
)

function byScripturePosition(a: { label: string }, b: { label: string }): number {
  const aIndex = booksInOrder.indexOf(a.label.toLowerCase())
  const bIndex = booksInOrder.indexOf(b.label.toLowerCase())
  return aIndex - bIndex
}

const booksInOrder = [
  'genesis',
  'exodus',
  'leviticus',
  'numbers',
  'deuteronomy',
  'joshua',
  'judges',
  'ruth',
  '1 samuel',
  '2 samuel',
  '1 kings',
  '2 kings',
  '1 chronicles',
  '2 chronicles',
  'ezra',
  'nehemiah',
  'esther',
  'job',
  'psalms',
  'proverbs',
  'ecclesiastes',
  'song of solomon',
  'isaiah',
  'jeremiah',
  'lamentations',
  'ezekiel',
  'daniel',
  'hosea',
  'joel',
  'amos',
  'obadiah',
  'jonah',
  'micah',
  'nahum',
  'habakkuk',
  'zephaniah',
  'haggai',
  'zechariah',
  'malachi',
  'matthew',
  'mark',
  'luke',
  'john',
  'acts',
  'romans',
  '1 corinthians',
  '2 corinthians',
  'galatians',
  'ephesians',
  'philippians',
  'colossians',
  '1 thessalonians',
  '2 thessalonians',
  '1 timothy',
  '2 timothy',
  'titus',
  'philemon',
  'hebrews',
  'james',
  '1 peter',
  '2 peter',
  '1 john',
  '2 john',
  '3 john',
  'jude',
  'revelation',
]
