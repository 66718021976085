import setMilliseconds from 'date-fns/setMilliseconds'
import setSeconds from 'date-fns/setSeconds'
import * as React from 'react'

const round = (time: Date): Date =>
  setMilliseconds(time, 0)

const now = (): Date =>
  round(new Date())

const topOfMinute = (time: Date): Date =>
  setSeconds(round(time), 0)

const pluralize = (label: string, n: number): string =>
  n == 1 && label || `${label}s`

function timeExpired(time: Date | null): boolean {
  if (!time) { return true }

  return time < now()
}

class CountdownContainer extends React.PureComponent {
  public render() {
    return <div className="countdown-timer__wrapper">
      <div className="countdown-timer__container justify-content-center">
        { this.props.children }
      </div>
    </div>
  }
}

export {
  now,
  pluralize,
  round,
  topOfMinute,

  timeExpired,

  CountdownContainer,
}
