import * as React from 'react'
import {
  connectStateResults,
  StateResultsProvided,
} from 'react-instantsearch-core'
import ConnectedBlogGalleryBlockLayout from '../blog-gallery/block-layout'
import ConnectedSearchReset from '../search-reset'
import ConnectedHrefModifier from '../SiteSearch/href-modifier'
import { isMobile } from '../SiteSearch/tab/views/utils'
import ConnectedBlogGalleryTableLayout from './table-layout'

interface IProps {
  setHasFacets: (facetsPresent: boolean) => void

  translations: {
    noResults: string,
    searchReset: string,
    blog_facet_select: {
      see_all_option: string,
    },
    searchBox: {
      placeholder: string,
    },
  },
}

const hasSearchQuery = (searchState: StateResultsProvided['searchState']): boolean => (
  searchState.query ? true : false
)

const hasSelectedFacet = (searchState: StateResultsProvided['searchState']): boolean => {
  if (!searchState.menu) { return false }
  if (searchState.menu['Post.categories.title'] &&
    searchState.menu['Post.categories.title'].length > 0) {
    return true
  } else {
    return false
  }
}

const hasSearchState = (searchState?: StateResultsProvided['searchState']): boolean => {
  if (!searchState) { return false }
  return hasSearchQuery(searchState) || hasSelectedFacet(searchState)
}

const hasSearchResults = (searchResults?: StateResultsProvided['searchResults']): boolean => {
  if (!searchResults) { return false }
  return searchResults.nbHits > 0
}

const belowSearchSectionsArray = (): HTMLElement[] => {
  if (typeof document == 'undefined') {
    // in ssr context
    return []
  }
  return Array.from(document.querySelectorAll('.below-blog-search-sections'))
}

const hideBelowSearchSections = () => {
  const belowSearchSections = belowSearchSectionsArray()
  return belowSearchSections.length > 0 &&
    belowSearchSections.forEach((belowSearchSection) => {
      belowSearchSection.style.display = 'none'
    })
}

const showBelowSearchSections = () => {
  const belowSearchSections = belowSearchSectionsArray()
  return belowSearchSections.length > 0 &&
    belowSearchSections.forEach((belowSearchSection) => {
      belowSearchSection.style.display = 'block'
    })
}

const hasFacets = (searchResults?: StateResultsProvided['searchResults']): boolean => {
  if (!searchResults) { return true }
  return searchResults.disjunctiveFacets.length > 1
}

const renderBlogs = () => {
  if (isMobile()) {
    return <ConnectedBlogGalleryBlockLayout columnClasses={['col-12']} />
  } else {
    return <ConnectedBlogGalleryTableLayout />
  }
}

class SearchResults extends React.PureComponent<StateResultsProvided & IProps> {
  public render() {
    const { searchState, searchResults, setHasFacets, translations } = this.props

    setHasFacets(hasFacets(searchResults))

    if (!hasSearchState(searchState)) {
      showBelowSearchSections()
      return null
    }
    if (!hasSearchResults(searchResults)) {
      hideBelowSearchSections()

      return (
        <ConnectedSearchReset
          translations={{
            searchResetMsg: translations.noResults,
            searchResetAction: translations.searchReset,
          }} />
      )
    }

    hideBelowSearchSections()
    return (
      <div className="row justify-content-center">
        <div className="col-content-container">
          <div className="row">
            <div className="col-12">
              <ConnectedHrefModifier>
                {renderBlogs()}
              </ConnectedHrefModifier>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedSearchResults = connectStateResults(SearchResults)

export default ConnectedSearchResults
