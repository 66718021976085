import * as React from 'react'
import WCC from 'wcc'

import addSeconds from 'date-fns/addSeconds'
import format from '../../date/format'
import { parseDateISOString } from '../../date/parse'
import { eachDayOfOccurrence } from '../../models/events'
import { IEventOccurrence, IFeaturedEventOccurrence } from '../../models/events'
import { buildAnalyticsAttributes } from '../algolia-search/insights'

type IProps = {
  className?: string,

  title?: JSX.Element | string
  hit?: { objectID: string },
} & (
  {
    featured: true,
    event: IFeaturedEventOccurrence,
  } | {
    featured?: false,
    event: IEventOccurrence,
  }
)

interface IRegistration {
  className: string,
  text: string,
}

export default class Event extends React.PureComponent<IProps> {
  public render() {
    const { event, hit } = this.props
    const title = this.props.title || event.title
    const ts = parseDateISOString(event.time)
    const endTime = addSeconds(ts, event.duration)
    const past = endTime.getTime() < Date.now()
    const started = !past && ts.getTime() < Date.now()

    const allDays = eachDayOfOccurrence(event)
    const multiday = allDays.length > 1

    const registration = this.registrationClassName()

    const ministry = event.ministry_code ? WCC.ministries[event.ministry_code] : undefined

    return <a
      className={
        `a-block card widget-events-card-deck__event-card
          ${this.props.featured && 'featured-event'}
          ${past && 'past-date'}
          ${started && 'started'}
          ${ this.props.className }`
      }
      href={this.href()}
      key={`event-${event.id}`}
      {...(hit && buildAnalyticsAttributes(hit))}
    >
      <div className={`widget-events-card-deck__event-card-info ${this.cancelledClassName()}`}>
        <div>
          <span className='overline'>
            { ministry && ministry.title }&nbsp;
          </span>
          <h5 className='event-title'>
            { title }&nbsp;
            <span className='cancelled-notice'>(Canceled)</span>
            <span className='closed-notice'>(Registration Closed)</span>
          </h5>
          <p className='event-description'>{event.snippet}&nbsp;</p>
        </div>
        <div className='event-info'>
          <span className='interface-label event-info__label'>
            {
              [
                multiday ?
                  [
                    format(allDays[0], 'iiii, MMM d, yyyy'),
                    format(allDays[allDays.length - 1], 'iiii, MMM d, yyyy'),
                  ].join(' - ') :
                  [
                    format(event.time, 'iiii, MMM d'),
                    format(event.time, 'h:mm a'),
                  ].join(' • '),
              ].filter(Boolean).join(' • ')
            }
            {
              registration ? <span><span className='bullet'> • </span><span className={registration.className}>{registration.text}</span></span> : null
            }
          </span>
          <i className='material-icons'>arrow_forward</i>
        </div>
      </div>
    </a>

  }

  private href() {
    return `/events/${this.props.event.series.id}-${this.slug()}`
  }

  private slug() {
    const match = this.props.event.slug.match(/\d-(.+)/)

    return (match && match[1]) || ''
  }

  private cancelledClassName() {
    const cancellation = this.props.event.cancellation

    return cancellation ? 'event-cancelled' : null
  }

  private registrationClassName(): IRegistration | null {
    const reg = this.props.event.registrations && this.props.event.registrations.event
    const now = new Date()

    if (!reg) { return null }

    if (reg.opens && (now < new Date(reg.opens))) {
      return { className: 'registration-not-yet-open', text: 'Registration required' }
    } else if (reg.closes && now > new Date(reg.closes)) {
      return { className: 'registration-closed', text: 'Registration closed' }
    } else {
      return { className: 'registration-open', text: 'Registration required' }
    }
  }
}
