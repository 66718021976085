import * as React from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'
import { withTranslations } from '../../../connectors/translation'
import { Reject } from '../../../util/reject'
import { Props as SortedTableViewProps, SortedTableView } from './sorted-table-view'

type Props = Reject<SortedTableViewProps, 'sortItems'>

export class AlphabeticalTableView extends React.Component<Props> {
  public render() {
    const {
      ...props
    } = this.props

    return <SortedTableView {...props} sortItems={this.sortItems} />
  }

  private sortItems: SortedTableViewProps['sortItems'] = (slicedItems, _allItems) => {
    return slicedItems.sort(byLabel)
  }
}

export default connectRefinementList(
  withTranslations(AlphabeticalTableView),
)

function byLabel(a: { label: string }, b: { label: string }): number {
  return a.label.localeCompare(b.label)
}
