import * as React from 'react'

import differenceInDays from 'date-fns/differenceInDays'
import startOfDay from 'date-fns/startOfDay'
import { Meter } from './meter'

interface ITimelineMeter {
  startDate: string
  endDate: string
  text: string
  class?: string | null
}

export class TimelineMeter extends React.Component<ITimelineMeter> {
  public render() {
    const { startDate, endDate, text } = this.props
    const start = startOfDay(new Date(startDate))
    const end = startOfDay(new Date(endDate))
    const today = startOfDay(new Date())
    return (
      <Meter
        primaryLabel={`${this.daysLeft(today, start, end)} days`}
        secondaryLabel={text}
        class={this.props.class}
        percent={this.percentage(today, start, end)}
      />
    )
  }

  private daysLeft(today: Date, startDate: Date, endDate: Date): number {
    const beginning = startDate > today ? startDate : today
    const daysLeft = differenceInDays(
      endDate,
      beginning,
    )

    return daysLeft > 0 ? daysLeft : 0
  }

  private percentage(today: Date, startDate: Date, endDate: Date): number {
    const totalDays = differenceInDays(
      endDate,
      startDate,
    )

    const daysPast = differenceInDays(
      today,
      startDate,
    )

    return (daysPast / totalDays) * 100
  }
}
