
export function parameterize(str: string, sep = '-') {
  // Turn unwanted chars into the separator
  str = str.replace(/[^a-z0-9\-_]+/gi, sep)
  if (sep && sep.length > 0) {
    const sepRegExp = escapeRegExp(sep)
    // No more than one of the separator in a row.
    str = str.replace(new RegExp(`${sepRegExp}{2,}`, 'g'), sep)
    // Remove leading/trailing separator.
    str = str.replace(new RegExp(`^${sepRegExp}|${sepRegExp}$`, 'g'), '')
  }
  return str.toLowerCase()
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
