import React from 'react'
import ReactDOM from 'react-dom'
import 'react_ujs'

// override mount components to make it async
// const oldMountComponents = ReactRailsUJS.mountComponents
const NewReactRailsUJS =
  Object.assign(window.ReactRailsUJS,
    {
      mountComponents(searchSelector: string): Promise<void[]> {
        const ujs = NewReactRailsUJS
        const nodes = Array.from(ujs.findDOMNodes(searchSelector))

        const tasks = nodes.map((node: any) => {
          const className = node.getAttribute(ujs.CLASS_NAME_ATTR)
          // could be loaded async from a 'lazy' require context
          const lazyConstructor = Promise.resolve(ujs.getConstructor(className))

          return lazyConstructor
            .then((constructor) => {
              const propsJson = node.getAttribute(ujs.PROPS_ATTR)
              const props = propsJson && JSON.parse(propsJson)
              const hydrate = node.getAttribute(ujs.RENDER_ATTR)

              if (!constructor) {
                const message = 'Cannot find component: \'' + className + '\''
                if (console && console.log) {
                  console.log('%c[react-rails] %c' + message + ' for element', 'font-weight: bold', '', node)
                }
                throw new Error(message + '. Make sure your component is available to render.')
              } else {
                if (hydrate && typeof ReactDOM.hydrate === 'function') {
                  ReactDOM.hydrate(React.createElement(constructor, props), node)
                } else {
                  ReactDOM.render(React.createElement(constructor, props), node)
                }
              }
            })
            .catch((err) => {
              const message = 'Error loading component: \'' + className + '\': \n' + err
              if (console && console.log) {
                console.log('%c[react-rails] %c' + message + ' for element', 'font-weight: bold', '', node)
              }
              throw new Error(message)
            })
        })

        return Promise.all(tasks)
      },
    })

export default NewReactRailsUJS
