import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { Stringable } from '../../util'
import AlgoliaSearch from '../algolia-search'
import ConnectedBlogGalleryTableLayout from '../blog-search/table-layout'
import HiddenFacets from '../SiteSearch/hidden-facets'
import { isMobile } from '../SiteSearch/tab/views/utils'
import ConnectedBlogGalleryBlockLayout from './block-layout'

interface IProps extends ITranslationsProvided {
  blogSource: BlogSource,

  layout?: 'block' | 'table'
  header?: string | boolean

  /**
   * Specify the number of columns at each breakpoint.  This is turned into
   * bootstrap column specifiers by taking 12 / #columns.
   *
   * Examples:
   * 2 => col-6
   * { xs: 3, md: 2 } => col-4 col-md-6
   * { xs: 10.0/12.0, xl: 3 } => col-10 col-xl-4
   */
  columns?: number | { xs: number, [key: string]: number  }

  hitsPerPage?: number

  viewAllLink?: string
}

type BlogSource =
  IAlgoliaBlogSource
// | IPlaylistBlogSource

interface IAlgoliaBlogSource {
  source: 'Algolia'
  index: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable,
  }
  operator?: 'or' | 'and'
}

export class BlogGallery extends React.Component<IProps> {
  public render() {
    const { blogSource, header, viewAllLink, t } = this.props

    return <AlgoliaSearch indexName={blogSource.index} widgetName="blog-gallery">
      <Configure analytics={false} />
      <HiddenFacets hiddenFacets={{ object_type: 'Post' }} />
      {blogSource.facetFilters &&
        <HiddenFacets hiddenFacets={blogSource.facetFilters}
          operator={blogSource.operator} />}

      <div className="row justify-content-center">
        <div className="col-content-container">
          <div className="row">
            <div className="col-12">
              <div className="widget-blog-gallery__header">
                {header !== false &&
                  <h2>{typeof header == 'string' ? header : t('header')}</h2>}
                {viewAllLink &&
                  <a href={viewAllLink} className="btn btn-link">View All</a>}
              </div>
              {this.renderLayout()}
            </div>
          </div>
        </div>
      </div>
    </AlgoliaSearch>
  }

  private renderLayout = () => {
    const { hitsPerPage } = this.props
    let { layout } = this.props

    let columns: string[] | undefined =
      typeof this.props.columns == 'number' ?
        [`col-${Math.round(12 / this.props.columns)}`] :
        this.props.columns && parseColumns(this.props.columns)

    // backwards compat
    //   previous location of layout was inside blogSource
    layout = layout || (this.props.blogSource && (this.props.blogSource as any).layout)
    //   previous param was "colsWithoutBreakpoint"
    if (!columns) {
      const { colsWithoutBreakpoint } = this.props as any
      columns = colsWithoutBreakpoint && [`col-${colsWithoutBreakpoint}`]
    }

    if (layout && layout === 'table') {
      return (
        <>
          <Configure hitsPerPage={hitsPerPage || 9} />
          {this.renderTableBlogs(columns)}
        </>
      )
    } else {
      return (
        <>
          <Configure hitsPerPage={hitsPerPage || 6} />
          <ConnectedBlogGalleryBlockLayout columnClasses={columns} />
        </>
      )
    }
  }

  private renderTableBlogs = (columns?: string[]) => {
    if (isMobile()) {
      return <ConnectedBlogGalleryBlockLayout columnClasses={columns} />
    } else {
      return <ConnectedBlogGalleryTableLayout />
    }
  }
}

const ConnectedBlogGallery = withTranslations(BlogGallery)
export default ConnectedBlogGallery

function parseColumns(colspec: { xs: number, [key: string]: number  }): string[] {
  const cols = [] as string[]

  Object.keys(colspec).forEach((key: keyof typeof colspec) => {
    if (key == 'xs') {
      cols.push(`col-${Math.round(12 / colspec.xs)}`)
    } else {
      cols.push(`col-${key}-${Math.round(12 / colspec[key])}`)
    }
  })

  return cols
}
