import startOfDay from 'date-fns/startOfDay'
import template from 'lodash-es/template'
import { createConnector } from 'react-instantsearch-dom'

interface IExposed {
  hiddenNumericFilters: string[]
}

type Operator = '=' | '>' | '>=' | '<' | '<=' | '!='

const filterPattern = /(.+)(=|>|>=|<|<=|!=)(.+)/

// These values have to be stable during pagination of results, otherwise the
// infinite search results will discard all previous pages thinking that it's a
// new search.
// This ensures that the calculation is done once per page-load, when this
// module is imported.
const nowValues = {
  now: Date.now() / 1000,
  today: startOfDay(Date.now()).getTime() / 1000,
}

const connectHiddenNumericFilters = createConnector<Record<string, never>, IExposed>({
  displayName: 'TabHiddenNumericFilter',
  getProvidedProps() {
    return {}
  },
  getSearchParameters(searchParameters, props, _state) {
    const {hiddenNumericFilters} = props

    if (!hiddenNumericFilters) {
      return searchParameters
    }

    for (const filter of hiddenNumericFilters) {
      const templatedFilter = template(filter)(
        nowValues,
      )
      const result = filterPattern.exec(templatedFilter)
      if (!result) {
        continue
      }

      const [_, attribute, operator, value] = result

      searchParameters = searchParameters.addNumericRefinement(
        attribute,
        operator as Operator,
        value as unknown as number,
      )
    }
    return searchParameters
  },
})

const HiddenNumericFilters = connectHiddenNumericFilters(() => null)
export default HiddenNumericFilters
