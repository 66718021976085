import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { WatermarkMediaDefault } from '@watermarkchurch/react-instantsearch-components'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import format from '../../date/format'
import { parseDateISOString } from '../../date/parse'
import { ICompositePost } from '../../models/algolia/composite'
import { exists } from '../../util'
import { HitBlockCard } from '../SiteSearch/hits/hit-block-card'

interface IProps extends ITranslationsProvided {
  hit: Hit<ICompositePost>
}

export class BlogGalleryBlockHit extends React.Component<IProps> {

  public render() {
    const { hit, t } = this.props
    const {
      date,
      slug,
      title,
      subtitle,
      summary,
      time_to_read,
      hero_image_url,
      hero_image_alt_text,
      author,
    } = hit.Post

    return <HitBlockCard
      hit={hit}
      className="widget-media-gallery__hit-block message-card"
      slug={slug}
      thumbnailUrl={hero_image_url || WatermarkMediaDefault}
      thumbnailAlt={hero_image_alt_text}
    >
      {title && <h3 className="card-title">{title}</h3>}
      {subtitle && <h6 className="card-subtitle">{subtitle}</h6>}
      {summary && <span className="summary">{summary}</span>}
      <span className="media-caption">
        {[
          author && author.full_name,
          date && this.formatDate(parseDateISOString(date)),
          time_to_read && time_to_read > 0 && t('time_to_read', {count: time_to_read}),
        ].filter(exists).join(' • ')}
      </span>
    </HitBlockCard>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, t('dateFormat'))
  }
}

const DEFAULT_MSG_DATE_FORMAT = 'MMMM d, yyyy'

const ConnectedGalleryBlockHit = withTranslations(
  BlogGalleryBlockHit,
  {
    dateFormat: DEFAULT_MSG_DATE_FORMAT,
  })

export default ConnectedGalleryBlockHit
