import { TapEventHandler } from 'bootstrap'
import * as React from 'react'

export interface ITabNavItemProps {
  id: string
  bookmark?: string
  active?: boolean
  hidden?: boolean

  storeState: boolean | 'hash' | 'path'

  translations?: {
    header?: string,
    mobileHeader?: string,
  }

  onTabSelected?: (name: string) => any

  /** injectable window for tests - defaults to global window */
  _window: Window
}

export class TabNavItem extends React.PureComponent<ITabNavItemProps> {
  public static defaultProps: Partial<ITabNavItemProps> = {
    storeState: false,
    _window: typeof(window) != 'undefined' ? window : undefined,
  }
  public anchorRef: React.RefObject<HTMLAnchorElement>

  constructor(props: TabNavItem['props'], context?: any) {
    super(props, context)

    this.anchorRef = React.createRef<HTMLAnchorElement>()
  }

  public componentDidMount = () => {
    const {_window} = this.props
    const current = this.anchorRef.current
    if (!current) { return }

    $(current).on('shown.bs.tab', this.onTabSelected)
    if (this.storeStateInHash()) {
      $(_window).on('hashchange', this.hashChangeHandler)
      this.hashChangeHandler()
    }
    if (this.storeStateInPath()) {
      _window.addEventListener('popstate', this.onPopStateHandler)
      this.onPopStateHandler()
    }
  }

  public componentWillUnmount = () => {
    const {_window} = this.props
    if (this.storeStateInHash()) {
      $(_window).on('hashchange', this.hashChangeHandler)
    }
    if (this.storeStateInPath()) {
      _window.removeEventListener('popstate', this.onPopStateHandler)
    }
  }

  public render() {
    const { id, translations, active, hidden } = this.props
    const bookmark = this.props.bookmark || this.props.id
    const mobileHeader = translations && (translations.mobileHeader || translations.header)
    const header = translations && translations.header

    return (
      <li className="nav-item" style={{ display: hidden ? 'none' : undefined }}>
        <a ref={this.anchorRef} className={`nav-link ${active ? 'active' : ''}`}
          data-toggle="tab" role="tab" aria-controls={`#${id}-tab`} aria-selected={active}
          id={`${id}-tab-nav`} href={`#${bookmark}`} data-bookmark={bookmark}
        >
          <span className="d-md-none">{mobileHeader || id}</span>
          <span className="d-none d-md-inline">{header || id}</span>
        </a>
      </li>
    )
  }

  private hashChangeHandler = () => {
    const {_window} = this.props
    if (!this.storeStateInHash()) {
      return
    }

    const bookmark = this.props.bookmark || this.props.id

    if (_window.location.hash && _window.location.hash == '#' + encodeURIComponent(bookmark)) {
      const current = this.anchorRef.current
      if (current) {
        $(current).tab('show')
      }
    }
  }

  private onPopStateHandler = () => {
    const {_window} = this.props
    if (!this.storeStateInPath()) {
      return
    }

    const bookmark = this.props.bookmark || this.props.id
    if (lastPathPart(_window.location.pathname) == bookmark) {
      const current = this.anchorRef.current
      if (current) {
        $(current).tab('show')
      }
    }
  }

  private onTabSelected = (evt: TapEventHandler<HTMLAnchorElement>) => {
    const { onTabSelected, _window } = this.props
    const bookmark = this.props.bookmark || this.props.id
    if (this.storeStateInHash()) {
      _window.location.hash = encodeURIComponent(bookmark)
    } else if (this.storeStateInPath()) {
      this.updatePath(bookmark, evt.relatedTarget && $(evt.relatedTarget).data('bookmark'))
    }

    if (onTabSelected) {
      onTabSelected(this.props.id)
    }
  }

  private updatePath = (bookmark: string, relatedBookmark: string | undefined) => {
    const {_window} = this.props
    const currentBookmark = lastPathPart(_window.location.pathname)
    if (currentBookmark && currentBookmark == bookmark) {
      return
    }

    let slug
    if (relatedBookmark &&
        relatedBookmark == currentBookmark) {
      // we are navigating between two tabs
      slug = bookmark
    } else {
      // we are navigating from the root into one of the tabs
      slug = [_window.location.pathname.replace(/\/+$/, ''), bookmark].join('/')
    }

    const newLocation = new URL(slug, _window.location.toString())
    if (_window.location.search) {
      newLocation.search = _window.location.search
    }
    _window.history.pushState(
      { bookmark, id: this.props.id },
      document.title,
      newLocation.toString(),
    )
  }

  private storeStateInHash = () => {
    return this.props.storeState === true || this.props.storeState === 'hash'
  }

  private storeStateInPath = () => {
    return this.props.storeState === 'path'
  }
}

interface ITabPaneProps extends React.Props<void> {
  id: string
  active?: boolean
}

export class TabPane extends React.PureComponent<ITabPaneProps> {
  public render() {
    const { id, active, children } = this.props

    return (
      <div className={`tab-pane ${active ? 'show active' : ''}`}
        id={`${id}-tab`} role="tabpanel" aria-labelledby={`${id}-tab-nav`}>
        {children}
      </div>
    )
  }
}

function lastPathPart(pathname: string) {
  return pathname.split('/').filter((p) => p.length > 0).pop()
}
