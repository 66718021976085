import * as React from 'react'
import { Hit } from 'react-instantsearch-core'


import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'
import { ICompositePost } from '../../../models/algolia/composite'
import { isMobile } from '../tab/views/utils'
import { TableViewOptions } from './any-hit'
import { PostHitBlockView } from './post-block'
import { PostHitTableView } from './post-table'

type TableViewProps = TableViewOptions & ITranslationsProvided & {
  hit: Hit<ICompositePost>,
}

const PostHitLayout = (props: TableViewProps) => (
  isMobile() ? <PostHitBlockView {...props} hit={props.hit} /> : <PostHitTableView {...props} hit={props.hit} />
)

const PostHit = withTranslations(PostHitLayout, 'hit.post')

export { PostHit }
