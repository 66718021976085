import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'
import SquareLogo from '~/images/wm-logo-400x400.png'

import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'
import format from '../../../date/format'
import { parseDateISOString } from '../../../date/parse'
import { ICompositeMessage } from '../../../models/algolia/composite'
import { IMessageDoc } from '../../../models/algolia/message'
import { parameterize } from '../../../util/inflector'
import { BlockViewOptions, TableViewOptions } from './any-hit'
import { HitBlockCard } from './hit-block-card'
import { HitTableRow } from './hit-table-row'
import ScriptureReferencesHighlight from './scripture-reference-highlight'
import SpeakersHighlight from './speakers-highlight'

import { ConnectedPodcastHitBlockView, ConnectedPodcastHitTableView } from './podcast'
import { present } from '../../../util'
import { joinJsx } from '../../../util/joinJsx'
import { isMobile } from '../tab/views/utils'

type MessageHit = Hit<IMessageDoc> | Hit<ICompositeMessage>

interface ITableViewProps extends TableViewOptions {
  hit: MessageHit,
}

const DEFAULT_MSG_DATE_FORMAT = 'MMMM d, yyyy'

class MessageHitTableView extends React.PureComponent<ITableViewProps & ITranslationsProvided> {

  public render() {
    const { hit } = this.props

    if ('object_type' in hit) {
      return this.renderCompositeHit(hit)
    }

    return this.renderHit(hit)
  }

  private renderHit(hit: Hit<IMessageDoc>) {
    const { key } = hit.series_type || { key: undefined }

    if (key == 'podcast') {
      return <ConnectedPodcastHitTableView hit={hit} />
    }

    const { thumbnail_url, title, date, objectID } = hit
    const slug = makeSlug(objectID, title)

    return <HitTableRow
      hit={hit}
      slug={slug}
      thumbnailUrl={thumbnail_url} // TODO: https://github.com/watermarkchurch/media/issues/194
      overline={<Highlight attribute="Message.series.title" hit={hit}></Highlight>}
      title={<Highlight attribute="title" hit={hit}></Highlight>}
      className="site-search-hit__message"
    >
      <span className="media-caption">
        {joinJsx(
          [
            hit.speakers && hit.speakers.length > 0 && <SpeakersHighlight attribute="speakers" hit={hit} key={hit.objectID} />,
            date && this.formatDate(new Date(Date.parse(date)))
          ].filter(present),

          <>&nbsp;•&nbsp;</>)}

      </span>
    </HitTableRow>
  }

  private renderCompositeHit(hit: Hit<ICompositeMessage>) {
    const { key } = hit.Message.series.series_type || { key: undefined }

    if (key == 'podcast') {
      return <ConnectedPodcastHitTableView hit={hit} />
    }

    const { id, images, date, description, title } = hit.Message
    const slug = makeSlug(id, title)

    return <HitTableRow
      hit={hit}
      slug={slug}
      thumbnailUrl={isMobile() ? images.wide_image_url : images.square_image_url || images.banner_image_url || SquareLogo}
      aspectRatio={isMobile() ? '16by9' : '1by1'}
      overline={<Highlight attribute="Message.series.title" hit={hit}></Highlight>}
      title={<Highlight attribute="Message.title" hit={hit}></Highlight>}
      className="site-search-hit__message"
    >
      <span className="media-caption">
        {joinJsx(
          [
            hit.Message.speakers && hit.Message.speakers.length > 0 && <SpeakersHighlight attribute="Message.speakers" hit={hit} key={hit.objectID} />,
            date && this.formatDate(parseDateISOString(date))
          ].filter(present),

          <>&nbsp;•&nbsp;</>)}
      </span>
      {description && <div className='description'>
        <p className='pr-5'>
          <Highlight attribute="Message.description" hit={hit}></Highlight>
        </p>
      </div>}
      <i className='material-icons right-arrow'>arrow_forward</i>
    </HitTableRow>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, t('dateFormat'))
  }
}

export const ConnectedMessageHitTableView = withTranslations(
  MessageHitTableView,
  {
    dateFormat: DEFAULT_MSG_DATE_FORMAT,
  },
)

interface IBlockViewProps extends BlockViewOptions {
  hit: MessageHit,
}

class MessageHitBlockView extends React.PureComponent<IBlockViewProps & ITranslationsProvided> {

  public render() {
    const { hit } = this.props

    if ('object_type' in hit) {
      return this.renderCompositeHit(hit)
    }

    return this.renderHit(hit)
  }

  public renderHit(hit: Hit<IMessageDoc>) {
    const { key } = hit.series_type

    if (key == 'podcast') {
      return <ConnectedPodcastHitBlockView hit={hit} />
    }

    const slug = makeSlug(hit.objectID, hit.title)

    return <HitBlockCard
      slug={slug}
      thumbnailUrl={hit.thumbnail_url}
      thumbnailOverlay={<button className="site-search-hit__message-btn btn btn-secondary">Watch</button>}
      overline={<Highlight attribute="Message.series.title" hit={hit}></Highlight>}
      title={<Highlight attribute="title" hit={hit}></Highlight>}
      className="site-search-hit__message message-card"
      {...this.props}
    >
      <span className="media-caption">
        {joinJsx(
          [
            hit.speakers && hit.speakers.length > 0 && <SpeakersHighlight attribute="speakers" hit={hit} key={hit.objectID} />,
            hit.date && this.formatDate(new Date(Date.parse(hit.date)))
          ].filter(present),

          <>&nbsp;•&nbsp;</>)}
      </span>
      {hit.scripture && <br />}
      {hit.scripture && <span className="media-caption">
        <ScriptureReferencesHighlight attribute="scripture" hit={hit} limit={1}/>
      </span>}
    </HitBlockCard>
  }

  public renderCompositeHit(hit: Hit<ICompositeMessage>) {
    const { key } = hit.Message.series.series_type

    if (key == 'podcast') {
      return <ConnectedPodcastHitBlockView hit={hit} />
    }

    const { images, title, description, date, id } = hit.Message
    const slug = makeSlug(id, title)

    return <HitBlockCard
      slug={slug}
      thumbnailUrl={images.wide_image_url || images.square_image_url || images.banner_image_url}
      thumbnailOverlay={<button className="site-search-hit__message-btn btn btn-secondary">Watch</button>}
      overline={<Highlight attribute="Message.series.title" hit={hit}></Highlight>}
      title={<Highlight attribute="Message.title" hit={hit}></Highlight>}
      className="message-card site-search-hit__message"
      {...this.props}
    >
      <span className="media-caption">
        {joinJsx(
          [
            hit.Message.speakers && hit.Message.speakers.length > 0 && <SpeakersHighlight attribute="Message.speakers" hit={hit} key={hit.objectID} />,
            date && this.formatDate(parseDateISOString(date))
          ].filter(present),

          <>&nbsp;•&nbsp;</>)}
      </span>
      {description && <div className='description'>
        <p className='pr-5'>
          <Highlight attribute="Message.description" hit={hit}></Highlight>
        </p>
        <i className='material-icons right-arrow'>arrow_forward</i>
      </div>}
    </HitBlockCard>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, (t('dateFormat')) || DEFAULT_MSG_DATE_FORMAT)
  }
}

export const ConnectedMessageHitBlockView = withTranslations(
  MessageHitBlockView,
  {
    dateFormat: DEFAULT_MSG_DATE_FORMAT,
  },
)

function makeSlug(id: string, title: string): string {
  title = title.split(/\s+/g).slice(0, 6).join(' ')
  return `/message/${id}-${parameterize(title)}`
}
