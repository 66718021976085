import bugsnagClient from 'bugsnag'
import * as React from 'react'

interface IOptions {
  wrapperComponent?: React.FunctionComponent | React.ComponentClass | string
}

let _ErrorBoundary: React.ComponentType<IOptions>


/**
 * This switch gets compiled away by webpack based on the current NODE_ENV.  For
 * example, in production the compiled code looks like this:

var _ErrorBoundary;
if (true) {
    var reportErrors_1 = function (errors) {
    ...
}
else {}

 * meanwhile in dev:

if (false) { var FallbackComponent_1, BugsnagErrorBoundary_1; }
else {
    _ErrorBoundary = (function (_super) {
      ...
      return DevErrorBoundary;
  }(react__WEBPACK_IMPORTED_MODULE_1__["Component"]));
}
 */

if (process.env.NODE_ENV == 'production') {
  interface IProvided {
    onCatch: (error: Error, info?: any) => void
  }
  interface IErrorInfo { err: Error, info: any }

  const reportErrors = (errors: IErrorInfo[]) => {
    if (errors.length == 1) {
      const {err, info} = errors[0]
      bugsnagClient.notify(err, {
        metaData: {
          info,
        },
      })
    } else if (errors.length > 1) {
      bugsnagClient.notify(new Error(`Multiple rendering errors`), {
        metaData: {
          errors,
        },
      })
    }
  }

  let _globalErrors: IErrorInfo[] = []
  let _globalErrorReportTimeout: ReturnType<typeof setTimeout> | undefined
  const globalOnCatch = (err: Error, info?: any) => {
    _globalErrors.push({ err, info })
    if (!_globalErrorReportTimeout) {
      _globalErrorReportTimeout = setTimeout(() => {
        reportErrors(_globalErrors)
        _globalErrors = []
        _globalErrorReportTimeout = undefined
      }, 100)
    }
  }

  const {Consumer} = React.createContext<IProvided>({ onCatch: globalOnCatch })

  _ErrorBoundary = ({children}) => {
    return <Consumer>
      {(ctx) =>
        <FallbackComponent {...ctx}>
          {children}
        </FallbackComponent>}
    </Consumer>
  }

  class FallbackComponent extends React.Component<IProvided, { error?: Error, info?: any }> {
    constructor(props: FallbackComponent['props'], context?: any) {
      super(props, context)
      this.state = {}
    }

    public componentDidCatch(error: Error, info?: any) {
      // Display fallback UI
      this.setState({ error, info })
      if (this.props.onCatch) {
        this.props.onCatch(error, info)
      }
    }

    public render() {
      const { error } = this.state

      if (error) {
        return <div className="row error-fallback">
          <div className="col">
            <span className="error">
                An error occurred in rendering this component!  Please try refreshing the page.
            </span>
            {(typeof window != 'undefined' && (window as any).isTest) && <>
              <pre>
                {error.message}
              </pre>
              <pre>
                {error.stack}
              </pre>
            </>}
          </div>
        </div>
      }
      return this.props.children
    }
  }
} else {
  _ErrorBoundary =
    class DevErrorBoundary extends React.Component<IOptions, { error?: Error, info?: any }> {
      constructor(props: DevErrorBoundary['props'], context?: any) {
        super(props, context)
        this.state = {}
      }

      public componentDidCatch(error: Error, info?: any) {
        // Display fallback UI
        this.setState({ error, info })
      }

      public render() {
        const { error, info } = this.state
        const { wrapperComponent } = this.props

        if (error) {
          const Wrapper = wrapperComponent || 'div'
          return <Wrapper>
            <h1 className="error">{error.message}</h1>
            <pre>{error.stack}</pre>
            <hr />
            <pre>{info && info.componentStack}</pre>
          </Wrapper>
        }
        return this.props.children
      }
    }
}

export const ErrorBoundary = _ErrorBoundary

export function withErrorBoundary<Props>(
  WrappedComponent: React.ComponentType<Props>,
  options?: IOptions,
): React.ComponentType<Props> {
  // eslint-disable-next-line react/display-name
  return (props: Props) =>
    <ErrorBoundary {...options}>
      <WrappedComponent {...props as any} />
    </ErrorBoundary>
}
