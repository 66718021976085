import * as React from 'react'
import {
  connectCurrentRefinements,
  connectStateResults,
  CurrentRefinementsProvided,
  StateResultsProvided,
} from 'react-instantsearch-core'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'

interface IProps {
  team_attribute: string,
}

class NoResults extends React.PureComponent<IProps &
  ITranslationsProvided & StateResultsProvided & CurrentRefinementsProvided
> {
  public render() {
    const {searchResults, team_attribute, items, refine, t} = this.props

    if (!searchResults || searchResults.nbHits > 0) {
      return null
    }

    const teams =
      items.filter((i) => i.attribute == team_attribute)

    if (teams && teams.length > 0) {
      return <div className="col-10 col-sm-12">
        {t('noResults.with_team.message', {
          query: searchResults.query,
          team: teams[0].currentRefinement,
        })}
        <a href="#" onClick={(evt) => {
          evt.preventDefault()
          refine(teams[0].value)
        }}>
          {t('noResults.with_team.action_link', {
            query: searchResults.query,
            team: teams[0].currentRefinement,
          })}
        </a>
      </div>
    } else {
      return <div className="col-10 col-sm-12">
        {t('noResults.without_team.message', {
          query: searchResults.query,
        })}

        <a href="#" onClick={(evt) => {
          evt.preventDefault()
          refine((ss) => {
            return {
              ...ss,
              query: '',
            }
          })
          setTimeout(() => {
            $('.ais-SearchBox-input').focus()
          }, 400)
        }}>
          {t('noResults.without_team.action_link', {
            query: searchResults.query,
          })}
        </a>
      </div>
    }
  }
}

const ConnectedNoResults = connectStateResults(
  connectCurrentRefinements(
    withTranslations(
      NoResults,
    ),
  ),
)

export default ConnectedNoResults
