import { HiddenFacets, SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import WCC from 'wcc'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { Stringable } from '../../util'
import AlgoliaSearch from '../algolia-search'
import { BlogFacets } from './blog-facets'
import ConnectedSearchResults from './search-results'

interface IProps extends ITranslationsProvided {
  blogSource: BlogSource,
  translations: {
    noResults: string,
    searchReset: string,
    blog_facet_select: {
      see_all_option: string,
    },
    searchBox: {
      placeholder: string,
    },
  },
  showFacets?: boolean
  header?: string
  viewAllLink?: string

  algolia_insights?: boolean
}

type BlogSource = IAlgoliaBlogSource

interface IAlgoliaBlogSource {
  source: 'Algolia'
  index: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable,
  }
  operator?: 'or' | 'and'
}

interface IState {
  hasFacets: boolean
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering', {
    logger: console as any,
  })

export class BlogSearch extends React.Component<IProps, IState> {
  public state: IState = {
    hasFacets: true,
  }

  public render() {
    const { blogSource, translations, header, viewAllLink, algolia_insights } = this.props

    return (
      <AlgoliaSearch
        indexName={blogSource.index}
        widgetName="blog-search"
        storeStateInQuery={true}
      >
        {algolia_insights !== false &&
          <Configure clickAnalytics />}
        <HiddenFacets hiddenFacets={{ object_type: 'Post' }} />
        {blogSource.facetFilters &&
          <HiddenFacets hiddenFacets={blogSource.facetFilters}
            operator={blogSource.operator} />}
        <div className="col-content-container col-content-offset px-0">
          <div className="row mb-4">
            <div className="col-12 col-md-6 widget-blog-search__header">
              {header && <h4>{header}</h4>}
              {viewAllLink &&
                <a href={viewAllLink} className="btn btn-link">View All</a>}
            </div>
            <div
              className={
                'people-search__search-box ' +
                `${this.includeFacetDropdown() ? 'col-6 col-md-4' : 'col-12 col-md-6 pr-3'}`
              }
            >
              <SearchBox defaultIndex={blogSource.index}
                autosuggestClient={autosuggestClient}
                className="narrow"
                searchAsYouType={false}
                translations={this.props.translationsAt('searchBox') as { [key: string]: string }} />
            </div>
            {this.includeFacetDropdown() &&
              <div className="col-6 col-md-2 people-search__filters">
                <BlogFacets
                  attribute={'Post.categories.title'}
                  translations={translations}
                  hasFacets={this.state.hasFacets}
                />
              </div>}
          </div>
        </div>
        <Configure hitsPerPage={9} />
        <ConnectedSearchResults
          setHasFacets={this.setHasFacets}
          translations={translations}
        />
      </AlgoliaSearch>
    )
  }

  public setHasFacets = (facetsPresent: boolean) => {
    if (facetsPresent !== this.state.hasFacets) {
      this.setState({ hasFacets: facetsPresent })
    }
  }

  private includeFacetDropdown() {
    if (this.props.blogSource.facetFilters) { return false }

    return this.props.showFacets
  }
}

const ConnectedBlogSearch = withTranslations(BlogSearch)
export default ConnectedBlogSearch
