import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { WatermarkMediaDefault } from '@watermarkchurch/react-instantsearch-components'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import format from '../../date/format'
import { parseDateISOString } from '../../date/parse'
import { ICompositePost } from '../../models/algolia/composite'
import { exists } from '../../util'
import { HitTableRow } from '../SiteSearch/hits/hit-table-row'

interface IProps extends ITranslationsProvided {
  hit: Hit<ICompositePost>
}

class BlogGalleryTableHit extends React.Component<IProps> {

  public render() {
    const { hit, t } = this.props
    const {
      date,
      slug,
      title,
      subtitle,
      summary,
      time_to_read,
      hero_image_url,
      hero_image_alt_text,
      author,
    } = hit.Post

    return <HitTableRow
      hit={hit}
      className="site-search-hit__post"
      slug={slug}
      thumbnailUrl={hero_image_url || WatermarkMediaDefault}
      thumbnailAlt={hero_image_alt_text}
      aspectRatio="16by9"
    >
      {this.isPresent(title) && <h3 className="card-title">{title}</h3>}
      {this.isPresent(subtitle) && <h5 className="card-subtitle subtitle">{subtitle}</h5>}
      {this.isPresent(summary) && <span className="summary">{summary}</span>}
      <span className="media-caption">
        {[
          author && author.full_name,
          date && this.formatDate(parseDateISOString(date)),
          time_to_read && time_to_read > 0 && t('time_to_read', { count: time_to_read }),
        ].filter(exists).join(' • ')}
      </span>
    </HitTableRow>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, t('dateFormat'))
  }

  private isPresent(aString: string | null): boolean {
    return aString != null && aString.trim() !== ''
  }
}

const DEFAULT_MSG_DATE_FORMAT = 'MMMM d, yyyy'

const ConnectedGalleryTableHit = withTranslations(
  BlogGalleryTableHit,
  {
    dateFormat: DEFAULT_MSG_DATE_FORMAT,
  })

export default ConnectedGalleryTableHit
