import * as React from 'react'

export class LoadingEvent extends React.PureComponent {

  public render() {
    return <a
      className={
        `a-block card widget-events-card-deck__event-card loading`
      }
    >
      <div className='widget-events-card-deck__event-card-info'>
        <div>
          <span className='overline'>
            &nbsp;
          </span>
          <h5 className='event-title'>
            &nbsp;
          </h5>
          <p className='event-description'></p>
        </div>
        <div className='event-info'>
          <span className='interface-label'>
            &nbsp;
          </span>
        </div>
      </div>
    </a>
  }
}
