import * as React from 'react'

import { Hit, StateResultsProvided, connectStateResults } from 'react-instantsearch-core'
import { Stats } from 'react-instantsearch-dom'
import { IMessageDoc } from '../../models/algolia/message'
import { exists, present } from '../../util'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'

interface IProps {
  hits: Array<Hit<IMessageDoc>>,
  year: string
}

function ArchiveYearView(props: IProps & ITranslationsProvided & StateResultsProvided) {
  const { hits, year, t, searchResults } = props
  const hasResults: boolean = hasSearchResults(searchResults)

  return (
    <>
      {hasResults &&
      <div className='archive-year-detail-view__header'>
        <Stats translations={{
          stats: (n) => t('hitStats', {count: n}),
        }} />
      </div>
      }
      <div className='archive-year-detail-view'>
        { hits.filter((hit) => hit.date_year.toString() === year).map((hit) => {
          return (
            <a href={`/message/${hit.objectID}`} className='archive-year-detail-view__message' key={hit.objectID}>
              <p className='archive-year-detail-view__message-series'>
                {hit.series_title}
              </p>
              <h3 className='archive-year-detail-view__message-title'>
                {hit.title}
              </h3>
              <div className='archive-year-detail-view__message-details'>
                {
                  [
                    hit.speakers.map(speaker => (speaker.speaker_name)).join(', '),
                    hit.date,
                    parseScriptures(hit.scripture)
                  ].filter(exists).filter(present).join(' • ')
                }
              </div>
              <div className='archive-year-detail-view__message-description'>
                <p>{hit.description}</p>
              </div>
              <i className='material-icons right-arrow'>arrow_forward</i>
            </a>
          )
        })}
      </div>
    </>
  )
}

function parseScriptures(scriptures: Array<{
  canon_order: number,
  book: string,
  verses: string,
}>): string {
  return scriptures.map((scripture) => {
    const bookName = scripture.book
    const verses = scripture.verses
    return `${bookName} ${verses}`
  }).join('; ')
}

export const ArchiveYearDetailView = connectStateResults(withTranslations(ArchiveYearView))

const hasSearchResults = (searchResults?: StateResultsProvided['searchResults']): boolean => {
  if (!searchResults) { return false }
  return searchResults.nbHits > 0
}
