import * as React from 'react'

import get from 'lodash-es/get'
import { connectHighlight, HighlightProps } from 'react-instantsearch-core'
import { ICompositeMessage } from '../../../models/algolia/composite'
import { IMessageDoc } from '../../../models/algolia/message'

type ScriptureReferences =
  IMessageDoc['scripture']
  | ICompositeMessage['Message']['scripture_references']

interface IProps extends HighlightProps {
  /** The number of scripture references to show.  No limit if left blank. */
  limit?: number
}

export class ScriptureReferencesHighlight extends React.PureComponent<IProps> {
  public render() {
    const {
      hit,
      attribute,
      highlight,
      limit,
    } = this.props
    const highlightProperty = this.props.highlightProperty || '_highlightResult'

    let scriptures: ScriptureReferences = get(hit, attribute, [])

    if (limit) {
      scriptures = scriptures.slice(0, limit)
    }

    const elements: React.ReactNode[] = []
    function pushParts(att: string, fallback: string) {
      const parts = highlight({
        attribute: att,
        hit,
        highlightProperty,
      })
      if (!parts || parts.length == 0) {
        elements.push(<span key={att + '-part'}>{fallback}</span>)
      } else {
        parts.forEach((item, i) => {
          if (item.isHighlighted) {
            elements.push(
              <em key={att + '-part-' + i}>{item.value}</em>,
            )
          } else {
            elements.push(item.value)
          }
        })
      }
    }

    for (let i = 0; i < scriptures.length; i++) {
      if (i > 0) { elements.push('; ')}

      const scripture = scriptures[i]
      if ('book' in scripture) {
        pushParts(attribute + `.[${i}].book`, scripture.book)
      } else if ('book_name' in scripture) {
        pushParts(attribute + `.[${i}].book_name`, scripture.book_name)
      } else {
        raiseUnexpectedScripture(scripture, attribute)
      }
      elements.push('\u00A0')

      pushParts(attribute + `.[${i}].verses`, scripture.verses)
    }

    return <>{elements}</>
  }
}

export default connectHighlight(ScriptureReferencesHighlight)

function raiseUnexpectedScripture(scripture: never, attribute: string): never {
  throw new Error(`Unexpected '${attribute}', object has no 'book' or 'book_name': ${scripture}`)
}
