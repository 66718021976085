import React from 'react'
import {
  connectStateResults,
  StateResultsProvided,
} from 'react-instantsearch-core'

import { ITranslationsProvided } from '../../connectors/translation'
import { PaginatedEpisodes } from './paginated-episodes'

interface IProps {
  header?: string
}

class EpisodeList extends React.Component<
  IProps & ITranslationsProvided & StateResultsProvided
  > {
  public render() {
    const { t, searchResults } = this.props

    return (
      <>
        <div className="row podcast-index__header">
          <div className="col-12">
            <h5>
              {t('header', { count: searchResults && searchResults.nbHits || 0 })}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <PaginatedEpisodes />
          </div>
        </div>
      </>
    )
  }
}
const ConnectedEpisodeList = connectStateResults(EpisodeList)

export { ConnectedEpisodeList }
