import * as React from 'react'
import { rewriteContentfulImageUrl } from '../../../util/contentfulImage'
import { buildAnalyticsAttributes } from '../../algolia-search/insights'

export type AspectRatio = '1by1' | '4by3' | '16by9' | '21by9'

export interface IHitBlockProps {
  hit: { objectID: string }

  /** The relative URL that the <a /> tag should link to */
  slug: string | undefined
  /** (optional) The thumbnail to display on desktop */
  thumbnailUrl?: string | null
  /** (optional) Alt text for the thumbnail image */
  thumbnailAlt?: string | null
  /** (optional) An overlay (like an icon or button) to put on top of the thumbnail */
  thumbnailOverlay?: React.ReactNode
  /** (optional) The Title that gets wrapped in an <h2 /> at the top of the text block. */
  title?: React.ReactNode
  /** (optional) The Aspect Ratio to use for the image. */
  aspectRatio?: AspectRatio
  /** (optional) Where to put the thumbnail.  Default: `top` means text goes below the thumbnail. */
  thumbPosition?: 'top' | 'bottom'
  className?: string
  /** (optional) when there is overline text above the title */
  overline?: React.ReactNode
}

export class HitBlock extends React.PureComponent<IHitBlockProps> {
  public static defaultProps: Partial<IHitBlockProps> = {
    className: 'ais-Hits-item',
    aspectRatio: '16by9',
    thumbPosition: 'top',
  }

  public render() {
    const {
      hit,
      slug,
      title,
      className,
      aspectRatio,
      thumbnailOverlay,
      thumbPosition,
      children,
    } = this.props
    const thumbnailUrl = this.props.thumbnailUrl && rewriteContentfulImageUrl(this.props.thumbnailUrl, { w: 640 })

    const thumb = <div className={`d-flex flex-column`}>
      {thumbnailUrl &&
          <div className={`${className}-thumb embed-responsive embed-responsive-${aspectRatio}`}>
            <img className="embed-responsive-item" src={thumbnailUrl} alt={this.props.thumbnailAlt || undefined} />
            {thumbnailOverlay}
          </div>}
    </div>

    return <a href={slug} className={`${className} a-block d-block`}
      {...buildAnalyticsAttributes(hit)}
    >
      {thumbPosition != 'bottom' && thumb}
      <div className={`${className}-text`}>
        {title && <h2>{title}</h2>}
        {children}
      </div>
      {thumbPosition == 'bottom' && thumb}
    </a>
  }
}
