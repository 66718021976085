import { HiddenFacets, SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import WCC from 'wcc'

import AlgoliaSearch from '../algolia-search'
import { InfiniteSearchResults } from '../infinite-search-results'
import { IRefinementDefinition } from '../SiteSearch/tab'
import { CampusSelect } from './campus-select'
import ConnectedNoResults from './no-results'
import { Person } from './person'
import { TeamSelect } from './team-select'

interface IProps {
  index: string
  autosuggestIndex?: string
  defaultTeam?: string
  defaultCampus?: string
  team_attribute?: string,
  hidden_facets?: IRefinementDefinition,
  translations: {
    header: string
    noResults: string
    team_select: {
      see_all_option: string,
    }
    campus_select: {
      see_all_option: string,
    }
    search_input: {
      placeholder: string,
    },
  }
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering',
  {
    logger: console as any,
  })

class PeopleSearch extends React.Component<IProps> {
  private defaultTeam?: string
  private componentRef: any
  private paramTeam: string | null

  constructor(props: PeopleSearch['props'], context?: any) {
    super(props, context)

    this.paramTeam = null
    this.componentRef = React.createRef()
  }

  public componentDidMount() {
    this.paramTeam = (new URLSearchParams(window.location.search)).get('team')
    this.defaultTeam = this.paramTeam || this.props.defaultTeam

    if (this.paramTeam) {
      setTimeout(() => {
        window.scrollTo(0, this.componentRef.current.getBoundingClientRect().top)
      }, 100)
    }
  }

  public render() {
    const { index, team_attribute, hidden_facets, translations } = this.props

    return (
      <AlgoliaSearch indexName={index} widgetName="people-search">
        <Configure analytics={false} />
        {hidden_facets && <HiddenFacets hiddenFacets={hidden_facets} />}
        <div className="row mb-4" ref={this.componentRef}>
          <div className="col-12 col-md-2 people-search__header">
            <h4>{translations.header}</h4>
          </div>
          <div className="col-6 col-md-4 people-search__search-box">
            <SearchBox defaultIndex={index}
              autosuggestIndex={this.props.autosuggestIndex}
              autosuggestClient={autosuggestClient}
              translations={translations.search_input}
              className="narrow"
              searchAsYouType={false} />
          </div>
          <div className="col-6 col-md-2 people-search__filters">
            <CampusSelect
              attribute={'Person.campus'}
              defaultRefinement={this.props.defaultCampus}
              translations={translations.campus_select}
            />
          </div>
          <div className="col-12 col-md-4 people-search__filters teams">
            <TeamSelect
              attribute={team_attribute || 'teams.name'}
              defaultRefinement={this.defaultTeam}
              translations={translations.team_select}
            />
          </div>
        </div>
        <InfiniteSearchResults
          wrapperClass="row justify-content-sm-start justify-content-center people-search-results"
          hitComponent={Person}
        />
        <ConnectedNoResults team_attribute={team_attribute || 'teams.name'} />
      </AlgoliaSearch>
    )
  }
}

export default PeopleSearch
