import * as React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'

import { ITab } from '.'
import { ITranslationsProvided, PrefixTranslations, withTranslations } from '../../../connectors/translation'
import { AnyHit } from '../hits'
import { AnyHitBlockView } from '../hits/any-hit'
import ConnectedHrefModifier from '../href-modifier'
import { ConnectedDesktopTabLayout, ConnectedMobileTabLayout } from './layout'
import { BlockTabView } from './views/block'
import { TableTabView } from './views/table'
import { isEmpty, isMobile } from './views/utils'
import { HiddenAttributesForFaceting } from '../hidden-attributes-for-faceting'
import { SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch, { AlgoliaSearchOptions } from 'algoliasearch'
import WCC from 'wcc'
import { Stats } from 'react-instantsearch-dom'
import ConnectedCurrentRefinementsBadges from '../current-refinements-badges'

export interface IProps {
  tab: ITab
  initial: boolean
  messagesOnly?: boolean
  autosuggestIndex?: string
  autoFocus?: boolean
}

// TODO: https://github.com/watermarkchurch/paper-signs/issues/297 pass translations down
const TabViews: { [key: string]: React.ComponentType<Record<string, never>> } = {
  default: TableTabView,
  table: TableTabView,
  block: BlockTabView,
}

const opts: AlgoliaSearchOptions = {
  logger: console as any,
}
if (WCC.algoliaProxy) {
  Object.assign(opts, {
    hosts: [WCC.algoliaProxy],
  })
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering',
  opts)

class TabViewSwitch extends React.PureComponent<IProps & ITranslationsProvided & StateResultsProvided<AnyHit>> {

  public render() {

    if (!this.isCorrectIndex()) {
      return null
    }

    const { tab, initial, t, messagesOnly, autosuggestIndex } = this.props

    if (initial) {
      // Use layout in the future?
      return this.renderInitialHit()
    }

    const view = isMobile() ? (tab.mobile_view || tab.view) : tab.view
    const Template = TabViews[view] || TabViews.default

    const canRefine = tab.attributes_for_faceting && tab.attributes_for_faceting.length > 0

    const Layout = isMobile() ?
      ConnectedMobileTabLayout :
      ConnectedDesktopTabLayout

    return <PrefixTranslations prefix={['tabs', tab.name]}>
      <Layout messagesOnly={messagesOnly} tab={tab} initial={initial}
      // initialHit={this.renderInitialHit()}
      >
        <HiddenAttributesForFaceting attributes={tab.hidden_attributes_for_faceting} />
        <div className="justify-content-center justify-content-md-start d-flex site-search-hits">
          {messagesOnly && <div>
            <SearchBox
              defaultIndex={tab.index || (tab.indexes && tab.indexes[0].id)}
              autosuggestIndex={autosuggestIndex}
              autosuggestClient={autosuggestClient}
              searchAsYouType={false}
              showLoadingIndicator={true}
              autoFocus={typeof (this.props.autoFocus) == 'undefined' ? true : this.props.autoFocus }
              translations={this.props.translationsAt('searchBox') as { [key: string]: string }}
            />

            <div className='px-2'>
              <Stats translations={{
                stats: (n, ms) => t('hitStats', { count: n, seconds: ms / 1000 }),
              }} />
            </div>
            {canRefine &&
                  <div className={`site-search-filters__badges`}>
                    <ConnectedCurrentRefinementsBadges />
                  </div>}

          </div>
          }
          <ConnectedHrefModifier className="w-100">
            <Template />
          </ConnectedHrefModifier>
        </div>
        
      </Layout>
    </PrefixTranslations>
  }

  private renderInitialHit() {
    const { tab, searchResults } = this.props

    const hasInitialQuery = !isEmpty(tab.initial_hit)
    if (hasInitialQuery) {
      if (!searchResults.hits[0]) {
        return null
      }

      const hit = searchResults.hits[0] as AnyHit

      return <div className="block-view">
        <AnyHitBlockView hit={hit} thumbPosition={'bottom'} />
      </div>
    } else {
      return null
    }
  }

  private isCorrectIndex(): boolean {
    const { tab, searchResults } = this.props

    if (!searchResults) { return false }
    if (tab.index == searchResults.index) { return true }
    if (!tab.indexes) { return false }
    return tab.indexes.some((i) => i.id == searchResults.index)
  }

}

const ConnectedTabViewSwitch = connectStateResults(withTranslations(TabViewSwitch)
)
export default ConnectedTabViewSwitch
