import {timeout, TimeoutError} from 'async-toolbox/timeout'

import { Stringable } from '../../util'
import type { ILiveStream, IStreamEvent } from './models'

const getLiveStream = async (id: Stringable | undefined, fetch: typeof window.fetch): Promise<ILiveStream | null> => {
  if (!id) { return null }

  const resp = await doFetch(`/api/v1/live_streams/${id}`, fetch)
  if (resp.status == 404) {
    return null
  }
  if (resp.status != 200) {
    throw new Error(`Unexpected status for ${resp.url} - ${resp.status}`)
  }
  const body: { stream: ILiveStream } = await resp.json()

  return body.stream
}

const checkLiveStreamActive = async (url: string, fetch: typeof window.fetch): Promise<boolean> => {
  // always use HTTPS even if live stream is http in the media system
  const u = new URL(url)
  u.protocol = 'https:'
  const resp = await doFetch(u.toString(), fetch)
  // akamai sends 400 when stream is not available
  return resp.status == 200
}

async function doFetch(url: string, fetch: typeof window.fetch): Promise<Response> {
  let resp: Response
  try {
    resp = await timeout(() => fetch(url), 10000)
  } catch (ex) {
    if (ex instanceof TimeoutError) {
      throw new ConnectionError(ex.message, 'timeout')
    }
    if (ex instanceof TypeError) {
      if (/^NetworkError/.test(ex.message)) {
        throw new ConnectionError(ex.message, 'connection-failed')
      }
    }
    throw ex
  }

  if (resp.status == 503) {
    throw new ConnectionError('503 Server Unavailable', 'server-unavailable')
  }
  return resp
}

export {
  ILiveStream,
  IStreamEvent,
  getLiveStream,
  checkLiveStreamActive,
}

type ReasonCode = 'connection-failed' | 'server-unavailable' | 'timeout'

/** Thrown when the browser's connection to Paper Signs fails */
export class ConnectionError extends Error {
  constructor(error: any, public readonly reason: ReasonCode) {
    super(error)
  }
}
