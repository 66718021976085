import * as React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { ClearRefinements, Stats } from 'react-instantsearch-dom'
import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'
import ConnectedSearchReset from '../../search-reset'
import ConnectedCurrentRefinementsBadges from '../current-refinements-badges'
import { ConnectedFacetFilterModal, ConnectedFacetFilterPanel } from '../facet-filters'
import ConnectedIndexSelect from '../index-select'
import { IProps as ITabViewProps } from './view-switch'

interface IProps extends ITabViewProps {
  initialHit?: React.ReactNode
}

export class DesktopTabLayout extends React.PureComponent<IProps & ITranslationsProvided & StateResultsProvided> {

  public render() {
    const { tab, initialHit, t, searchResults, messagesOnly } = this.props

    const canRefine = tab.attributes_for_faceting && tab.attributes_for_faceting.length > 0
    const canSelectIndex = tab.indexes && tab.indexes.length > 0
    const hasResults: boolean = hasSearchResults(searchResults)

    return <>
      {initialHit && <div className="row">
        {t('initialHitHeader') && <div className="col-12">
          <span className="overline">{t('initialHitHeader')}</span>
        </div>}
        <div className="col-12 site-search__initial-hit">
          {initialHit}
        </div>
      </div>}
      <div className="row">
        <div className="col-12 col-md-9">
          <div className={`row site-search-filters ${messagesOnly ? 'd-none' : ''}`}>
            {hasResults &&
              <div className="col-6">
                <Stats translations={{
                  stats: (n, ms) => t('hitStats', { count: n, seconds: ms / 1000 }),
                }} />
              </div>}
            {hasResults && canSelectIndex && tab.indexes &&
              <div className="col-6 d-flex justify-content-center">
                <ConnectedIndexSelect className="site-search-filters__indexes"
                  defaultRefinement={tab.index || tab.indexes[0].id}
                  items={tab.indexes.map((index) => ({
                    value: index.id,
                    label: t(['indexes', index.name]),
                  }))} />
              </div>}
            {canRefine &&
              <div className={`col-12 site-search-filters__badges`}>
                <ConnectedCurrentRefinementsBadges />
              </div>}
          </div>
          {renderResults(this.props.children, hasResults)}
        </div>
        <div className="col-md-3 d-none d-md-block">
          {messagesOnly && hasResults && canSelectIndex && tab.indexes &&
              <ConnectedIndexSelect className="site-search-filters__indexes"
                defaultRefinement={tab.index || tab.indexes[0].id}
                items={tab.indexes.map((index) => ({
                  value: index.id,
                  label: t(['indexes', index.name]),
                }))} />}
          {canRefine && <div className="row">
            <div className="col-md-6">
              <h4 className="site-search-filters__header">{t('filterButtonText')}</h4>
            </div>
            <div className="col-md-6">
              <ClearRefinements translations={{ reset: t('clearAll') }} />
            </div>
          </div>}
          {canRefine &&
            <ConnectedFacetFilterPanel id="site-search-filters"
              className="site-search-filters__facets"
              attributes={tab.attributes_for_faceting.map((a) => typeof (a) == 'string' ? { name: a } : a)} />}
        </div>
      </div>
    </>
  }
}

export const ConnectedDesktopTabLayout = connectStateResults(
  withTranslations(
    DesktopTabLayout,
  ),
)

export class MobileTabLayout extends React.PureComponent<IProps & ITranslationsProvided & StateResultsProvided> {

  public render() {
    const { tab, initial, initialHit, t, searchResults, messagesOnly } = this.props

    const canRefine = tab.attributes_for_faceting && tab.attributes_for_faceting.length > 0
    const canSelectIndex = tab.indexes && tab.indexes.length > 0
    const hasResults: boolean = hasSearchResults(searchResults)

    return <>
      {(canRefine || canSelectIndex) &&
        <div className="row site-search-filters justify-content-center">
          {canRefine &&
            <div className={`col-12 site-search-filters__badges ${messagesOnly ? 'd-none' : ''}`}>
              <ConnectedCurrentRefinementsBadges />
            </div>}
          <div className="col-6 d-flex justify-content-center">
            {hasResults && canSelectIndex && tab.indexes &&
              <ConnectedIndexSelect className="site-search-filters__indexes"
                defaultRefinement={tab.index || tab.indexes[0].id}
                items={tab.indexes.map((index) => ({
                  value: index.id,
                  label: t(['indexes', index.name]),
                }))} />}
          </div>
          <div className="col-6 d-flex justify-content-center">
            {canRefine &&
              <ConnectedFacetFilterModal id="site-search-filters"
                className="site-search-filters__facets" skin={'light'}
                attributes={tab.attributes_for_faceting.map((a) => typeof (a) == 'string' ? { name: a } : a)} />}
          </div>
        </div>}

      {initialHit && <div className="row">
        {t('initialHitHeader') && <div className="col-12">
          <span className="overline">{t('initialHitHeader')}</span>
        </div>}
        <div className="col-12 site-search__initial-hit">
          {initialHit}
        </div>
      </div>}
      <div className="row">
        <div className="col-12 col-md-9">
          {/* Only render the full results in mobile if we're not showing the initial hit */}
          {(!initial || !initialHit) && renderResults(this.props.children, hasResults)}
        </div>
        <div className="col-md-3 d-none d-md-block">
        </div>
      </div>
    </>
  }
}

export const ConnectedMobileTabLayout = connectStateResults(
  withTranslations(
    MobileTabLayout,
  ),
)

const hasSearchResults = (searchResults?: StateResultsProvided['searchResults']): boolean => {
  if (!searchResults) { return false }
  return searchResults.nbHits > 0
}

const renderResults = (children: any, hasResults: boolean) => {
  if (hasResults) { return children }
  return <ConnectedSearchReset />
}
