import * as React from 'react'

import { SiteSkins } from '../../models'
import { ButtonStyle } from './buttons'

interface IProps {
  /** The DOM ID of the modal, used to attach Bootstrap event listeners */
  id?: string
  /** Optional className to apply to the wrapper div.  Ex. 'my-modal' */
  className?: string
  /** Optional skin for background color of the modal.  Ex. 'light' */
  skin?: SiteSkins
  /** Optional className to apply to the modal div.  Ex. 'push slide' */
  modalClassName?: string
  /** Icon to use in the button */
  icon?: string
  /** Bootstrap button style to use on the open/close butotn */
  buttonColor?: ButtonStyle
  /** Bootstrap button style to use on the apply */
  applyColor?: ButtonStyle

  translations: {
    /** Sets the button text and header h5 inside the modal */
    buttonText: string
    /** Sets the text of the apply button */
    apply: string,
  }

  /** Invoked when the apply button is used to close the modal */
  onApply?: () => any
  /** Invoked when the modal is opened */
  onOpen?: () => any
  /** Invoked when the modal is closed without using the apply button */
  onCancel?: () => any
}

interface IState {
  open: boolean
}

/**
 * Renders a button that opens a modal when pressed.  The modal can be made a push
 * modal by setting the `modalClassName` property to `push slide`
 */
export class ButtonWithModal<P extends IProps, S extends IState> extends React.Component<P, S> {
  private defaultId: string

  constructor(props: P, context?: any) {
    super(props, context)

    this.defaultId = `ButtonWithModal-${Math.floor(Math.random() * 10000)}`

    this.state = {
      open: false,
    } as S

    this.onApply = this.onApply.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onOpen = this.onOpen.bind(this)
  }

  public render() {
    const { children, translations, icon, buttonColor, applyColor, className, modalClassName } = this.props
    const id = this.props.id || this.defaultId
    const skin = this.props.skin && `skin-${this.props.skin}`

    return <div className={className}>
      <button type="button" className={`btn ${buttonColor || 'btn-success'} ${className}-button`}
        onClick={this.onOpen}
        data-toggle="modal" data-target={`#${id}`}>
        <i className="material-icons">{icon}</i>{translations.buttonText}
      </button>

      <div id={id} className={`${modalClassName} modal ${className}-modal`} tabIndex={-1} role="dialog"
        aria-labelledby={`${id}-label`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <section className={`modal-header ${skin}`}>
              <h5 className="modal-title" id={`${id}-label`}>{translations.buttonText}</h5>
              <button type="button" className="close" aria-label="Close"
                onClick={this.onCancel} data-dismiss="modal">
                <i aria-hidden="true" className="material-icons">close</i>
              </button>
            </section>
            <section className={`modal-body ${skin}`}>
              {children}
            </section>
            <section className={`modal-footer p-0 ${skin}`}>
              <button type="button" className={`btn ${applyColor || 'btn-success'} w-100 p-3`}
                onClick={this.onApply} data-dismiss="modal">
                {translations.apply || 'Apply'}
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  }

  protected onOpen() {
    if (this.state.open) { return }

    this.setState({
      open: true,
    })

    if (this.props.onOpen) {
      this.props.onOpen()
    }
  }

  protected onApply() {
    if (!this.state.open) { return }

    this.setState({ open: false })

    if (this.props.onApply) {
      this.props.onApply()
    }
  }

  private onCancel() {
    if (!this.state.open) { return }

    this.setState({
      open: false,
    })

    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }
}
