import * as React from 'react'
import { ISeries } from '../../models/media'
import WatermarkMediaDefault from '~/images/watermark-media-default.png'

interface IProps {
  series: ISeries,
  isActive?: boolean,
  isCurrentSlide?: boolean,
  style?: React.CSSProperties
}

export function SeriesCard(props: IProps) {
  const { series, isActive, style, isCurrentSlide } = props
  const classes = ['card', 'series-card', 'col-md-3', 'col-10']
  if(isActive) classes.push('active')
  if(isCurrentSlide) classes.push('current-slide')

  return (
    <a href={`/series/${series.id}`} className={classes.join(' ')} style={style}>
      <div className='d-flex flex-column'>
        <div className='embed-responsive embed-responsive-16by9'>
          <img className='card-img-top embed-responsive-item' src={getSeriesImageUrl(series)} alt={series.title} />
        </div>
      </div>
      <div className='card-body'>
        <h5 className='card-title'>{series.title}</h5>
        <span className='media-caption'>{series.messages_count} messages</span>
        <p>{series.summary}</p>
      </div>
    </a>
  )
}

function getSeriesImageUrl(series: ISeries): string {
  if(!series.images) return WatermarkMediaDefault

  if(series.images.wide) {
    return series.images.wide.url
  } else if(series.images.banner) {
    return series.images.banner.url
  } else {
    return WatermarkMediaDefault
  }
}
