import * as React from 'react'
import { connectHits, Hit } from 'react-instantsearch-core'

import { ICompositePost } from '../../models/algolia/composite'
import ConnectedGalleryBlockHit from './block-hit'

interface IProps {
  hits: Array<Hit<ICompositePost>>
  columnClasses?: string[]
}

export class BlogGalleryBlockLayout extends React.Component<IProps> {
  public render() {
    const { hits } = this.props
    const columnClasses = this.props.columnClasses || ['col-12', 'col-md-4']

    return <div className="row hit-list">
      {hits.map((hit) => {
        return <div
          className={`${columnClasses.join(' ')} widget-blog-gallery__hit`}
          key={`blog-gallery-hit-${hit.date}`}
        >
          <ConnectedGalleryBlockHit hit={hit} />
        </div>
      })}
    </div>
  }
}

const ConnectedBlogGalleryBlockLayout = connectHits<IProps, ICompositePost>(BlogGalleryBlockLayout)
export default ConnectedBlogGalleryBlockLayout
