import * as React from 'react'
import { GivingMeter } from './giving-meter'
import { TimelineMeter } from './timeline-meter'

interface IGivingMeter {
  max: number
  current: number
  text: string
  class?: string
}

interface ITimelineMeter {
  startDate: string
  endDate: string
  text: string
  class?: string
}

interface IProgressMeterProps {
  giving?: IGivingMeter
  timeline?: ITimelineMeter
}

class ProgressMeter extends React.Component<IProgressMeterProps> {
  public render() {
    return (
      <>
        {this.props.giving && <GivingMeter {...this.props.giving} />}
        {this.props.timeline && <TimelineMeter {...this.props.timeline} />}
      </>
    )
  }
}

export default ProgressMeter
