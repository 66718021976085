

export interface Colspec {
  xs: number,
  [key: string]: number
}

export function parseColumnClasses(colspec: Colspec): string[] {
  const cols = [] as string[]

  Object.keys(colspec).forEach((key: keyof typeof colspec) => {
    if (key == 'xs') {
      cols.push(`col-${Math.round(12 / colspec.xs)}`)
    } else {
      cols.push(`col-${key}-${Math.round(12 / colspec[key])}`)
    }
  })

  return cols
}
