import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ICompositeEvent } from '../../../models/algolia/composite'
import { IEventOccurrence } from '../../../models/events'
import Event from '../../events-list/event'
import { TableViewOptions } from './any-hit'

type TableViewProps = TableViewOptions & {
  hit: Hit<ICompositeEvent>,
}

export class EventHitTableView extends React.PureComponent<TableViewProps> {
  public render() {
    const { hit } = this.props

    const event: IEventOccurrence = {
      ...hit.Event,
      ministry_code: hit.ministries && hit.ministries[0] && hit.ministries[0].code,
    }

    return <Event className="row"
      title={<Highlight attribute="Event.title" hit={hit} />}
      event={event}
      hit={hit} />
  }
}
