import * as React from 'react'
import { connectStateResults } from 'react-instantsearch-core'

interface IProps {
  className?: string
  isSearchStalled: boolean
}

export class LoadingBar extends React.PureComponent<IProps> {
  public render() {
    const { className, isSearchStalled } = this.props
    return <div className={`loading-bar ${className}`}
      style={ {visibility: isSearchStalled ? 'visible' : 'hidden' }}></div>
  }
}

export default connectStateResults(LoadingBar)
