import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ICompositeMinistry } from '../../../models/algolia/composite'
import { TableViewOptions } from './any-hit'
import { HitTableRow } from './hit-table-row'

type TableViewProps = TableViewOptions & {
  hit: Hit<ICompositeMinistry>,
}

export class MinistryHitTableView extends React.PureComponent<TableViewProps> {

  public render() {
    const { hit } = this.props
    const slug = hit.Ministry.homepage.slug
    const { descriptive_title } = hit.Ministry

    return <HitTableRow
      hit={hit}
      slug={slug}
      title={<Highlight attribute="Ministry.title" hit={hit} />}
      className="site-search-hit__ministry"
    >
      { descriptive_title && descriptive_title.length &&
        <h4><Highlight attribute="Ministry.descriptive_title" hit={hit} /></h4> }
      <p><Highlight attribute="Ministry.blurb" hit={hit} /></p>
      <p className="meta-data">Learn More</p>
    </HitTableRow>
  }
}
