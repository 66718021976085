import * as React from 'react'
import { connectMenu, MenuExposed, MenuProvided } from 'react-instantsearch-core'

type IProps = MenuExposed & {
  translations: {
    see_all_option: string,
  },
}

interface IMenuItem {
  label: string
  value: string
  count?: number
  isRefined?: boolean
}

class DropdownMenu extends React.Component<IProps & MenuProvided> {
  private domId: string

  constructor(props: DropdownMenu['props'], context?: any) {
    super(props, context)

    this.domId = `team_select_${Math.floor(Math.random() * 10000)}`

    this.refine = this.refine.bind(this)
  }

  public render() {
    const { currentRefinement, translations } = this.props
    return <div className="dropdown">
      <button
        className={
          'btn btn-outline-secondary btn-lg btn-block ' +
          'dropdown-toggle'
        }
        type="button"
        id={this.domId}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="current-refinement">{currentRefinement || translations.see_all_option}</span>
      </button>
      <div className="dropdown-menu dropdown-menu-right rounded-0" aria-labelledby={this.domId}>
        <div className="teams-wrapper">
          {this.props.items.map((item) => this.renderOption(item))}
        </div>
        {this.props.currentRefinement && this.renderShowAll()}
      </div>
    </div>
  }

  private refine(e: React.MouseEvent<any>, value: string) {
    e.preventDefault()

    this.props.refine(value)
  }

  private renderOption(item: IMenuItem) {
    const props = {
      className: `dropdown-item ${item.isRefined ? 'active' : ''}`,
      onClick: (e: React.MouseEvent<any>) => !item.isRefined && this.refine(e, item.value),
    }

    return <button {...props} key={`team-select-option=${item.value}`}>
      <span className="refinement">{item.label}</span>
    </button>
  }

  private renderShowAll() {
    return <>
      <div className="dropdown-divider"></div>
      {this.renderOption({ value: '', label: 'Show All' })}
    </>
  }
}

const ConnectedDropdownMenu = connectMenu(DropdownMenu)

const TeamSelect = ({attribute, ...props}: IProps) =>
  <ConnectedDropdownMenu
    attribute={attribute}
    limit={100}
    transformItems={(items) => items.sort((a: any, b: any) => ByLabel(a, b))}
    {...props}
  />

const ByLabel = (a: any, b: any) => (
  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
)

export { TeamSelect }
