import * as React from 'react'
import {
  connectCurrentRefinements,
  Refinement,
  RefinementValue,
} from 'react-instantsearch-core'

interface IProps {
  refine: RefinementValue
  items: Refinement[]
  translations: {
    clearRefinementsMsg?: string,
    clearRefinementsAction?: string,
  },
}

const ClearRefinements = ({ items, refine, translations }: IProps) => (
  <p>
    {translations.clearRefinementsMsg || 'No results.'}
    &nbsp;
    <a href="#" onClick={(evt) => {
      evt.preventDefault()
      refine(items)
    }}>
      {translations.clearRefinementsAction || 'Expand your search to exclude filters?'}
    </a>
  </p>
)

const ConnectedClearRefinements = connectCurrentRefinements(ClearRefinements)
export default ConnectedClearRefinements
