import { AlgoliaSearch, IInstantSearchProps } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import WCC from 'wcc'

import fetchIp from '../../util/fetch-ip'

interface IState {
  clientIP?: string,
}

export default class extends React.Component<IInstantSearchProps, IState> {
  public displayName = 'AlgoliaSearch'

  public render() {
    const {children, ...props} = this.props
    const blacklistIPs = WCC.CONSTANTS?.ALGOLIA_ANALYTICS_IP_BLACKLIST ?
      WCC.CONSTANTS.ALGOLIA_ANALYTICS_IP_BLACKLIST.split(',') : []

    const {clientIP} = this.state || {} as IState
    const isIPBlacklisted = !clientIP || (blacklistIPs.indexOf(clientIP) !== -1)

    return (
      <AlgoliaSearch
        apiKey={ WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering' }
        appId={ WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering' }
        hosts={ WCC.algoliaProxy ? [WCC.algoliaProxy] : undefined }
        { ...props }
      >
        { isIPBlacklisted && <Configure analytics={false} /> }
        {children}
      </AlgoliaSearch>
    )
  }

  public componentDidMount = async () => {
    this.setState({ clientIP: await fetchIp() })
  }
}
