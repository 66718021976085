import React from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

export interface HiddenAttributesForFacetingParams {
  attributes?: string[]
}

const HiddenRefinementList = connectRefinementList(() => null)

/**
 * Creates a "hidden" refinement list for the given attributes.
 * This allows us to craft a URL that applies this refinement, without having it visible in the UI.
 * This is helpful for view all links.
 */
export function HiddenAttributesForFaceting(params: HiddenAttributesForFacetingParams) {
  
  return <>
    {params.attributes?.map((attribute) => {
      return <HiddenRefinementList key={attribute} attribute={attribute} />
    })}
  </>
}
