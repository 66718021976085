import * as React from 'react'
import { RefinementListExposed } from 'react-instantsearch-core'

import AlphabeticalTableView from './alphabetical-view'
import CampusTableView from './campus-view'
import ScriptureTableView from './scripture-view'
import SortedTableView from './sorted-table-view'
import YearTableView from './year-view'

/** All known keys for which we have refinement list views. */
export type RefinementListView = 'default'
  | 'alphabetical'
  | 'scripture'
  | 'year'

export type ConnectedViewProps =
  Pick<
    RefinementListExposed,
    'attribute' | 'defaultRefinement' | 'operator' | 'limit' | 'showMore' | 'showMoreLimit'
  > & {
    /** Reverse the sort order of the view's refinement values.  Default is 'desc' by number of hits. */
    sortOrder?: 'asc' | 'desc',
  }

// These use the disjunctive 'or' for the operator.  If this changes, update the corresponding
// calculation inside autocomplete.tsx
const Views: { [view: string]: React.ComponentType<ConnectedViewProps> } = {
  default: SortedTableView,
  alphabetical: AlphabeticalTableView,
  scripture: ScriptureTableView,
  year: YearTableView,
  campus: CampusTableView,
}

/** Get the predefined view component for the given view key (one of RefinementListView) */
export function refinementListViewFor(view?: RefinementListView | null): typeof Views['default'] {
  return (view && Views[view]) || Views.default
}
