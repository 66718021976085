function pad(n: number | string, width: number, z?: string) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const formatDuration = (duration: number) => {
  const seconds = duration % 60
  const minutes = Math.floor(duration / 60) % 60
  const hours = Math.floor(duration / 60 / 60) % 24

  return [
    hours > 0 ? hours : null,
    pad(minutes, 2),
    pad(seconds, 2),
  ].filter((p) => p)
    .join(':')
}

export { formatDuration }
