import * as React from 'react'

interface ITooltipProps {
  title: string
}

export class Tooltip extends React.PureComponent<ITooltipProps> {
  private ref: React.RefObject<HTMLSpanElement>

  constructor(props: Tooltip['props'], context?: any) {
    super(props, context)

    this.ref = React.createRef()
  }

  public componentDidMount() {
    const node = this.ref.current
    if (node) {
      $(node).tooltip()
    }
  }

  public render() {
    return <span data-toggle="tooltip" title={this.props.title} ref={this.ref}>
      {this.props.children}
    </span>
  }
}
