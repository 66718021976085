import { createConnector } from 'react-instantsearch-core'

import { ITab } from '.'
import { isEmpty } from './views/utils'

interface ITabConfigureExposed {
  initialHit: ITab['initial_hit']
}

const configureInitialQuery = createConnector<Record<string, never>, ITabConfigureExposed>({
  displayName: 'TabConfigure',
  getProvidedProps() {
    return {}
  },
  getSearchParameters(searchParameters, _props, _state) {
    const {initialHit} = this.props
    if (!initialHit || isEmpty(initialHit.facets)) {
      // initial query for a tab that doesn't have one defined should be zero results
      return searchParameters.setHitsPerPage(0).setPage(0)
    }

    for (const attribute of Object.keys(initialHit.facets)) {
      const value = initialHit.facets[attribute]
      searchParameters = searchParameters.addDisjunctiveFacet(attribute)

      if (Array.isArray(value)) {
        value.forEach((v) =>
          searchParameters = searchParameters.addDisjunctiveFacetRefinement(attribute, v.toString()))
      } else {
        searchParameters = searchParameters.addDisjunctiveFacetRefinement(attribute, value.toString())
      }
    }

    searchParameters = searchParameters.setQueryParameters({
      ruleContexts: ['initial-query'],
      hitsPerPage: 1,
      page: 0,
    })

    return searchParameters
  },
})

const ConfigureInitialQuery = configureInitialQuery(() => null)
export default ConfigureInitialQuery
