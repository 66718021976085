import * as React from 'react'

import { DefaultFormatter, Formatter } from './timer/formatter'
import { CountdownContainer, now, round } from './util'

interface IProps {
  labelText?: string
  target: Date
  onExpiry?: () => void
  onRefresh?: () => void

  formatter?: Formatter
}

interface IState {
  from: number
  target: number
}

export class Timer extends React.Component<IProps, IState> {
  private interval?: ReturnType<typeof setTimeout>

  private readonly formatter: Formatter = DefaultFormatter

  constructor(props: IProps) {
    super(props)
    const targetTime = round(this.props.target).getTime()

    this.formatter = props.formatter || DefaultFormatter

    this.state = {
      from: now().getTime(),
      target: targetTime,
    }
  }

  public componentDidMount() {
    if (this.props.onRefresh) { this.props.onRefresh() }

    this.interval = setInterval(this.tick, 1000)
  }

  public componentWillUnmount() {
    if (!this.interval) { return }

    clearInterval(this.interval)
  }

  public render() {
    return <CountdownContainer>
      {this.props.labelText &&
        <h2 className="countdown-timer__label-text">{this.props.labelText}</h2>}
      <div className="countdown-timer__clock">
        {this.formatter(this.state.target, this.state.from)}
      </div>
      {this.props.children}
    </CountdownContainer>
  }

  private diffInSeconds() {
    return (this.state.target - this.state.from) / 1000
  }

  private tick = () => {
    if (this.diffInSeconds() <= 0) {
      if (this.props.onExpiry) { this.props.onExpiry() }
      return
    }

    this.setState({ from: now().getTime() })
  }
}
