import * as React from 'react'
import { connectRefinementList, RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'

export type Props = ITranslationsProvided
  & RefinementListExposed
  & RefinementListProvided
  & {
    /** Provide a sort function to sort by something other than number of hits */
    sortItems?: (slicedItems: RefinementListProvided['items'], allItems: RefinementListProvided['items']) =>
      RefinementListProvided['items'],

    /** Optionally reverse the sort order (default is 'desc' i.e. number of hits descending) */
    sortOrder?: 'asc' | 'desc',
  }

interface IViewState {
  extended: boolean
}

export class SortedTableView extends React.Component<Props, IViewState> {
  public static defaultProps = {
    sortOrder: 'desc' as Props['sortOrder'],
  }

  constructor(props: SortedTableView['props'], context?: any) {
    super(props, context)

    this.state = {
      extended: false,
    }
    this.showMore = this.showMore.bind(this)
    this.sort = this.sort.bind(this)
  }

  public render() {
    const { refine, t, showMore, showMoreLimit } = this.props
    const limit = this.props.limit || 10
    const { extended } = this.state

    let items = this.props.items
      .slice(0, extended ? showMoreLimit : limit)

    items = this.sort(items)

    if (items.length < 1) {
      return (
        <div className="refinement-list-empty">{t('attributes_for_faceting.noResults')}</div>
      )
    }

    return <table className="w-100 table table-borderless refinement-list">
      <thead>
        <tr>
          <td className="label" colSpan={3}></td>
          <td className="count"></td>
          <td className="clear"></td>
        </tr>
      </thead>
      <tbody>
        {items
          .map((item) => (
            <tr key={item.label} className={`${item.isRefined ? 'btn-tag' : 'btn-outline-dark'} refinement-list-item`}
              onClick={() => refine(item.value)}>
              <td className="label" colSpan={3}>{item.label}</td>
              <td className="count">{item.count}</td>
              <td className="clear"><i className="material-icons"
                style={{ visibility: item.isRefined ? 'visible' : 'hidden' }}>clear</i></td>
            </tr>),
          )}

        {/* Render & respond to the show more link if `showMore` is enabled via this.props.showMore */}
        {showMore && !extended && this.props.items.length > limit &&
          <tr><td><a className="btn btn-link" onClick={this.showMore}>{t('showMore')}</a></td></tr>}
      </tbody>
    </table>
  }

  private sort(items: Props['items']): Props['items'] {
    const { sortItems, sortOrder } = this.props
    if (sortItems) {
      items = sortItems(items, this.props.items)
    }
    if (sortOrder == 'asc') {
      items = items.reverse()
    }

    return items
  }

  private showMore() {
    this.setState({ extended: true })
  }
}

export default connectRefinementList(
  withTranslations(SortedTableView),
)
