import * as React from 'react'

interface IProps {
  label: string,
  count: number,
  isRefined: boolean,
  value: string,
  refine: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, label: string) => void
}

function Year(props: IProps) {
  const { label, count, isRefined, value, refine } = props
  return (
    <a onClick={(e) => !isRefined && refine(e, value)} key={label} className='archive-index-view__item'>
      <div className='archive-index-view__item__label'>
        <h3 className='archive-index-view__item__year'>{label}</h3>
        <p className='archive-index-view__item__count'>{count} Messages</p>
      </div>
      <button className='archive-index-view__item__view-button'>
        View Messages
        <i className='material-icons right-arrow'>arrow_forward</i>
      </button>
    </a>
  )
}

export default Year
