import React, { useState, useEffect } from 'react'
import { ISeries, ISeriesFilters, ISeriesQueryParams } from '../../models/media'
import { SeriesCard } from './series-card'
import Carousel from './carousel'
import { present, entries } from '../../util'
import { toString } from '../../util'

interface IPropsV1 {
  api_url: string,
  currentSeriesId: number,
  slidesToShow?: number,
  slidesToScroll?: number,
  slideScrollSpeed?: number,
  title: string,

  tag_id: number,
  limit?: number,
  
  // These are in IPropsV2
  queryParams?: undefined
  filters?: undefined

  fetch?: typeof window.fetch
}

interface IPropsV2 {
  api_url: string,
  currentSeriesId: number,
  slidesToShow?: number,
  slidesToScroll?: number,
  slideScrollSpeed?: number,
  title: string,

  queryParams?: ISeriesQueryParams
  
  filters?: ISeriesFilters

  fetch?: typeof window.fetch
}

export function SeriesGallery(props: IPropsV1 | IPropsV2) {
  const { api_url, currentSeriesId, title, slidesToScroll, slidesToShow, slideScrollSpeed } = props
  const [series, setSeries] = useState<ISeries[]>([])
  const [error, setError] = useState<Error | null>(null)

  const fetchImpl = props.fetch || window.fetch

  useEffect(() => {
    fetchSeries().then((series) => {
      setSeries(series)
    }).catch((error) => {
      setError(error)
    })
  }, [])

  if(error) {
    throw error
  }

  return (
    <div className="row justify-content-center">
      <div className='col-content-container'>
        <div className='row'>
          <div className='col-12'>
            <div className='series-gallery'>
              <div className='widget-series-gallery__header'>
                <h2>{title}</h2>
              </div>
              <Carousel settings={{
                slidesToShow: slidesToShow,
                slidesToScroll: slidesToScroll,
                speed: slideScrollSpeed,
              }}>
                {series.filter((series) => {
                  return series.id != currentSeriesId
                }).map((series) => (
                  <SeriesCard key={series.id.toString()} series={series} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  async function fetchSeries(): Promise<ISeries[]> {
    const url = new URL(api_url + 'series')
    
    const queryParams: ISeriesQueryParams = {
      limit: 5,
      ...props.queryParams
    }
    
    const filters: ISeriesFilters = {
      ...props.filters
    }
    if ('tag_id' in props) {
      filters.ministry_tag_id = props.tag_id
    }

    const params = new URLSearchParams(url.search)
    entries(queryParams).forEach(([key, value]) => {
      if (present(value)) {
        params.set(key, value.toString())
      }
    })
    entries(filters).forEach(([key, value]) => {
      if (present(value)) {
        if (Array.isArray(value)) {
          value = value.map(toString).join(',')
        }
        params.set(`filter[${key}]`, value.toString())
      }
    })
    url.search = params.toString()

    const response = await fetchImpl(url)

    if (response.status == 200) {
      const json = await response.json()

      return json.series
    } else {
      throw new Error(`Unexpected status for ${response.url} - ${response.status}`)
    }
  }
}
