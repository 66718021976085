import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import WCC from 'wcc'

import {
  AlgoliaSearch,
  HiddenFacets,
  MediaGallerySource,
} from '@watermarkchurch/react-instantsearch-components'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { ConnectedEpisodeList } from './episode-list'

interface IProps {
  header?: string
  indexName: string
  mediaSource: MediaGallerySource
}

class IndexWrapper extends React.Component<IProps & ITranslationsProvided> {
  public render() {
    const { indexName, mediaSource, ...rest } =  this.props

    let facets = { object_type: 'Message' }
    if (mediaSource.source == 'Algolia') {
      facets = {
        ...facets,
        ...mediaSource.hiddenFacets,
        ...mediaSource.facetFilters,
      }
    }

    return (
      <AlgoliaSearch
        indexName={indexName}
        widgetName="podcast-index"
        hosts={WCC.algoliaProxy ? [WCC.algoliaProxy] : undefined}
      >
        <Configure analytics={false} />
        <HiddenFacets hiddenFacets={facets} />
        <Configure hitsPerPage={mediaSource.hitsPerPage || 10} />
        <ConnectedEpisodeList { ...rest } />
      </AlgoliaSearch>
    )
  }
}
export default withTranslations(IndexWrapper)
