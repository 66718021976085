import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'
import { ICompositeMega } from '../../../models/algolia/composite'
import { fetchSectionHTML, renderSection } from '../../../util/async-render-section'
import { buildAnalyticsAttributes } from '../../algolia-search/insights'
import { TableViewOptions } from './any-hit'
import { HitTableRow } from './hit-table-row'
import { MegaCardHero } from './mega-card-hero'

type TableViewProps = TableViewOptions & {
  hit: Hit<ICompositeMega>,
}

interface IMegaState {
  sections: string[]
}


export class MegaHitTableView extends React.PureComponent<TableViewProps, IMegaState> {
  constructor(props: TableViewProps) {
    super(props)
    this.state = { sections: [] }
  }

  public async componentDidMount() {
    const { hit } = this.props
    const { sections } = hit.MegaSearchResult

    this.setState({
      sections: await Promise.all(
        sections.map(async (section, i) =>
          fetchSectionHTML(section.id, i),
        ),
      ),
    })
  }

  public render() {
    const { hit } = this.props

    if (hit.MegaSearchResult.header.object_type == 'SectionHero') {
      return (
        <div className='row site-search-hit__mega' {...buildAnalyticsAttributes(hit)}>
          <MegaCardHero hit={hit} />
          {
            this.state.sections.map((section) =>
              renderSection({ html: section }),
            )
          }
        </div>
      )
    }

    const { descriptive_title, homepage: { slug } } = hit.MegaSearchResult.header

    return <div className="row site-search-hit__mega"
      {...buildAnalyticsAttributes(hit)}>
      <HitTableRow
        hit={hit}
        slug={slug}
        title={<Highlight attribute="MegaSearchResult.header.title" hit={hit} />}
        className="site-search-hit__mega__header"
      >
        { descriptive_title && descriptive_title.length &&
          <h4><Highlight attribute="MegaSearchResult.header.descriptive_title" hit={hit} /></h4> }
        <p><Highlight attribute="MegaSearchResult.header.blurb" hit={hit} /></p>
        <p className="meta-data">Learn More</p>
      </HitTableRow>
      {
        this.state.sections.map((section) =>
          renderSection({ html: section }),
        )
      }
    </div>
  }
}
