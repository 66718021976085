import * as React from 'react'
import {
  connectCurrentRefinements,
  CurrentRefinementsProvided,
  Refinement,
  SearchState,
} from 'react-instantsearch-core'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { SiteSkins } from '../../models'
import { Accordion } from '../bootstrap/accordion'
import { ButtonWithModal } from '../bootstrap/button-with-modal'
import CurrentRefinementsBadges from './current-refinements-badges'
import { RefinementListView, refinementListViewFor } from './refinement-list'
import { isMobile } from './tab/views/utils'

interface IProps extends ITranslationsProvided {
  attributes: IAttributeSettings[]
  id?: string
  className?: string
  skin?: SiteSkins
}

export interface IAttributeSettings {
  /**
   * The attribute to facet on.
   */
  name: string,

  /**
   * Override the title displayed in the dropdown header.  When not present, this is
   * looked up from en.yml
   */
  title?: string

  /**
   * This defines the Template component used to render the refinement list.
   * default: lists facets matching the most hits first
   * scripture: lists books of the Bible in biblical order regardless of number of hits
   * year: lists years most recent first, always showing the current year regardless of number of hits
   */
  view?: RefinementListView

  /** Set to expand this attribute on load (default: false) */
  initiallyExpanded?: boolean

  /** Set to false to prevent showing the "show more" button */
  showMore?: boolean
  /** The number of items displayed initially */
  limit?: number
  /** The number of items displayed after hitting "show more" */
  showMoreLimit?: number
}

interface IState {
  open: boolean,
  refinementState?: Refinement[] | null,
}

class FacetFilterModal extends React.Component<IProps & CurrentRefinementsProvided, IState> {
  public static defaultProps = {
    className: 'facet-filter-modal',
  }

  constructor(props: IProps & CurrentRefinementsProvided, context?: any) {
    super(props, context)

    this.state = {
      open: false,
    }

    this.onApply = this.onApply.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onOpen = this.onOpen.bind(this)
  }

  public render() {
    const {id, className, skin, t} = this.props
    return <ButtonWithModal
      id={id}
      className={className}
      modalClassName="push slide"
      icon="filter_list"
      buttonColor="btn-outline-dark"
      applyColor="btn-danger"
      skin={skin}
      translations={ {
        buttonText: t('filterButtonText'),
        apply: t('apply'),
      } }

      onOpen={this.onOpen}
      onApply={this.onApply}
      onCancel={this.onCancel}
    >
      <CurrentRefinementsBadges />
      <FacetFilterPanel {...this.props} id={`${id}-panel`} />
    </ButtonWithModal>
  }

  private onOpen() {
    // If the tab has set an `initial_query`, we want to clear that
    // before popping open the panel, so we can see all the facets for a
    // normal default search instead of just the ones matching initial_query
    this.props.refine((ss: any) => (
      Object.assign(ss, { clearInitialQuery: true })
    ))

    this.setState({
      open: true,
      refinementState: this.props.items,
    })
  }

  private onApply() {
    if (!this.state.open) { return }

    this.props.refine((ss: any) => (
      Object.assign(ss, { clearInitialQuery: false })
    ))

    this.setState({
      open: false,
      refinementState: null,
    })
  }

  private onCancel() {
    if (!this.state.open) { return }

    const {refine} = this.props
    const {refinementState} = this.state
    refine((searchState) => {
      // change the search state's refinement list to look like the previous refinement list
      searchState.refinementList =
          refinementState ?
            refinementState.reduce(
              (hash, refinement) => {
                hash[refinement.attribute] = refinement.currentRefinement as string[]
                return hash
              },
              {} as Exclude<SearchState['refinementList'], undefined>)
            : undefined

      const ss = (searchState as any)
      ss.clearInitialQuery = false

      return searchState
    })

    this.setState({
      open: false,
      refinementState: null,
    })
  }
}

export const ConnectedFacetFilterModal = connectCurrentRefinements(
  withTranslations(FacetFilterModal),
)

class FacetFilterPanel extends React.PureComponent<IProps & ITranslationsProvided> {
  public static defaultProps = {
    className: 'facet-filter-panel',
  }

  private defaultId: string

  constructor(props: FacetFilterPanel['props'], context?: any) {
    super(props, context)

    this.defaultId = `FacetFilterPanel-${Math.floor(Math.random() * 10000)}`
  }

  public render() {
    const {className } = this.props
    const id = this.props.id || this.defaultId

    const attributes = this.props.attributes.map((att) => {
      return {
        ...att,
        // Set initially shown if on desktop and configuration requests it
        show: att.initiallyExpanded && !isMobile(),
      }
    })

    return <div id={id} className={className}>
      <Accordion id={`${id}-refinement-list`}
        translations={this.props.translationsAt('attributes_for_faceting') as any}
        items={attributes}>

        {(item) => {
          const AttributeView = refinementListViewFor(item.view)

          return (
            <>
              <AttributeView
                attribute={item.name}
                // enable the show more button
                showMore={item.showMore !== undefined ? item.showMore : true }
                limit={item.limit || 10}
                showMoreLimit={item.showMoreLimit || 999} />

              {item.name == 'date_year' && <p>Older messages can be found in the <a href='/archive'>Message Archive</a></p>}
            </>
          )
        }}
      </Accordion>
    </div>
  }
}

export const ConnectedFacetFilterPanel = withTranslations(
  FacetFilterPanel,
)
