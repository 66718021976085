export function isEmpty(obj: any) {
  if (!obj) { return true }

  for (const key of Object.keys(obj)) {
    const val = obj[key]
    if (val) {
      if (typeof(val) == 'string') {
        if (val.length > 0) {
          return false
        }
      } else {
        return false
      }
    }
  }

  return true
}

export function isMobile() {
  if (typeof window == 'undefined') {
    return false
  }
  return window.matchMedia('(max-width: 575.98px)').matches
}
