const getOrFetch = async (url: string) => {
  let response

  if (window && window.localStorage) {
    response = window.localStorage.getItem(url)
  }

  if (!response) {
    response = await (await fetch(url)).text()
    window.localStorage.setItem(url, response)
  }

  return response
}

export default async () => {
  return JSON.parse((await getOrFetch('/api/v1/ip'))).ip
}
