import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'
import format from '../../../date/format'
import { parseDateISOString } from '../../../date/parse'
import { ICompositeMessage } from '../../../models/algolia/composite'
import { IMessageDoc } from '../../../models/algolia/message'
import { formatDuration } from '../../../util/format-duration'
import { parameterize } from '../../../util/inflector'
import { BlockViewOptions, TableViewOptions } from './any-hit'
import { HitBlockCard } from './hit-block-card'
import { HitTableRow } from './hit-table-row'
import ScriptureReferencesHighlight from './scripture-reference-highlight'
import SpeakersHighlight from './speakers-highlight'

type MessageHit = Hit<IMessageDoc> | Hit<ICompositeMessage>

interface ITableViewProps extends TableViewOptions {
  hit: MessageHit,
}

const podcastEpisodeMeta = (episode: ICompositeMessage) =>
  <span>
    {[
      `Episode ${episode.Message.series_position}`,
      episode.date && format(parseDateISOString(episode.date), 'MMMM d, yyyy'),
    ].join(' • ')}
  </span>

const podcastEpisodeDurationAuthor = (episode: ICompositeMessage) =>
  <p className="media-caption">
    {episode.Message.duration &&
      <span className="duration">
        {formatDuration(episode.Message.duration)}
      </span>}
    <span className="speakers">
      <SpeakersHighlight attribute="Message.speakers" hit={episode} />
    </span>
  </p>

const DEFAULT_MSG_DATE_FORMAT = 'MMMM d, yyyy'

class PodcastHitTableView extends React.PureComponent<ITableViewProps & ITranslationsProvided> {
  public render() {
    const { hit } = this.props

    if ('object_type' in hit) {
      return this.renderCompositeHit(hit)
    }

    return this.renderHit(hit)
  }

  private renderHit(hit: Hit<IMessageDoc>) {
    const { thumbnail_url, title, scripture, date, objectID, series_id } = hit
    const slug = makeSlug(series_id, objectID, title)

    return <HitTableRow
      hit={hit}
      slug={slug}
      thumbnailUrl={thumbnail_url} // TODO: https://github.com/watermarkchurch/media/issues/194
      title={<Highlight attribute="title" hit={hit}></Highlight>}
      className="site-search-hit__podcast"
    >
      <span className="media-caption">
        <SpeakersHighlight attribute="speakers" hit={hit} />
        &nbsp;•&nbsp;
        {date && this.formatDate(new Date(Date.parse(date)))}
      </span>
      {scripture && <span className="media-caption">
        <ScriptureReferencesHighlight attribute="scripture" hit={hit} />
      </span>}
    </HitTableRow>
  }

  private renderCompositeHit(hit: Hit<ICompositeMessage>) {
    const { id, images, title, series } = hit.Message
    const slug = makeSlug(series.id, id, title)

    const thumbnailUrl = images.square_image_url || images.wide_image_url || images.banner_image_url
    const className = 'site-search-hit__podcast'

    return <a className={`row ${className} ${slug ? 'a-block' : ''}`} href={slug}>
      <div className={`col ${className}-thumb`}>
        {thumbnailUrl &&
          <div className="embed-responsive embed-responsive-1by1">
            <img className="embed-responsive-item" src={thumbnailUrl} />
          </div>}
      </div>
      <div className={`col ${className}-text d-inline-flex flex-column`}>
        <p className="media-caption date-episode-number">
          {podcastEpisodeMeta(hit)}
        </p>
        {title && <h3><Highlight attribute="title" hit={hit}></Highlight></h3>}
        {podcastEpisodeDurationAuthor(hit)}
      </div>
      <div className="site-search-hit__podcast__play-icon">
        <i className="material-icons">play_circle_filled</i>
      </div>
    </a>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, t('dateFormat'))
  }
}

export const ConnectedPodcastHitTableView = withTranslations(
  PodcastHitTableView,
  {
    dateFormat: DEFAULT_MSG_DATE_FORMAT,
  },
)

interface IBlockViewProps extends BlockViewOptions {
  hit: MessageHit,
}

class PodcastHitBlockView extends React.PureComponent<IBlockViewProps & ITranslationsProvided> {

  public render() {
    const { hit } = this.props

    if ('object_type' in hit) {
      return this.renderCompositeHit(hit)
    }

    return this.renderHit(hit)
  }

  public renderHit(hit: Hit<IMessageDoc>) {
    const slug = makeSlug(hit.series_id, hit.objectID, hit.title)

    return <HitBlockCard
      slug={slug}
      title={<Highlight attribute="title" hit={hit}></Highlight>}
      className="site-search-hit__podcast podcast-card"
      {...this.props}
    >
      <span className="media-caption">
        <SpeakersHighlight attribute="speakers" hit={hit} />
        &nbsp;•&nbsp;
        {this.formatDate(new Date(Date.parse(hit.date)))}
      </span>
      {hit.scripture && <br />}
      {hit.scripture && <span className="media-caption">
        <ScriptureReferencesHighlight attribute="scripture" hit={hit} limit={1} />
      </span>}
    </HitBlockCard>
  }

  public renderCompositeHit(hit: Hit<ICompositeMessage>) {
    const { title, id, series } = hit.Message
    const slug = makeSlug(series.id, id, title)
    const className = 'podcast-card site-search-hit__podcast'

    return <a href={slug} className={`${className} a-block d-block card`}>
      <div className="card-body">
        <p className="media-caption date-episode-number">
          <div className="site-search-hit__podcast__play-icon">
            <i className="material-icons">play_circle_filled</i>
          </div>
          {podcastEpisodeMeta(hit)}
        </p>
        {title && <h5 className="card-title">{<Highlight attribute="Message.title" hit={hit}></Highlight>}</h5>}
        {podcastEpisodeDurationAuthor(hit)}
      </div>
    </a>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, (t('dateFormat')) || DEFAULT_MSG_DATE_FORMAT)
  }
}

export const ConnectedPodcastHitBlockView = withTranslations(
  PodcastHitBlockView,
  {
    dateFormat: DEFAULT_MSG_DATE_FORMAT,
  },
)

function makeSlug(seriesId: string | number, id: string, title: string): string {
  title = title.split(/\s+/g).slice(0, 6).join(' ')
  return `/podcast/${seriesId}/${id}-${parameterize(title)}`
}
