import * as React from 'react'

import { Meter } from './meter'

interface IGivingMeter {
  max: number
  current: number
  text: string
  class?: string | null
}

export class GivingMeter extends React.Component<IGivingMeter> {
  public render() {
    const { max, current, text } = this.props
    return (
      <Meter
        primaryLabel={`$${current.toLocaleString('en-US')}`}
        secondaryLabel={text}
        class={this.props.class}
        percent={this.percentage(max, current)}
      />
    )
  }

  private percentage(max: number, current: number): number {
    return (current / max) * 100
  }
}
