import * as React from 'react'

import { rewriteContentfulImageUrl } from '../../../util/contentfulImage'
import { buildAnalyticsAttributes } from '../../algolia-search/insights'

interface IProps {
  hit: { objectID: string}

  aspectRatio?: string
  slug: string | undefined
  thumbnailUrl?: string | null
  thumbnailAlt?: string | null
  overline?: React.ReactNode
  title?: React.ReactNode

  className?: string
}

export class HitTableRow extends React.PureComponent<IProps> {
  public render() {
    const { hit, aspectRatio, slug, overline, title, children } = this.props

    const thumbnailUrl = this.props.thumbnailUrl && rewriteContentfulImageUrl(this.props.thumbnailUrl, { w: 640 })

    const className = this.props.className || 'ais-Hits-item'

    return <a className={`row ${className} ${slug ? 'a-block' : ''}`} href={slug}
      {...buildAnalyticsAttributes(hit)}
    >
      <div className={`col ${className}-thumb`}>
        {thumbnailUrl &&
          <div className={`embed-responsive embed-responsive-${aspectRatio || '1by1'}`}>
            <img className="embed-responsive-item" src={thumbnailUrl}
              alt={this.props.thumbnailAlt || undefined} />
          </div>}
      </div>
      <div className={`col ${className}-text d-inline-flex flex-column`}>
        {overline && <h5>{overline}</h5>}
        {title && <h3>{title}</h3>}
        {children}
      </div>
    </a>
  }
}
