import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'


import WatermarkMediaDefault from '~/images/watermark-media-default.png'
import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'
import format from '../../../date/format'
import { parseDateISOString } from '../../../date/parse'
import { ICompositePost } from '../../../models/algolia/composite'
import { exists } from '../../../util'
import { TableViewOptions } from './any-hit'
import { HitTableRow } from './hit-table-row'

type TableViewProps = TableViewOptions & ITranslationsProvided & {
  hit: Hit<ICompositePost>,
}

class HitTableView extends React.PureComponent<TableViewProps> {

  public render() {
    const { hit, t } = this.props
    const {
      author,
      date,
      slug,
      subtitle,
      hero_image_url,
      hero_image_alt_text,
      time_to_read,
    } = hit.Post

    return <HitTableRow
      hit={hit}
      slug={slug}
      title={<Highlight attribute="Post.title" hit={hit} />}
      thumbnailUrl={hero_image_url || WatermarkMediaDefault}
      thumbnailAlt={hero_image_alt_text}
      aspectRatio="16by9"
      className="site-search-hit__post"
    >
      {subtitle &&
        <h5 className="subtitle"><Highlight attribute="Post.subtitle" hit={hit} /></h5>}
      <span className="media-caption">
        {[
          author && author.full_name,
          date && this.formatDate(parseDateISOString(date)),
          time_to_read && time_to_read > 0 && t('time_to_read', { count: time_to_read }),
        ].filter(exists).join(' • ')}
      </span>
    </HitTableRow>
  }

  private formatDate(date: Date): string {
    const { t } = this.props

    return format(date, t('date_format'))
  }
}

const PostHitTableView = withTranslations(HitTableView, 'hit.post')

export { PostHitTableView }
