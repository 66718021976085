import * as React from 'react'
import { connectCurrentRefinements, CurrentRefinementsProvided, RefinementValue } from 'react-instantsearch-core'
import WCC from 'wcc'

import { uniqBy } from '../../util'

export class CurrentRefinementsBadges extends React.PureComponent<CurrentRefinementsProvided> {
  public render() {
    const {items, refine} = this.props
    return <>
      {uniqBy(items, 'attribute').map((refinement) => {
        /*
          * When existing several refinements for the same atribute name, then you get a
          * nested items object that contains a label and a value function to use to remove a single filter.
          * https://community.algolia.com/react-instantsearch/connectors/connectCurrentRefinements.html
          */
        if (refinement.items) {
          return refinement.items.map((i) => this.renderRefinement({
            ...i,
            attribute: refinement.attribute,
          },
          refine))
        }

        return this.renderRefinement({
          ...refinement,
          label: refinement.currentRefinement,
        },
        refine)
      })}
    </>
  }

  private renderRefinement(
    item: {
      attribute: string,
      label: string,
      value: RefinementValue,
    },
    refine: CurrentRefinementsProvided['refine'],
  ) {
    // TODO: if attribute view == 'campus' ?
    item = this.transformLabel(item)

    return <button key={item.label} className="btn btn-tag" onClick={() => refine(item.value)}>
      {item.label}
      <i className="material-icons">clear</i>
    </button>
  }

  private transformLabel = <T extends { label: string }>(item: T): T => {
    const campus = WCC.activeCampuses.find((c) => c.code == item.label)
    if (campus) {
      return {
        ...item,
        label: campus.name,
      }
    }
    return item
  }
}

export default connectCurrentRefinements(CurrentRefinementsBadges)
