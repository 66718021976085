import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

/**
 * This react component is when you don't want to implement a "block" or "table"
 * layout for some kind of hit just yet.
 */
export class UnexpectedHitView extends React.Component<{ hit: Hit<any> }> {
  public render() {
    return null
  }
}
