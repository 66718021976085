import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ICompositeRedirect } from '../../../models/algolia/composite'
import { TableViewOptions } from './any-hit'
import { HitTableRow } from './hit-table-row'

type TableViewProps = TableViewOptions & {
  hit: Hit<ICompositeRedirect>,
}

export class RedirectHitTableView extends React.PureComponent<TableViewProps> {

  public render() {
    const { hit } = this.props
    const href = hit.Redirect.href

    return <HitTableRow
      hit={hit}
      slug={href}
      title={<Highlight attribute="Redirect.title" hit={hit} />}
      className="site-search-hit__redirect"
    >
      <p><Highlight attribute="Redirect.description" hit={hit} /></p>
      <p className="meta-data">Learn More</p>
    </HitTableRow>
  }
}
