import * as React from 'react'
import { connectMenu, MenuExposed, MenuProvided } from 'react-instantsearch-core'

type IProps = MenuExposed & {
  translations: {
    see_all_option: string,
    noResults: string,
  },
}

interface IMenuItem {
  label: string
  value: string
  count?: number
  isRefined?: boolean
}

class DropdownMenu extends React.Component<IProps & MenuProvided> {
  private domId: string

  constructor(props: DropdownMenu['props']) {
    super(props)

    this.refine = this.refine.bind(this)
  }

  public render() {
    const { currentRefinement, translations } = this.props
    return <div className="dropdown">
      <button
        className={
          'btn btn-outline-secondary btn-lg btn-block ' +
          'dropdown-toggle'
        }
        type="button"
        id={this.domId}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="current-refinement">{currentRefinement || translations.see_all_option}</span>
      </button>
      <div className="dropdown-menu rounded-0"
        
        aria-labelledby={this.domId}>
        {!currentRefinement && this.props.items.length === 0 && <div className="dropdown-item noResults">{translations.noResults}</div>}
        {this.props.currentRefinement && this.renderShowAll()}
        <div className="scripture-wrapper">
          {this.props.items.map((item) => this.renderOption(item))}
        </div>
      </div>
    </div>
  }

  private refine(e: React.MouseEvent<any>, value: string) {
    e.preventDefault()
    const searchParams = new URLSearchParams(window.location.search)

    if(value !== '') {
      searchParams.set('scripture', value)
    } else {
      searchParams.delete('scripture')
    }
    window.history.pushState(null, '', `?${searchParams.toString()}`)

    this.props.refine(value)
  }

  private renderOption(item: IMenuItem) {
    const props = {
      className: `dropdown-item ${item.isRefined ? 'active' : ''}`,
      onClick: (e: React.MouseEvent<any>) => !item.isRefined && this.refine(e, item.value),
    }

    return <button {...props} key={`scripture-select-option=${item.value}`}>
      <span className="refinement">{item.label}</span>
    </button>
  }

  private renderShowAll() {
    return <>
      {this.renderOption({ value: '', label: 'Show All' })}
      <div className="dropdown-divider"></div>
    </>
  }
}

const ConnectedDropdownMenu = connectMenu(DropdownMenu)

const ScriptureSelect = ({attribute, ...props}: IProps) =>
  <ConnectedDropdownMenu
    attribute={attribute}
    limit={100}
    transformItems={(items) => items.sort(byScripturePosition)}
    {...props}
  />

export { ScriptureSelect }

function byScripturePosition(a: { label: string }, b: { label: string }): number {
  const aIndex = booksInOrder.indexOf(a.label.toLowerCase())
  const bIndex = booksInOrder.indexOf(b.label.toLowerCase())
  return aIndex - bIndex
}

const booksInOrder = [
  'genesis',
  'exodus',
  'leviticus',
  'numbers',
  'deuteronomy',
  'joshua',
  'judges',
  'ruth',
  '1 samuel',
  '2 samuel',
  '1 kings',
  '2 kings',
  '1 chronicles',
  '2 chronicles',
  'ezra',
  'nehemiah',
  'esther',
  'job',
  'psalms',
  'proverbs',
  'ecclesiastes',
  'song of solomon',
  'isaiah',
  'jeremiah',
  'lamentations',
  'ezekiel',
  'daniel',
  'hosea',
  'joel',
  'amos',
  'obadiah',
  'jonah',
  'micah',
  'nahum',
  'habakkuk',
  'zephaniah',
  'haggai',
  'zechariah',
  'malachi',
  'matthew',
  'mark',
  'luke',
  'john',
  'acts',
  'romans',
  '1 corinthians',
  '2 corinthians',
  'galatians',
  'ephesians',
  'philippians',
  'colossians',
  '1 thessalonians',
  '2 thessalonians',
  '1 timothy',
  '2 timothy',
  'titus',
  'philemon',
  'hebrews',
  'james',
  '1 peter',
  '2 peter',
  '1 john',
  '2 john',
  '3 john',
  'jude',
  'revelation',
]
