import addSeconds from 'date-fns/addSeconds'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'

interface IAddress {
  street: string
  city: string
  state: string
  zip: string
  geo: number[]
}

interface IArea extends IAddress {
  id: number
  name: string
  campus?: ICampus
}

interface ICampus extends IAddress {
  id: number
  name: string
}

interface ILocation extends IAddress {
  id: number
  name: string
  name_campus_context: string
  campus?: ICampus
  area: IArea
}

interface IContact {
  name: string
  phone: string
  email: string
}

interface IRegistration {
  id: number
  opens: string
  closes: string
  url: string
  type: string
  type_id: number
  cost: number
}

interface ISeries {
  id: number
  terminates: boolean
  count: number
  window: string[]
  ical: string
  events: Array<{ id: number, time: string }>
}

interface ITag {
  id: number
  name: string
}

interface IAsset {
  id: number
  mime_type: string
  size: string
  url: string
}

interface ICancellation {
  timestamp: string
  reason: string
  cancelled_by_id: number
}

export interface IEventOccurrence {
  id: number
  title: string
  time: string
  slug: string
  description: string
  description_markdown: string
  snippet: string
  duration: number
  flags: {
    childcare?: boolean
    featured?: boolean
    ongoing?: boolean,
  }
  campus?: ICampus
  location: ILocation
  tags: ITag[]
  registrations: {
    event?: IRegistration
    childcare?: IRegistration,
  }
  contact: IContact
  series: ISeries
  images: {
    wide?: IAsset,
    featured?: IAsset,
  },
  cancellation?: ICancellation | null,
  ministry_code: string | null,
  hide_from_internal_search: boolean,
  meta: {
    title_tag: string,
    description: string,
    keywords: string[],
    no_index: boolean,
    no_follow: boolean
  } | null
}

export interface IFeaturedEventOccurrence extends IEventOccurrence {
  flags: IEventOccurrence['flags'] & {
    featured: true,
  }
  images: IEventOccurrence['images'] & {
    featured: IAsset,
  }
}

export function isFeaturedEvent(event: IEventOccurrence): event is IFeaturedEventOccurrence {
  return event && event.flags && event.flags.featured || false
}

export function eachDayOfOccurrence(
  {time, duration}: Pick<IEventOccurrence, 'time' | 'duration'>,
): Date[] {
  const start = new Date(time)
  const end = addSeconds(start, duration)
  return eachDayOfInterval(
    {
      start,
      end,
    },
  )
}
