import * as React from 'react'

import { connectMenu, MenuExposed, MenuProvided, Hit } from 'react-instantsearch-core'
import { IMessageDoc } from '../../models/algolia/message'
import { ArchiveYearDetailView } from './archive-year-view'
import Year from './year'

interface IProps extends MenuExposed, MenuProvided {
  hits: Array<Hit<IMessageDoc>>,
}

class ArchiveIndexMenu extends React.Component<IProps> {
  constructor(props: ArchiveIndexMenu['props']) {
    super(props)

    this.refine = this.refine.bind(this)
  }

  public render() {
    const { items, hits } = this.props
    const searchParams = new URLSearchParams(window.location.search)

    if (searchParams.has('menu[date_year]') && searchParams.get('menu[date_year]') !== '')
    {
      const year = searchParams.get('menu[date_year]') as string
      return <ArchiveYearDetailView hits={hits} year={year} />
    } else {
      return (
        <div className='archive-index-view'>
          { items.sort((a, b) => ByValue(a.label, b.label)).map(({ label, count, isRefined, value }) => {
            return (
              <Year
                key={'year-' + label}
                label={label}
                count={count}
                isRefined={isRefined}
                value={value}
                refine={this.refine}
              />
            )
          })}
        </div>
      )
    }
  }

  private refine(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, label: string) {
    e.preventDefault()
    const searchParams = new URLSearchParams(window.location.search)

    window.history.pushState(null, '', `?${searchParams.toString()}`)
    this.props.refine(label)
  }
}

const ByValue = (a: string, b: string) => (
  a > b ? -1 : 1
)

export const ArchiveIndexView = connectMenu(ArchiveIndexMenu)
