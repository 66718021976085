import { Hit } from 'react-instantsearch-core'

import {
  ICompositeEvent,
  ICompositeMega,
  ICompositeMessage,
  ICompositeMinistry,
  ICompositeOpportunity,
  ICompositePage,
  ICompositePerson,
  ICompositePost,
  ICompositeRedirect,
} from '../../../models/algolia/composite'
import { IMessageDoc, isMessageHit } from '../../../models/algolia/message'

/**
 * Union of all the possible types of hits that could come back.
 *
 * Whenever we add a new type to the composite index, or add a whole new index,
 * add it here and then let Typescript tell you what else to update.
 */
const HitTypes = ['Message', 'Person', 'Opportunity', 'Page', 'Ministry', 'Event', 'MegaSearchResult'] as const

export type AnyHit =
  // hits from the composite index
  Hit<ICompositePerson>
  | Hit<ICompositeMessage>
  | Hit<ICompositeOpportunity>
  | Hit<ICompositePage>
  | Hit<ICompositePost>
  | Hit<ICompositeMinistry>
  | Hit<ICompositeEvent>
  | Hit<ICompositeMega>
  | Hit<ICompositeRedirect>

  // hits from other indexes
  | Hit<IMessageDoc>

interface IMatchTypeMap {
  Message: Hit<ICompositeMessage['Message']> | Hit<IMessageDoc>
  Person: Hit<ICompositePerson['Person']>
  Opportunity: Hit<ICompositeOpportunity['Opportunity']>
  Page: Hit<ICompositePage['Page']>
  Post: Hit<ICompositePost['Post']>
  Ministry: Hit<ICompositeMinistry['Ministry']>
  Event: Hit<ICompositeEvent['Event']>
  MegaSearchResult: Hit<ICompositeMega['MegaSearchResult']>
  Redirect: Hit<ICompositeRedirect['Redirect']>
}

/**
 * Pulls an object field off a hit (like 'Message' or 'Person') and adds in the highlight
 * result of that property to give it the shape of a Hit
 */
export function pickHitProp<
  H extends { objectID: string; _highlightResult: any },
  P extends keyof H
>(
  hit: H,
  prop: P,
): Hit<H[P]> {
  return Object.assign({
    objectID: hit.objectID,
    _highlightResult: hit._highlightResult[prop],
  }, hit[prop]) as any
}

type Transforms<U, Keys extends keyof IMatchTypeMap = keyof IMatchTypeMap> = {
  [K in Keys]: Transform<U, K>
}

type Transform<U, K extends keyof IMatchTypeMap> =
  (hit: IMatchTypeMap[K], path: string | null) => U

const defaultTransform = HitTypes.reduce((memo, key) => {
  memo[key] = raiseUnrecognizedHit as any
  return memo
}, {} as Transforms<any>)

export function pickHit<Keys extends keyof IMatchTypeMap>(
  hit: AnyHit,
  ...keys: Keys[]): IMatchTypeMap[Keys] {
  const transforms: Transforms<IMatchTypeMap[Keys]> = Object.assign({}, defaultTransform)
  keys.forEach((key) => {
    transforms[key] = identity as any
  })

  return matchHit(hit, Object.assign({}, defaultTransform, transforms))
}

export function matchPartialHit<U, Key extends keyof IMatchTypeMap>(
  hit: AnyHit,
  key: Key,
  transform: Transform<U, Key>,
): U {
  const transforms: Transforms<U> = Object.assign({}, defaultTransform)
  transforms[key] = transform as Transform<U, keyof IMatchTypeMap>

  return matchHit(hit, transforms)
}

/**
 * Figures out what kind of hit this is, regardless of which index it comes from,
 * and calls the appropriate transform function for that hit.
 * Useful for grabbing values like the `title` which are common to messages in both
 * the composite and regular indexes.
 * @param hit The hit which is of an unknown shape
 * @param transforms The function to apply if it's a message, person, etc.
 */
export function matchHit<U>(hit: AnyHit, transforms: Transforms<U>): U {
  if ('object_type' in hit) {
    switch (hit.object_type) {
    case 'Message':
      return transforms.Message(pickHitProp(hit, 'Message'), 'Message')
    case 'Person':
      return transforms.Person(pickHitProp(hit, 'Person'), 'Person')
    case 'Opportunity':
      return transforms.Opportunity(pickHitProp(hit, 'Opportunity'), 'Opportunity')
    case 'Page':
      return transforms.Page(pickHitProp(hit, 'Page'), 'Page')
    case 'Post':
      return transforms.Post(pickHitProp(hit, 'Post'), 'Post')
    case 'Event':
      return transforms.Event(pickHitProp(hit, 'Event'), 'Event')
    case 'Ministry':
      return transforms.Ministry(pickHitProp(hit, 'Ministry'), 'Ministry')
    case 'MegaSearchResult':
      return transforms.MegaSearchResult(pickHitProp(hit, 'MegaSearchResult'), 'MegaSearchResult')
    case 'Redirect':
      return transforms.Redirect(pickHitProp(hit, 'Redirect'), 'Redirect')
    default:
      return raiseUnrecognizedHit(hit)
    }
  } else if (isMessageHit(hit)) {
    return transforms.Message(hit, null)
  }
  // else other non-composite hits
  return raiseUnrecognizedHit(hit)
}

export function raiseUnrecognizedHit(hit: never): never {
  throw new Error(`Unrecognized hit model ${'objectID' in hit ? `objectID: ${(hit as any).objectID}` : hit}`)
}

function identity<T>(o: T): T {
  return o
}
