import React from 'react'
import { connectInfiniteHits, Hit } from 'react-instantsearch-core'

import { ICompositeMessage } from '../../models/algolia/composite'
import { IMessageDoc } from '../../models/algolia/message'
import { ConnectedMessageHitBlockView, ConnectedMessageHitTableView } from '../SiteSearch/hits/message'
import { isMobile } from '../SiteSearch/tab/views/utils'

interface IConnectInfiniteHitsProps {
  hits: Array<Hit<ICompositeMessage>>
  hasMore?: any
  refineNext?: any
}

type MessageHit = Hit<IMessageDoc> | Hit<ICompositeMessage>

const ResponsiveHit = (props: { hit: MessageHit }) => {
  return isMobile() ?
    <ConnectedMessageHitBlockView hit={props.hit} /> :
    <ConnectedMessageHitTableView hit={props.hit} />
}

const RenderEpisodes = ({ hits, hasMore, refineNext }: IConnectInfiniteHitsProps) =>
  <div className={`infinite-hits w-100 ${isMobile() ? 'block-view' : 'table-view'}`}>
    {hits.map((hit) => <ResponsiveHit hit={hit} key={hit.objectID} />)}
    {hasMore &&
      <div className="d-flex justify-content-center">
        <button className="btn btn-link load-more" onClick={refineNext}>
          Load More Episodes
        </button>
      </div>}
  </div>

const PaginatedEpisodes = connectInfiniteHits(RenderEpisodes)

export {
  PaginatedEpisodes,
}
