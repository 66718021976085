import * as React from 'react'

import { Timer } from './timer'
import { TemplateFormatter } from './timer/formatter'
import { CountdownContainer, timeExpired, topOfMinute } from './util'

interface IProps {
  labelText?: string
  timestamp: string
  template?: string

  onExpiry?: () => void
}
interface IState {
  targetTime: Date
  mounted?: boolean
}

export class SimpleCountdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { targetTime: topOfMinute(new Date(this.props.timestamp)) }
  }

  public componentDidMount() {
    const { onExpiry } = this.props
    if (timeExpired(this.state.targetTime) && onExpiry) { onExpiry() }
    this.setState({ mounted: true })
  }

  public render() {
    const { labelText, onExpiry } = this.props
    const { targetTime, mounted } = this.state

    if (timeExpired(targetTime)) { return null }

    if (!mounted) {
      // At prerendering time, we might cache an inappropriate time remaining
      return <CountdownContainer>
        <h2 className="countdown-timer__label-text">{this.props.labelText}</h2>
        <div className="loading-bar"/>
      </CountdownContainer>
    }

    return <Timer
      target={targetTime}
      formatter={this.props.template ? TemplateFormatter(this.props.template, { allowHtml: true }) : undefined}
      onExpiry={onExpiry}
      labelText={labelText}
    />
  }
}
