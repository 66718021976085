import * as React from 'react'

import { IHitBlockProps } from './hit-block'

import WatermarkMediaDefault from '~/images/watermark-media-default.png'
import { rewriteContentfulImageUrl } from '../../../util/contentfulImage'
import { buildAnalyticsAttributes as buildInsightsAttributes } from '../../algolia-search/insights'

export class HitBlockCard extends React.Component<IHitBlockProps> {
  public static defaultProps: Partial<IHitBlockProps> = {
    className: 'ais-Hits-item',
    aspectRatio: '16by9',
    thumbPosition: 'top',
  }

  public render() {
    const {
      hit,
      slug,
      title,
      className,
      thumbPosition,
      aspectRatio,
      thumbnailOverlay,
      children,
      overline,
    } = this.props

    const thumbnailUrl = this.props.thumbnailUrl && rewriteContentfulImageUrl(this.props.thumbnailUrl, { w: 640 })

    const thumb = <div className="d-flex flex-column">
      <div className={`${className}-thumb embed-responsive embed-responsive-${aspectRatio}`}>
        <img className={`embed-responsive-item card-img-${(thumbPosition != 'bottom') ? 'top' : 'bottom'}`}
          src={thumbnailUrl || WatermarkMediaDefault} />
        {thumbnailOverlay}
      </div>
    </div>

    return <a href={slug} className={`${className} a-block d-block card`}
      {...buildInsightsAttributes(hit)}
    >
      {thumbPosition != 'bottom' && thumb}
      <div className="card-body">
        {overline && <h5>{overline}</h5>}
        {title && <h2 className="card-title">{title}</h2>}
        {children}
      </div>
      {thumbPosition == 'bottom' && thumb}
    </a>
  }
}
