import * as React from 'react'

interface IMeterProps {
  primaryLabel: string
  secondaryLabel: string
  class?: string | null
  percent: number
}

export class Meter extends React.Component<IMeterProps> {
  public render() {
    const { primaryLabel, secondaryLabel, percent } = this.props
    const classColor = this.props.class ? this.props.class : 'feldgrau'
    return (
      <div className="meter">
        <div className="progress">
          <div
            className={`progress-bar bg-${classColor}`}
            role="progressbar"
            style={{ width: `${percent}%` }}
            aria-valuenow={percent}
            aria-valuemin={0}
            aria-valuemax={100}
          >
          </div>
        </div>
        <h4 className={`text-${classColor}`}>{primaryLabel}</h4>
        <p>{secondaryLabel}</p>
      </div>
    )
  }
}
