import * as React from 'react'
import { connectMenu, MenuExposed, MenuProvided } from 'react-instantsearch-core'

type IProps = MenuExposed & {
  translations: {
    see_all_option: string,
    noResults: string,
  },
}

interface IMenuItem {
  label: string
  value: string
  count?: number
  isRefined?: boolean
}


class DropdownMenu extends React.Component<IProps & MenuProvided> {
  private domId: string

  constructor(props: DropdownMenu['props']) {
    super(props)

    this.domId = `team_select_${Math.floor(Math.random() * 10000)}`

    this.refine = this.refine.bind(this)
  }

  public render() {
    const { currentRefinement, translations } = this.props
    return <div className="dropdown">
      <button
        className={
          'btn btn-outline-secondary btn-lg btn-block ' +
          'dropdown-toggle'
        }
        type="button"
        id={this.domId}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="current-refinement">{currentRefinement || translations.see_all_option}</span>
      </button>
      <div className="dropdown-menu dropdown-menu-right rounded-0"
        aria-labelledby={this.domId}>
        {!currentRefinement && this.props.items.length === 0 && <div className="dropdown-item noResults">{translations.noResults}</div>}
        {this.props.currentRefinement && this.renderShowAll()}
        <div className="tags-wrapper">
          {this.props.items.map((item) => this.renderOption(item))}
        </div>
      </div>
    </div>
  }

  private refine(e: React.MouseEvent<any>, value: string) {
    e.preventDefault()
    const searchParams = new URLSearchParams(window.location.search)

    if(value !== '') {
      searchParams.set('ministry', value)
    } else {
      searchParams.delete('ministry')
    }

    window.history.pushState(null, '', `?${searchParams.toString()}`)

    this.props.refine(value)
  }

  private renderOption(item: IMenuItem) {
    const props = {
      className: `dropdown-item ${item.isRefined ? 'active' : ''}`,
      onClick: (e: React.MouseEvent<any>) => !item.isRefined && this.refine(e, item.value),
    }

    return <button {...props} key={`team-select-option=${item.value}`}>
      <span className="refinement">{item.label}</span>
    </button>
  }

  private renderShowAll() {
    return <>
      {this.renderOption({ value: '', label: 'Show All' })}
      <div className="dropdown-divider"></div>
    </>
  }
}
const ConnectedDropdownMenu = connectMenu(DropdownMenu)

const TagSelect = ({attribute, ...props}: IProps) =>
  <ConnectedDropdownMenu
    attribute={attribute}
    limit={100}
    transformItems={(items) => items.sort((a: any, b: any) => ByLabel(a, b))}
    {...props}
  />

const ByLabel = (a: any, b: any) => (
  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
)

export { TagSelect }
