import React from 'react'
import template from 'lodash-es/template'
import format from 'date-fns/format'
import formatDuration from 'date-fns/formatDuration'
import formatDistance from 'date-fns/formatDistance'
import intervalToDuration from 'date-fns/intervalToDuration'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

import { pluralize } from '../util'

export type Formatter = (
  /** The target date, when the countdown expires */
  date: number,
  /** The start date, i.e. now */
  baseDate: number,
) => JSX.Element | JSX.Element[]

interface TemplateFormatterOptions {
  /**
  * An object to import into the template as local variables.
  */
  imports?: Record<string, any> | undefined;

  /**
   * True to allow the template to contain HTML, false to escape HTML
   */
  allowHtml?: boolean
}

export function TemplateFormatter(templateString: string, options?: TemplateFormatterOptions): Formatter {
  const {allowHtml, ...templateOptions} = options || {}
  const templateFunc = template(templateString, {
    ...templateOptions,
    imports: {
      // allow access to "format" functions
      format,
      ...templateOptions.imports,
    }
  })

  // eslint-disable-next-line react/display-name
  return (date, baseDate) => {
    const duration = intervalToDuration({ start: baseDate, end: date })

    const rendered = templateFunc({
      timestamp: date,
      now: baseDate,
      remaining: formatDistanceStrict(date, baseDate),
      formatDuration: function(options?: any) {
        return formatDuration(duration, options)
      },
      formatDistance: function(options?: any) {
        return formatDistance(date, baseDate, options)
      },
    })

    if (allowHtml) {
      return <span className="countdown-timer__clock__formatted"
        dangerouslySetInnerHTML={{ __html: rendered }}></span>
    }

    return <span className="countdown-timer__clock__formatted">{rendered}</span>
  }
}

export const DefaultFormatter: Formatter = (date, baseDate) => {
  const parts = calculateParts((date - baseDate)  / 1000)
  return parts.map((part) =>
    <span className="countdown-timer__clock__part" key={`part-${part.label}`}>
      <span className="countdown-timer__clock__part__number">
        {part.value >= 10 ? part.value : `0${part.value}`}
      </span>
    </span>,
  )
}

interface IPart {
  label: string
  value: number
}

function calculateParts(diffInSeconds: number): IPart[] {
  const parts: IPart[] = []

  const seconds = diffInSeconds % 60
  const minutes = Math.floor(diffInSeconds / 60) % 60
  const hours = Math.floor(diffInSeconds / 60 / 60) % 24
  const days = Math.floor(diffInSeconds / 60 / 60 / 24)

  if (days > 0) {
    parts.push({ label: pluralize('Day', days), value: days })
  }

  return parts.concat([
    { label: pluralize('Hour', hours), value: hours },
    { label: pluralize('Minute', minutes), value: minutes },
    { label: pluralize('Second', seconds), value: seconds },
  ])
}
