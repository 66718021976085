import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { AnyHit } from '.'
import {
  ICompositeEvent,
  ICompositeMega,
  ICompositeMessage,
  ICompositeMinistry,
  ICompositeOpportunity,
  ICompositePage,
  ICompositePerson,
  ICompositePost,
  ICompositeRedirect,
} from '../../../models/algolia/composite'
import { IMessageDoc, isMessageHit } from '../../../models/algolia/message'
import { withErrorBoundary } from '../../error-boundary'
import { EventHitTableView } from './event-table'
import { IHitBlockProps } from './hit-block'
import { MegaHitTableView } from './mega-table'
import { ConnectedMessageHitBlockView, ConnectedMessageHitTableView } from './message'
import { MinistryHitTableView } from './ministry-table'
import ConnectedOpportunityHitTableView from './opportunity-table'
import { PageHitTableView } from './page-table'
import { PersonHitBlockView } from './person-block'
import { PersonHitTableView } from './person-table'
import { PostHitBlockView } from './post-block'
import { PostHit } from './post-hit'
import { RedirectHitTableView } from './redirect-table'
import { UnexpectedHitView } from './unexpected'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommonViewProps {
}

export type TableViewOptions = ICommonViewProps

export const AnyHitTableView = withErrorBoundary(
  wrapAsViewSelector<TableViewOptions>(
    ConnectedMessageHitTableView,
    PersonHitTableView,
    ConnectedOpportunityHitTableView,
    PageHitTableView,
    PostHit,
    MinistryHitTableView,
    EventHitTableView,
    MegaHitTableView,
    RedirectHitTableView,
  ),
)

/* The block view properties which are not dependent on the hit, but can be set by consumers of the block view */
export type BlockViewOptions = Pick<IHitBlockProps, 'thumbPosition'> & ICommonViewProps

export const AnyHitBlockView = withErrorBoundary(
  wrapAsViewSelector<BlockViewOptions>(
    ConnectedMessageHitBlockView,
    PersonHitBlockView,
    UnexpectedHitView,
    PageHitTableView,
    PostHitBlockView,
    MinistryHitTableView,
    EventHitTableView,
    UnexpectedHitView,
    RedirectHitTableView,
  ),
)

function wrapAsViewSelector<TOptions>(
  MessageHitView: React.ComponentType<TOptions & { hit: Hit<IMessageDoc> | Hit<ICompositeMessage> }>,
  PersonHitView: React.ComponentType<TOptions & { hit: Hit<ICompositePerson> }>,
  OpportunityHitView: React.ComponentType<TOptions & { hit: Hit<ICompositeOpportunity> }>,
  PageHitView: React.ComponentType<TOptions & { hit: Hit<ICompositePage> }>,
  PostHitView: React.ComponentType<TOptions & { hit: Hit<ICompositePost> }>,
  MinistryHitView: React.ComponentType<TOptions & { hit: Hit<ICompositeMinistry> }>,
  EventHitView: React.ComponentType<TOptions & { hit: Hit<ICompositeEvent> }>,
  MegaHitView: React.ComponentType<TOptions & { hit: Hit<ICompositeMega> }>,
  RedirectHitView: React.ComponentType<TOptions & { hit: Hit<ICompositeRedirect> }>,
): React.ComponentType<TOptions & { hit: AnyHit }> {
  // eslint-disable-next-line react/display-name
  return (props: TOptions & { hit: AnyHit }) => {
    const { hit } = props
    if ('object_type' in hit) {
      switch (hit.object_type) {
      case 'Message':
        return <MessageHitView {...props} hit={hit} />
      case 'Person':
        return <PersonHitView {...props}
          hit={hit} />
      case 'Opportunity':
        return <OpportunityHitView {...props}
          hit={hit} />
      case 'Page':
        return <PageHitView {...props} hit={hit} />
      case 'Post':
        return <PostHitView {...props} hit={hit} />
      case 'Ministry':
        return <MinistryHitView {...props} hit={hit} />
      case 'Event':
        return <EventHitView {...props} hit={hit} />
      case 'MegaSearchResult':
        return <MegaHitView {...props} hit={hit} />
      case 'Redirect':
        return <RedirectHitView {...props} hit={hit} />
      default:
        return raiseUnrecognizedHit(hit)
      }
    } else if (isMessageHit(hit)) {
      return <MessageHitView {...props} hit={hit} />
    }
    return raiseUnrecognizedHit(hit)
  }
}

function raiseUnrecognizedHit(hit: never): never {
  throw new Error(`Unrecognized hit model: ${'objectID' in hit ? (hit as any).objectID : hit}`)
}
