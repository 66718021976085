import * as $ from 'jquery'
import * as React from 'react'
import { findDOMNode } from 'react-dom'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { Stringable } from '../../util'
import LiveStreamCountdown from './live-stream-countdown'
import { SimpleCountdown } from './simple-countdown'

interface IProps {
  labelText?: string
  liveStreamId?: Stringable
  timestamp?: string
  template?: string
  colors?: Record<string, string>
  align?: 'left' | 'center' | 'right'
}
interface IState {
  expired: boolean
}

class CountdownTimerWrapper extends React.Component<IProps & ITranslationsProvided, IState> {
  private sectionContainerNode?: JQuery<Node>

  constructor(props: CountdownTimerWrapper['props'], context?: any) {
    super(props, context)

    this.state = { expired: false }
  }

  public async componentDidMount() {
    // TODO: findDOMNode is deprecated, replace this with a React ref
    // eslint-disable-next-line react/no-find-dom-node
    const node = findDOMNode(this)

    if (node && node.parentNode) {
      this.sectionContainerNode = $(node.parentNode).parents('section')
      this.displaySections()
    }
  }

  public render() {
    const { liveStreamId, labelText, template, timestamp, t } = this.props
    
    if (liveStreamId) {
      return <div className="countdown-timer-root" style={this.wrapperStyles()}>
        <LiveStreamCountdown
          // note: This cannot change w/ 'expired' b/c we get into a remounting loop
          key={`live-stream-countdown`}
          id={liveStreamId}
          labelText={ labelText || t('labelText') }
          template={template}
          onRefresh={ this.refresh }
        />
      </div>
    } else if (timestamp) {
      return <div className="countdown-timer-root" style={this.wrapperStyles()}>
        <SimpleCountdown
          key={`simple-countdown-${this.state.expired ? 'off' : 'on'}`}
          timestamp={ timestamp }
          labelText={ labelText }
          template={template}
          onExpiry={ this.expire }
        />
      </div>
    }

    return null
  }

  public expire = () => {
    this.setState({ expired: true })
    this.displaySections()
  }

  public refresh = () => {
    this.setState({ expired: false })
    this.displaySections()
  }

  private wrapperStyles() {
    const {colors, align} = this.props
    const styles: Record<string, string> = {}

    if (colors) {
      Object.keys(colors).forEach((color) => {
        styles[`--color-${color}`] = colors[color]
      })
    }
    if (align) {
      styles['--align'] = align
    }

    return styles
  }

  private displaySections() {
    if (!this.sectionContainerNode) { return }

    $('.async-section-container', this.sectionContainerNode).addClass('hidden')
    $(this.sectionContainerNode)
      .find(`.js-countdown-expired-${this.state.expired}`)
      .removeClass('hidden')
  }
}

export default withTranslations(
  CountdownTimerWrapper,
  {
    labelText: 'Next Live Stream begins in',
  },
)
