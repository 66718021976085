import * as React from 'react'

import startOfDay from 'date-fns/startOfDay'
import Calendar, { CalendarProps, ViewCallback } from 'react-calendar/dist/entry.nostyle'
import { Tooltip } from '../../components/bootstrap/tooltip'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import format from '../../date/format'
import { eachDayOfOccurrence, IEventOccurrence } from '../../models/events'

type IProps = ITranslationsProvided & {
  event: IEventOccurrence,
}

interface IState {
  currentActiveDate: Date
}

const begOfTime = new Date(-999999999999999)
const endOfTime = new Date(999999999999999)
const now = new Date()

const formatWeekdayHeader = (locale: string, date: Date) =>
  format(date, 'iiiiii')
const roundDT = (date: Date, hours: number = 0): Date => {
  const copy = new Date(date.getTime())
  copy.setHours(hours, 0, 0, 0)
  return copy
}
const isUpcoming = (date: Date) => date >= startOfDay(now)

const today = roundDT(now)

class EventCalendar extends React.Component<IProps, IState> {
  private eventDate: Date
  private earliestDate: Date
  private earliestUpcomingDate: Date
  private latestDate: Date
  private latestUpcomingDate: Date
  private seriesDates: { [key: string]: Date }
  private tooltipText: string

  constructor(props: IProps) {
    super(props)

    this.earliestDate =
      this.latestDate =
      this.eventDate = roundDT(new Date(this.props.event.time))

    this.earliestUpcomingDate = endOfTime
    this.latestUpcomingDate = begOfTime
    this.seriesDates = {}

    const isMultiday = eachDayOfOccurrence(props.event).length > 1

    this.tooltipText = this.props.t(
      isMultiday ? 'upcoming.multiday' : 'upcoming.single',
      {
        title: this.props.event.title,
        time: format(this.props.event.time, 'h:mm a'),
      },
    )

    props.event.series.events.forEach(({ time }) => {
      const allDays = eachDayOfOccurrence({
        ...this.props.event,
        time,
      })
      const firstDay = allDays[0]
      const lastDay = allDays[allDays.length - 1]

      allDays.forEach((dt) => {
        this.seriesDates[dt.toDateString()] = dt
      })

      if (isUpcoming(firstDay)) {
        if (firstDay < this.earliestUpcomingDate) {
          this.earliestUpcomingDate = firstDay
        }

        if (firstDay > this.latestUpcomingDate) {
          this.latestUpcomingDate = firstDay
        }
      }

      if (firstDay < this.earliestDate) {
        this.earliestDate = firstDay
      }

      if (lastDay > this.latestDate) {
        this.latestDate = lastDay
      }
    })

    this.earliestDate = roundDT(this.earliestDate)
    this.latestDate = roundDT(this.latestDate, 23)

    this.state = {
      currentActiveDate: this.initialDate(),
    }

    this.tileClassNames = this.tileClassNames.bind(this)
    this.tileContent = this.tileContent.bind(this)
  }

  public navLabel(iconClass: string) {
    return <>
      <i className="material-icons">{iconClass}</i>
      <Tooltip title={this.props.t('has-more')} />
    </>
  }

  public render() {
    const classNames = []
    const calendarOptions: CalendarProps = {
      activeStartDate: this.state.currentActiveDate,
      calendarType: 'US',
      formatShortWeekday: formatWeekdayHeader,
      maxDate: this.latestDate,
      minDate: this.earliestDate,
      minDetail: 'month',
      nextLabel: this.navLabel('arrow_forward'),
      prevLabel: this.navLabel('arrow_back'),
      onActiveDateChange: this.onActiveDateChange,
      selectRange: false,
      showNeighboringMonth: false,
      tileDisabled: (_) => true,
      tileClassName: this.tileClassNames,
      tileContent: this.tileContent,
    }

    if (this.state.currentActiveDate > this.earliestUpcomingDate) {
      classNames.push('has-previous')
    }

    if (this.state.currentActiveDate < this.latestUpcomingDate) {
      classNames.push('has-next')
    }

    calendarOptions.className = classNames

    return <Calendar { ...calendarOptions } />
  }

  private onActiveDateChange: ViewCallback = ({ activeStartDate }) => {
    this.setState({
      currentActiveDate: activeStartDate,
    })
  }

  private tileClassNames({ date }: { date: Date }): string[] {
    const match = this.seriesDates[date.toDateString()]
    const classNames = ['h4']

    if (this.inRange(date) && match) {
      classNames.push('has-event')
      classNames.push(isUpcoming(match) ? 'upcoming' : 'past')
    }

    return classNames
  }

  private tileContent({ date }: { date: Date }) {
    const match = this.seriesDates[date.toDateString()]

    if (!match) { return null }

    const text = isUpcoming(match) ? this.tooltipText : this.props.t('past')

    return <Tooltip title={text} />
  }

  private inRange(date: Date) {
    return this.earliestDate <= date && date <= this.latestDate
  }

  private initialDate() {
    if (this.earliestDate > today) { return this.earliestDate }
    if (today > this.latestDate) { return this.latestDate }

    return this.eventDate
  }
}

export default withTranslations(EventCalendar)
