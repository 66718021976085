
import date_fns_format from 'date-fns/format'
import WCC from 'wcc'
import locales from './locales'

export default function format(date: Date | string | number | undefined, formatStr: string) {
  if (date === undefined || date === null) {
    return ''
  }
  if (typeof date == 'string') {
    date = new Date(date)
  }

  const localeName = WCC.locale ||
    typeof(window) != 'undefined' ? window.navigator.language : 'en'
  return date_fns_format(date, formatStr, {
    locale: locales[localeName],
  })
}
