import * as React from 'react'

interface IAccordionItem {
  name: string,

  /** Overrides the text shown at the top of the accordion */
  title?: string

  /** Set this to determine initial collapse status (default: false) */
  show?: boolean
}

interface IProps<T extends IAccordionItem> {
  /** A unique DOM ID to which we hook up boostrap javascript events */
  id?: string

  /** An optional className applied to the accordion div */
  className?: string

  /** A set of items to render accordion cards for.  Each item must have a `name` property. */
  items: T[]

  translations: {
    /** A display text keyed off each item's `name` property. */
    [name: string]: string,
  }

  /**
   * If true, the `data-parent` attribute is set, causing only one
   * item at a time to be visible.
   *
   * https://getbootstrap.com/docs/4.1/components/collapse/#options
   */
  collapseAsGroup?: boolean

  /**
   * Executed once per item to render the interior of the card panel.
   */
  children: (item: T) => React.ReactNode
}

/**
 * Renders a Bootstrap accordion with a full-width button header controlling a div
 * around each child.  The items list must be provided as a property, and the children
 * is a function that renders the inner body of the collapsed card.
 */
export class Accordion<T extends IAccordionItem> extends React.Component<IProps<T>> {
  public static defaultProps = {
    className: 'accordion',
  }

  private defaultId: string

  constructor(props: IProps<T>, context?: any) {
    super(props, context)

    this.defaultId = `Accordion-${Math.floor(Math.random() * 10000)}`
  }

  public render() {
    const {items, className} = this.props
    const id = this.props.id || this.defaultId

    return <div className={`${className} accordion`} id={id}>
      {items.map((item, index) => this.renderCard(item, index, id))}
    </div>
  }

  private renderCard(item: T, index: number, parentId: string) {
    const {translations, children, collapseAsGroup} = this.props
    const cardId = `${parentId}-${index}`

    const {name, title, show} = item

    return <div className="accordion-card" id={cardId} key={index}>
      <button id={`${cardId}-header`}
        className={`btn btn-outline-dark w-100 dropdown-toggle`}
        type="button"
        data-toggle="collapse" data-target={`#${cardId}-panel`}
        aria-haspopup="true" aria-expanded={!!show} aria-controls={`${cardId}-panel`}>

        {
          title ||
          (translations && translations[name]) ||
          name
        }
      </button>
      <div id={`${cardId}-panel`} className={`collapse ${show ? 'show' : ''}`}
        data-parent={collapseAsGroup ? `#${parentId}` : null}
        aria-labelledby={`${cardId}-header`}>
        {children(item)}
      </div>
    </div>
  }
}
