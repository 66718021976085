// Add more imported locales as we define them
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'

export default {
  'en': enUS,
  'en-US': enUS,
  es,
} as {
  [locale: string]: object,
}
