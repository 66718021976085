import { Hit } from 'react-instantsearch-core'

/**
 * A message object in the Algolia "Messages_production" index
 */
export interface IMessageDoc {
  title: string,
  description: string,
  date: string,
  date_timestamp: number,
  date_year: string,
  series_id: number,
  series_title: string,
  series_type: { key: 'podcast' | 'messages' },
  featured_series_title: string,
  statistics: {
    daily: {
      total: number,
      average: number,
      total_magnitude: number,
      average_magnitude: number,
    },
    weekly: {
      total: number,
      average: number,
      total_magnitude: number,
      average_magnitude: number,
    },
    monthly: {
      total: number,
      average: number,
      total_magnitude: number,
      average_magnitude: number,
    },
    quarterly: {
      total: number,
      average: number,
      total_magnitude: number,
      average_magnitude: number,
    },
    yearly: {
      total: number,
      average: number,
      total_magnitude: number,
      average_magnitude: number,
    },
    total: { count: number, magnitude: number, days: number },
  },
  has_video: boolean,
  speakers: Array<{ speaker_id: number, speaker_name: string }>,
  scripture: Array<{ canon_order: number, book: string, verses: string }>,
  tags: string[],
  transcript_terms: any[],
  primary_topic: string,
  topics: any[],
  clips_rank_5: any[],
  clips_rank_4: any[],
  clips_rank_3: any[],
  ministries: string[],
  thumbnail_url: string,
  featured: boolean,
  objectID: string
}

export function isMessageHit(obj: Hit<any>): obj is Hit<IMessageDoc> {
  return isMessageDoc(obj)
}

export function isMessageDoc(obj: any): obj is IMessageDoc {
  return typeof obj == 'object' &&
    'objectID' in obj &&
    'title' in obj &&
    'date' in obj
    // TODO: more distinguishing features?
}
