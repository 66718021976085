import React from 'react'
import { Hit } from 'react-instantsearch-core'
import { ICompositeMega } from '../../../models/algolia/composite'
import { rewriteContentfulImageUrl } from '../../../util/contentfulImage'
import { Highlight } from 'react-instantsearch-dom'

interface MegaCardHeroProps {
  hit: Hit<ICompositeMega>
}

export const MegaCardHero = ({ hit }: MegaCardHeroProps) => {
  if (hit.MegaSearchResult.header.object_type != 'SectionHero') {
    return null
  }

  const { summary, image_url } = hit.MegaSearchResult.header

  const contentful_url = image_url && rewriteContentfulImageUrl(image_url, { w: 640 })

  return (
    <div className='site-search-hit__mega__header'>
      <div className="mega-card-hero">
        <div className="mega-card-hero__top">
          <img className='mega-card-hero__img' src={contentful_url} />
          <div className="titles">
            <h4><Highlight attribute='MegaSearchResult.header.title' hit={hit} /></h4>
            <h6><Highlight attribute='MegaSearchResult.header.subtitle' hit={hit} /></h6>
          </div>
        </div>
        {
          summary &&
          <p className="mega-card-hero__description formatted-content" dangerouslySetInnerHTML={ { __html: summary }}></p>
        }
      </div>
    </div>
  )
}