import { SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch, { AlgoliaSearchOptions } from 'algoliasearch'
import * as React from 'react'
import { SearchState } from 'react-instantsearch-core'
import { Configure } from 'react-instantsearch-dom'
import WCC from 'wcc'

import { CampusEventHandler, getCampus } from '../../../application/campus'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { assert, exists } from '../../util'
import AlgoliaSearch from '../algolia-search'
import { TabNavItem, TabPane } from '../bootstrap/tabs'
import DefaultFacets from './default-facets'
import HiddenFacets from './hidden-facets'
import LoadingBar from './loading-bar'
import HiddenNumericFilters from './numeric-filters'
import { ITab } from './tab'
import ConfigureInitialQuery from './tab/configure-initial-query'
import ConnectedTabViewSwitch from './tab/view-switch'
import { isEmpty } from './tab/views/utils'

interface IProps extends ITranslationsProvided {
  tabs: ITab[]
  autosuggestIndex?: string
  messagesOnly?: boolean
  
  autoFocus?: boolean

  /** disable analytics in preview mode */
  preview?: boolean
}

interface IState {
  activeTab: ITab
  visibleTabs: ITab[]
  initial: boolean
}

const opts: AlgoliaSearchOptions = {
  logger: console as any,
}
if (WCC.algoliaProxy) {
  Object.assign(opts, {
    hosts: [WCC.algoliaProxy],
  })
}
const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering',
  opts)

class SiteSearch extends React.Component<IProps, IState> {

  constructor(props: IProps, context?: any) {
    super(props, context)

    this.onTabSelected = this.onTabSelected.bind(this)
    this.clearInitial = this.clearInitial.bind(this)

    const visibleTabs = this.getVisibleTabs()
    const activeTab = visibleTabs[0]
    if (activeTab) {
      this.state = {
        activeTab,
        visibleTabs,
        initial: true,
      }
    }
  }

  public componentDidMount() {
    $(document).on('change.campus', this.onCampusChanged)

    const {initial, activeTab} = this.state
    if (initial && activeTab.on_initial != 'empty') {
      this.clearInitial()
    }
  }

  public componentWillUnmount() {
    $(document).off('change.campus', this.onCampusChanged)
  }

  public render() {
    const { t, translationsAt, messagesOnly } = this.props
    const { activeTab, visibleTabs, initial } = this.state

    const defaultIndex = activeTab.index || (activeTab.indexes && activeTab.indexes[0].id)

    assert(defaultIndex, `Unable to determine default index for tab ${activeTab.name || 'unknown'}`)

    return (
      <AlgoliaSearch indexName={defaultIndex} widgetName={`${this.props.messagesOnly ? 'messages-only' : '' } site-search`}
        storeStateInQuery={true}
        onSearchStatechange={this.onSearchStateChange}>
        {initial && activeTab.initial_hit && <ConfigureInitialQuery initialHit={activeTab.initial_hit} />}
        <div className={`row tab-header justify-content-center ${initial ? 'initial' : ''}`}>
          <div className="col-content-container">
            {!messagesOnly && <SearchBox
              defaultIndex={defaultIndex}
              autosuggestIndex={this.props.autosuggestIndex}
              autosuggestClient={autosuggestClient}
              searchAsYouType={false}
              showLoadingIndicator={true}
              autoFocus={typeof (this.props.autoFocus) == 'undefined' ? true : this.props.autoFocus }
              translations={this.props.translationsAt('searchBox') as { [key: string]: string }} />}

            <ul className="nav nav-tabs site-search-tabs" role="tablist">
              {
                this.props.tabs.map((tab) =>
                  <TabNavItem key={tab.name} id={tab.name} bookmark={t(['tabs', tab.name, 'bookmark'])}
                    active={activeTab == tab} hidden={visibleTabs.indexOf(tab) == -1}
                    translations={translationsAt(['tabs', tab.name])}
                    storeState={'path'}
                    onTabSelected={this.onTabSelected}></TabNavItem>,
                )
              }
            </ul>
          </div>
        </div>
        <div className={`row tab-content justify-content-center ${initial ? 'initial' : ''}`}>
          <div className="col-content-container">
            <LoadingBar className="site-search-loading-bar" />
            {visibleTabs.map((tab) =>
              <TabPane key={tab.name} id={tab.name}
                active={activeTab == tab}>
                {activeTab == tab && <>
                  {this.tabConfiguration(tab)}
                  <ConnectedTabViewSwitch autosuggestIndex={this.props.autosuggestIndex} messagesOnly={messagesOnly} autoFocus={this.props.autoFocus} tab={tab} initial={initial} />
                </>}
              </TabPane>,
            )}
          </div>
        </div>
      </AlgoliaSearch>
    )
  }

  private onCampusChanged: CampusEventHandler = () => {
    this.setState({
      visibleTabs: this.getVisibleTabs(undefined, getCampus()),
    })
  }

  private getVisibleTabs = (activeTab?: ITab, campus?: ICampus) => {
    activeTab = activeTab || this.state && this.state.activeTab
    const visibleTabs = this.state && this.state.visibleTabs ||
      // initial state - the always visible tabs.
      this.props.tabs.filter((t) => !t.hidden && !t.hidden_unless_campus_selected)

    // only add tabs - do not take away tabs once they have been shown to the user.

    if (campus) {
      // add in the hidden_unless_campus_selected tabs
      visibleTabs.push(...this.props.tabs.filter((t) => t.hidden_unless_campus_selected))
    }
    if (activeTab && visibleTabs.indexOf(activeTab) == -1) {
      // add in the current active tab
      visibleTabs.push(activeTab)
    }
    return visibleTabs.uniq().sort(byPositionInArray(this.props.tabs))
  }

  private onTabSelected(tabName: string) {
    const activeTab = this.props.tabs.find((t) => t.name == tabName)
    assert(activeTab, `No tab found for name ${tabName}`)
    this.setState({
      visibleTabs: this.getVisibleTabs(activeTab, getCampus()),
      activeTab,
      // When a tab changes, if we change the index before rendering the tab's
      // <Configure />, then a search fires off for the new index with the wrong
      // search parameters.  Better to change the search parameters first and search
      // the old index, because TabViewSwitch can detect when we're on the wrong
      // index and not render any results.
    })

    if (this.state.initial && activeTab.on_initial != 'empty') {
      this.clearInitial()
    }
  }

  private onSearchStateChange = (searchState: SearchState) => {
    if (this.state.initial && !isEmpty(searchState)) {
      this.clearInitial()
    }
  }

  private clearInitial() {
    this.setState({
      initial: false,
    })
  }

  private tabConfiguration(tab: ITab) {
    const {preview} = this.props
    const {initial} = this.state

    let algolia_insights = tab.algolia_insights
    if (preview) {
      algolia_insights = false
    } else if (typeof algolia_insights == 'undefined') {
      // defaults to true
      algolia_insights = true
    }

    const extraConfig = {
      distinct: tab.distinct,

      // we don't need analytics in preview mode, or for an initial page load
      clickAnalytics: algolia_insights && !preview && !initial,
      analytics: !preview && !initial,
    }

    return [
      !isEmpty(extraConfig) &&
        <Configure {...extraConfig} />,
      tab.hidden_facets &&
        <HiddenFacets hiddenFacets={tab.hidden_facets} />,
      tab.default_facets &&
        <DefaultFacets defaultFacets={tab.default_facets} />,
      tab.hidden_numeric_filters &&
        <HiddenNumericFilters hiddenNumericFilters={tab.hidden_numeric_filters} />,
    ].filter(exists)
  }
}

export default withTranslations(SiteSearch)

function byPositionInArray<T>(arr: T[]): (a: T, b: T) => number {
  return (a, b) => {
    return arr.indexOf(a) - arr.indexOf(b)
  }
}
