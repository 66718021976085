import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ICompositePerson } from '../../../models/algolia/composite'
import { TableViewOptions } from './any-hit'
import { HitTableRow } from './hit-table-row'

interface ITableViewProps extends TableViewOptions {
  hit: Hit<ICompositePerson>,
}

export class PersonHitTableView extends React.PureComponent<ITableViewProps> {

  public render() {
    const { hit } = this.props
    const { Person } = hit
    // has_profile_page should default to true
    const slug = Person.has_profile_page !== false ? `/people/${Person.slug}` : undefined

    return <HitTableRow
      hit={hit}
      slug={slug}
      thumbnailUrl={Person.profile_image_url}
      thumbnailAlt={Person.profile_image_alt_text}
      title={<Highlight attribute="Person.full_name" hit={hit} />}
      className="site-search-hit__person"
    >
      <span className="meta-data">
        <Highlight attribute="Person.title" hit={hit} />
      </span>
    </HitTableRow>
  }
}
