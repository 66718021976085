import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ICompositePerson } from '../../models/algolia/composite'
import { pickHit } from '../SiteSearch/hits'

const Person = (props: { hit: Hit<ICompositePerson> }) => {
  const { hit } = props

  const person = pickHit(hit, 'Person')
  // has_profile_page should default to true
  const slug = person.has_profile_page !== false ? `/people/${person.slug}` : undefined

  return (
    <div className="col-9 col-sm-3 mb-4">
      <a href={ slug } className="card a-block person-card hover-grow">
        <div className="grow" >
          <img src={`${person.profile_image_url}?w=600&fm=jpg`} className="card-img-top"
            alt={person.profile_image_alt_text || person.full_name} />
        </div>
        <div className="card-body">
          <h6 className="card-title"><Highlight attribute="full_name" hit={person} /></h6>
          <p className="card-text"><Highlight attribute="title" hit={person} /></p>
        </div>
      </a>
    </div>
  )
}

export { Person }
