import * as React from 'react'
import {
  connectCurrentRefinements,
  connectStateResults,
  CurrentRefinementsProvided,
  StateResultsProvided,
} from 'react-instantsearch-core'
import ConnectedClearRefinements from './clear-refinements'

interface IProps {
  translations?: {
    searchResetMsg?: string,
    searchResetAction?: string,
    clearRefinementsMsg?: string,
    clearRefinementsAction?: string,
  },
}

class SearchReset extends React.PureComponent<IProps &
  StateResultsProvided & CurrentRefinementsProvided
  > {
  public render() {
    const { searchResults, items, refine } = this.props
    const translations = this.props.translations || {}

    if (!searchResults || searchResults.nbHits > 0) {
      return null
    }

    if (items && items.length > 0) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="col-content-container formatted-content">
            <ConnectedClearRefinements translations={translations} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="row justify-content-center mt-5">
          <div className="col-content-container formatted-content">
            <p>
              {translations.searchResetMsg || 'There are no results.'}
              &nbsp;
              <a href="#" onClick={(evt) => {
                evt.preventDefault()
                refine((ss) => {
                  return {
                    ...ss,
                    query: '',
                  }
                })
                setTimeout(() => {
                  $('.ais-SearchBox-input').focus()
                }, 400)
              }}>
                {translations.searchResetAction || 'Please try again.'}
              </a>
            </p>
          </div>
        </div>
      )
    }
  }
}

const ConnectedSearchReset = connectStateResults(
  connectCurrentRefinements(
    SearchReset,
  ),
)

export default ConnectedSearchReset
