
/*
 * Parse a date string into a Date assuming that it is in local time (not UTC)
 * https://stackoverflow.com/a/42626876/2192243
 */
export function parseDateISOString(str: string) {
  const ds = str.split(/\D/).map((s) => parseInt(s, 10))
  ds[1] = ds[1] - 1 // adjust month
  return new Date(...ds as [number, number])
}
