import * as React from 'react'

import { InfiniteSearchResults } from '../../../infinite-search-results'
import { AnyHitTableView } from '../../hits/any-hit'

export const TableTabView = () => (
  <InfiniteSearchResults
    wrapperClass="infinite-hits w-100 table-view"
    hitComponent={AnyHitTableView} />
)
