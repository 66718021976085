import * as React from 'react'
import { connectSortBy, SortByProvided } from 'react-instantsearch-core'

declare module 'react-instantsearch-core' {
  
  interface SortByItem { value: string, label: string }

  interface SortByExposed {
    /** The list of indexes to search in. */
    items: SortByItem[]
    /** The default selected index. */
    defaultRefinement: string
    /**
     * Function to modify the items being displayed, e.g. for filtering or sorting them.
     * Takes an items as parameter and expects it back in return.
     */
    transformItems?: (items: Array<SortByItem & { isRefined: boolean }>) => Array<SortByItem & { isRefined: boolean }>
  }

  interface SortByProvided {
    refine: (index: string) => string

    createURL: (index: string) => string

    /** the refinement currently applied */
    currentRefinement: string

    items: Array<SortByItem & { isRefined: boolean }>
  }

  export function connectSortBy(
    stateless: React.StatelessComponent<SortByProvided>,
  ): React.ComponentClass<InfiniteHitsProvided>
  export function connectSortBy<TProps extends Partial<SortByProvided>>(
    Composed: React.ComponentType<TProps>,
  ): ConnectedComponentClass<TProps, SortByProvided, SortByExposed>
}

type IProps = SortByProvided & {
  className?: string,
}

class IndexSelect extends React.PureComponent<IProps> {

  constructor(props: IProps, context?: any) {
    super(props, context)
  }

  public render() {
    const {refine, items} = this.props
    const currentItem = items.find((x) => x.isRefined)
    const className = this.props.className || 'search-index-select'

    return <div className={className}>
      <div className="dropdown">
        <button className="btn btn-outline-dark dropdown-toggle"
          id={`${className}-button`} type="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

          {currentItem &&
            currentItem.label}
        </button>
        <div className="dropdown-menu dropdown-menu-center" aria-labelledby={`${className}-button`}>
          {items.map((index) =>
            <button key={index.value} type="button" className="dropdown-item btn btn-lg"
              onClick={() => refine(index.value)}
              data-value={index.value}>

              {index.label}
            </button>)}
        </div>
      </div>
    </div>
  }
}

const ConnectedIndexSelect = connectSortBy(IndexSelect)
export default ConnectedIndexSelect
