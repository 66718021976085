import React from 'react'
import { Helmet } from 'react-helmet'

import { Player, PlayerAPI, PlayerConfig, PlayerEvent, SourceConfig, StaticPlayerAPI } from 'bitmovin-player'
import { UIFactory } from 'bitmovin-player-ui'
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr'
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4'
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts'
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash'
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin'
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls'
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer'
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill'
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style'
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles'
import SubtitlesCEA608Module from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608'
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml'
import { IStreamEvent } from '../live-stream'

Player.addModule(EngineBitmovinModule)
Player.addModule(MseRendererModule)
Player.addModule(HlsModule)
Player.addModule(XmlModule)
Player.addModule(DashModule)
Player.addModule(AbrModule)
Player.addModule(ContainerTSModule)
Player.addModule(ContainerMp4Module)
Player.addModule(SubtitlesModule)
Player.addModule(SubtitlesCEA608Module)
Player.addModule(PolyfillModule)
Player.addModule(StyleModule)

interface IProps {
    title: string
    description: string
    hls: string
    poster?: string

    /** Inject the Bitmovin API */
    Player: StaticPlayerAPI

    onPlayerCreated?: (player: PlayerAPI) => void
    onPlayerDestroyed?: (player: PlayerAPI) => void
}
interface IState {
    player?: PlayerAPI | null
    playerEvent?: PlayerEvent
    streamEvent?: IStreamEvent
    playerState?: PlayerState

    error?: Error
    persistentError?: boolean
}

enum PlayerState {
    loading,
    waiting,
    playing,
    done,
}
export class BitmovinPlayer extends React.Component<IProps, IState> {

  public playerConfig: PlayerConfig = {
    key: 'f6db6f0b-50d5-4059-bad8-190eb8c46e01',
    logs: {
      // level: LogLevel.DEBUG
    },
    playback: {
      autoplay: true,
      muted: false,
    },
    cast: {
      enable: true,
    },
    events: {
      [PlayerEvent.SourceLoaded]: data => {
        console.log(data) 
        if((this.state.player && !this.state.player.isLive())){
          // Prevents the player from playing audio while it is hidden.
          this.state.player.mute()
        } else if(this.state.player) {
          this.state.player.unmute()
        }
      } 
    },
    ui: false,
  }

  public playerSource: SourceConfig = {
    title: this.props.title,
    description: this.props.description,
    hls: this.props.hls,
    poster: this.props.poster,
  }
  private playerDiv: React.RefObject<HTMLElement>

  constructor(props: IProps) {
    super(props)
    this.playerDiv = React.createRef()
  }

  public async componentDidMount() {
    this.setupPlayer()
  }

  public componentWillUnmount() {
    this.destroyPlayer()
  }

  public setupPlayer() {
    console.log('setupPlayer')
    // Use injected PlayerAPI if provided, otherwise use default
    const PlayerImpl = this.props.Player

    if (this.playerDiv.current) {
      const player = new PlayerImpl(this.playerDiv.current, this.playerConfig)
      UIFactory.buildModernUI(player)
      player.load(this.playerSource).then(() => {
        this.setState({
          ...this.state,
          player,
        })
                
        console.log('Successfully loaded source')
        if (this.props.onPlayerCreated) {
          this.props.onPlayerCreated(player)
        } else {
          console.log('Player failed to be created!')
          console.log(player)
        }
      }, () => {
        console.log('Error while loading source')
      })
    }

  }

  public destroyPlayer() {
    if (this.state.player != null) {
      this.state.player.destroy()
      if (this.props.onPlayerDestroyed) {
        this.props.onPlayerDestroyed(this.state.player)
      } else {
        console.log('Player failed to be destroyed!')
      }
      this.setState({
        ...this.state,
        player: null,
      })
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Helmet>
          <link rel="stylesheet" href="//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css" />
          <style>{`.bmpui-ui-watermark { display: none; } .bmpui-ui-titlebar { display: none; }`}</style>
        </Helmet>
        <div id="player" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} ref={this.playerDiv as any}/>
      </React.Fragment>
    )
  }
}
