import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connectInfiniteHits, InfiniteHitsProvided } from 'react-instantsearch-core'

interface IProps {
  hitComponent: React.ComponentType<{ hit: any }>
  wrapperClass?: string
  element?: string
}

class InfiniteHits extends React.Component<IProps & InfiniteHitsProvided> {
  public render() {
    const { hasMore, wrapperClass, element, refineNext, hitComponent: HitComponent, hits } = this.props

    return <InfiniteScroll
      className={ wrapperClass || 'infinite-hits' }
      hasMore={ hasMore }
      loadMore={ () => {
        setTimeout(() => refineNext(), 0)
      } }
      element={ element || 'div' }
    >
      { hits.map((hit) =>
        <HitComponent hit={hit} key={`infinite-hit-${hit.objectID}`}/>,
      )}
    </InfiniteScroll>
  }
}

const InfiniteSearchResults = connectInfiniteHits(InfiniteHits)

export { InfiniteSearchResults }
