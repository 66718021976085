import * as React from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'
import { withTranslations } from '../../../connectors/translation'
import { Reject } from '../../../util/reject'
import { Props as SortedTableViewProps, SortedTableView } from './sorted-table-view'

type Props = Reject<SortedTableViewProps, 'sortItems'>

export class YearTableView extends React.Component<Props> {
  public render() {
    return <SortedTableView {...this.props}
      sortItems={(items, allItems) => {
        // current year always goes in the shown slice of the dataset
        const currentYear = (new Date()).getFullYear().toString()
        const currentYearIndex = items.findIndex((val) => val.label == currentYear)
        if (currentYearIndex < 0) {
          const current = allItems.find((val) => val.label == currentYear)
          if (current) {
            items = [current, ...items.slice(0, items.length - 1)]
          }
          // else current year doesnt exist in the dataset
        }
        return items.sort(byLabelDescending)
      }} />
  }
}

function byLabelDescending(a: { label: string }, b: { label: string }): number {
  return b.label.localeCompare(a.label)
}

export default connectRefinementList(
  withTranslations(YearTableView),
)
