import React from 'react'
import { HiddenFacets } from '@watermarkchurch/react-instantsearch-components'
import AlgoliaSearch from '../algolia-search'
import { IRefinementDefinition } from '../SiteSearch/tab'
import { Configure, SearchBox } from 'react-instantsearch-dom'
import { ArchiveSearchResults } from './results'
import { TagSelect } from './tag-select'
import { ScriptureSelect } from './scripture-select'


interface IProps {
  indexName: string,
  hiddenFacets?: IRefinementDefinition,
  title?: string,
  description?: string,
  translations: {
    search_input: {
      placeholder: string,
    }
    tag_select: {
      see_all_option: string,
      noResults: string,
    },
    scripture_select: {
      see_all_option: string,
      noResults: string,
    }
  }
}

export function ArchiveSearch(props: IProps) {
  const { indexName, hiddenFacets, translations, title, description } = props
  const [year, setYear] = React.useState('')
  const [isTyping, setIsTyping] = React.useState(false)

  return (
    <>
      <div className='breadcrumb-container'>
        <div className='row justify-content-center'>
          <div className='col-content-container'>
            <nav aria-label='breadcrumb'>
              <ol className='overline breadcrumb d-flex'>
                <li className='breadcrumb-item'><a href='/'>Home</a></li>
                <li className='breadcrumb-item'><a href='/messages'>Messages</a></li>
                <li className={`breadcrumb-item ${year !== '' ? '' : 'active'}`} aria-current='page'>
                  <a className='' href='/archive'>Archive</a>
                </li>
                {year !== '' && <li className='breadcrumb-item active' aria-current='page'>{year}</li>}
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <AlgoliaSearch
        indexName={indexName}
        storeStateInQuery
        widgetName="archive-search"
        onSearchStatechange={(state) => {
          if(state.menu && state.menu['date_year']) {
            setYear(state.menu['date_year'])
          } else {
            setYear('')
          }
        }}>
        <Configure analytics={false} />
        {hiddenFacets && <HiddenFacets hiddenFacets={hiddenFacets} />}
        <div className='row'>
          <div className='col-content-offset col-content-narrow'>
            <h2 className='archive-search__title'>
              {
                [title || 'Message Archive', year].filter(Boolean).join(' - ')
              }
            </h2>
          </div>
          {description && <div className='col-content-offset col-content-narrow archive-search__description'>
            <p>{description}</p>
          </div>}
        </div>
        <div className="row justify-content-center">
          <div className='col-12 col-lg-10 col-xl-8'>
            <div className="row archive-search__container">
              <div className="col-12 col-md-5 archive-search__search-box">
                <SearchBox
                  onChange={(event) => setIsTyping(event.currentTarget.value !== '')}
                  onReset={() => setIsTyping(false)}
                  reset={<i className='material-icons'>close</i>}
                  submit={<i className='material-icons' hidden={isTyping}>search</i>}
                  translations={translations.search_input}
                  searchAsYouType={false}/>
              </div>
              <div className='archive-search__filters col-md-6 col-12'>
                <div className='col-5 archive-search__scripture-select'>
                  <ScriptureSelect
                    attribute='scripture.book'
                    limit={66}
                    translations={translations.scripture_select}
                    defaultRefinement={scriptureRefinement()}
                  />
                </div>
                <div className='col-7 archive-search__tag-select'>
                  <TagSelect
                    attribute='tags'
                    translations={translations.tag_select}
                    defaultRefinement={tagRefinement()}
                  />
                </div>
              </div>
            </div>
            <ArchiveSearchResults />
          </div>
        </div>
      </AlgoliaSearch>
    </>
  )
}

function scriptureRefinement(): string {
  if(window.location.search) {
    const params = new URLSearchParams(window.location.search)
    const scripture = params.get('scripture')
    if(scripture) {
      return scripture
    }
  }

  return ''
}

function tagRefinement(): string {
  if(window.location.search) {
    const params = new URLSearchParams(window.location.search)
    const tag = params.get('ministry')
    if(tag) {
      return tag
    }
  }

  return ''
}
