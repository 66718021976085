import * as React from 'react'

import { connectRefinementList } from 'react-instantsearch-dom'
import WCC from 'wcc'
import { withTranslations } from '../../../connectors/translation'
import { Reject } from '../../../util/reject'
import { Props as SortedTableViewProps, SortedTableView } from './sorted-table-view'

type Props = Reject<SortedTableViewProps, 'transformItems' | 'sortItems'>

export class CampusTableView extends React.Component<Props> {
  public render() {
    const {
      items,
      ...props
    } = this.props

    return <SortedTableView {...props} items={items.map(this.transformItem)} />
  }

  private transformItem = <T extends IItem>(item: T): T => {
    const campus = WCC.activeCampuses.find((c) => c.code == item.label)
    if (campus) {
      return {
        ...item,
        label: campus.name,
      }
    }
    return item
  }
}

interface IItem {
  label: string,
}

export default connectRefinementList(
  withTranslations(CampusTableView),
)
