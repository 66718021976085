import React, { useEffect } from 'react'

export interface ICarouselProps {
  className?: string
  settings?: CarouselSettings
  children: React.ReactNode
}

export interface CarouselSettings {
  slidesToShow?: number
  slidesToScroll?: number
  speed?: number
}

export default function Carousel({settings, children}: ICarouselProps) {
  const [index, setIndex] = React.useState(0)
  const length = React.Children.count(children)
  const trackRef = React.useRef<HTMLDivElement>(null)

  const itemsPerPage = settings?.slidesToShow || 4
  const itemWidthPercent = 100 / itemsPerPage
  const translateAmount = -index * itemWidthPercent

  const isFirstItemVisible = index === 0
  const isLastItemVisible = index === React.Children.count(children) - itemsPerPage

  useEffect(() => {
    if (trackRef.current && length > 0) {
      trackRef.current.style.width = `${length * itemWidthPercent}%`
    }
  }, [trackRef, length])


  const handlePrev = () => {
    const newIndex = index - (settings?.slidesToScroll || 1)
    setIndex(newIndex < 0 ? length - 1 : newIndex)
  }

  const handleNext = () => {
    const newIndex = index + (settings?.slidesToScroll || 1)
    setIndex(newIndex >= length ? 0 : newIndex)
  }

  const renderChildren = () => {
    return React.Children.map(children, (child, i) => {
      const isCurrentSlide = i === index
      const isActive = i >= index && i < index + itemsPerPage

      return React.cloneElement(child as React.ReactElement<any>, { isActive, isCurrentSlide })
    })
  }

  const trackStyle = {
    transform: `translate3d(${translateAmount}%, 0, 0)`,
    transition: `transform ${(settings?.speed || 250)}ms ease`,
  }

  return (
    <div className='carousel'>
      <button className={`carousel__button carousel__button--prev ${isFirstItemVisible ? 'disabled' : ''}`} onClick={handlePrev} disabled={isFirstItemVisible} />
      <div className='carousel__container row'>
        <div ref={trackRef} className='carousel__track' style={trackStyle}>
          {renderChildren()}
        </div>
      </div>
      <button className={`carousel__button carousel__button--next ${isLastItemVisible ? 'disabled' : ''}`} onClick={handleNext} disabled={isLastItemVisible} />
    </div>
  )
}
