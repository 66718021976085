const dateFormat: RegExp = new RegExp(/\d{4}-\d{2}-\d{2}/)

const isDate = (str: string | null): boolean => {
  if (!str) {
    return false
  }
  return dateFormat.test(str)
}

export { isDate }
