import * as React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { exists } from '../../util'
import aa, { getInsightsData } from '../algolia-search/insights'

interface IProps {
  className?: string
}

export class HrefModifier extends React.Component<StateResultsProvided & IProps> {
  private readonly ref: React.RefObject<HTMLDivElement>
  private interval?: ReturnType<typeof setTimeout>

  constructor(props: HrefModifier['props']) {
    super(props)
    this.ref = React.createRef()
  }

  public componentDidUpdate() {
    setTimeout(this.findAndModifyHrefs, 1)

    clearInterval(this.interval)
    this.interval = setInterval(this.findAndModifyHrefs, 100)
  }

  public componentWillUnmount() {
    clearInterval(this.interval)
    this.interval = undefined
  }

  public render() {
    return <div key="HrefModifier" className={this.props.className || `HrefModifier`} ref={this.ref}
      onClick={(e) => this.onClick(e)}>
      {this.props.children}
    </div>
  }

  private onClick(e: React.MouseEvent) {
    const { index } = this.props.searchResults

    const current = this.ref.current
    if (!current) { return }

    for (const target of $(e.target).parentsUntil(current).toArray()) {
      const data = getInsightsData($(target))
      // if they don't specify query ID, then insights aren't configured for this query
      if (data && data.queryId) {
        const payload = {
          eventName: `click${data.objectType}`,
          index,
          objectIDs: [data.objectId],
          queryID: data.queryId,
          positions: [data.position].filter(exists),
        }
        aa('clickedObjectIDsAfterSearch', payload)

        // break early - only one analytics event regardless of nesting
        return
      }
    }
  }

  private findAndModifyHrefs = () => {
    const {current} = this.ref

    if (!current) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    $(current).find('[href], [data-href]').each(function() {
      $(this).addClass('HrefModifier')
      const $this = $(this)
      if ($this.prop('href')) {
        const newHref = self.modifyHref($this.prop('href'))
        $this.prop('href', newHref)
      }

      if ($this.data('href')) {
        const newHref = self.modifyHref($this.data('href'))
        $this.data('href', newHref)
      }
    })
  }

  private modifyHref(href: string): string {
    const {searchState} = this.props

    const url = new URL(href, document.baseURI)
    url.searchParams.set('q', searchState.query || '')

    return url.toString()
  }
}

const ConnectedHrefModifier = connectStateResults(HrefModifier)
export default ConnectedHrefModifier
