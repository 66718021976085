import * as React from 'react'
import WCC from 'wcc'

import addDays from 'date-fns/addDays'
import { getCampus } from '../../../application/campus'
import { IEventOccurrence, isFeaturedEvent } from '../../models/events'
import { Stringable } from '../../util'
import { AsyncErrorHandler } from '../../util/async-error-handler'
import Event from './event'
import { LoadingEvent } from './loading-event'

interface IProps {
  title: string
  translations: { error: string, blank_slate: string }

  campus_aware?: boolean
  campus_id?: Stringable
  days_ahead?: number
  event_class?: string
  limit?: number
  open_registration?: boolean
  promote_featured_events?: boolean
  series_id?: Stringable,
  tag_ids?: Stringable[] | Stringable
  unique?: boolean
  viewAllLink?: string
  wrapper_class?: string
}

interface IState {
  events: IEventOccurrence[]
  initialized: boolean

  wait?: boolean
  error?: any
}

interface IEventsQueryResp {
  pagination: {
    'total': number,
    'size': number,
    'limit': number,
    'offset': number,
    'order_by': string,
    'sort': string,
    'filter'?: {
      [key: string]: string,
    },
    '_links': {
      'self': string,
      'next': string,
    },
  },
  events: IEventOccurrence[]
}

export default class EventsList extends React.Component<IProps, IState> {
  private static apiEndpoint = '/api/v1/events'

  constructor(props: EventsList['props']) {
    super(props)

    this.state = {
      events: [],
      initialized: false,
    }

    this.loadEvents = new AsyncErrorHandler(this).wrap(this, this.loadEvents)
  }

  public render() {
    const { error, initialized } = this.state

    if (error) {
      return <div className="row">
        <div className="col-12">
          <h2 className="widget-events-list__heading">{this.props.translations.error}</h2>
        </div>
      </div>
    }

    const featuredEvents = this.state.events.filter(isFeaturedEvent).slice(0, 1)
    const events = this.state.events.slice(featuredEvents.length)

    return <>
      <div className="widget-events-list__heading">
        <h2>{this.props.title}</h2>
        {this.props.viewAllLink &&
          <a href={this.props.viewAllLink} className="btn btn-link">View All</a>}
      </div>
      <div className={`row ${this.props.wrapper_class}`}>
        {!initialized && new Array(this.props.limit || 20).fill({}).map((_, i) =>
          <div className={this.props.event_class || 'col-12'} key={i}>
            <LoadingEvent key={i} />
          </div>,
        )}
        {initialized && featuredEvents.map((event) =>
          <div className={this.props.event_class || 'col-12'} key={event.id}>
            <Event featured={true} event={event} />
          </div>,
        )}
        {initialized && events.map((event) =>
          <div className={this.props.event_class || 'col-12'} key={event.id}>
            <Event event={event} />
          </div>,
        )}
        {initialized && !this.state.events.length &&
          <div className="col-12">
            <div className="blank-slate justify-content-center d-flex flex-column text-center alert-light">
              {this.props.translations.blank_slate}
            </div>
          </div>}
      </div>
    </>
  }

  public componentDidMount() {
    
    this.loadEvents()
  }

  private async loadEvents(): Promise<void> {
    const params = this.apiParams()
    const urlWithParams = EventsList.apiEndpoint + '?' + $.param(params)
    const response = await fetch(urlWithParams)

    const resp: IEventsQueryResp = await response.json()

    this.setState({
      events: resp.events,
      initialized: true,
    })
  }

  private apiParams() {
    const now = roundDownTo5MinutesBoundary(new Date())

    const filter: any = {
      time_gt: now.toISOString(),
      time_lt: this.props.days_ahead ? addDays(now, this.props.days_ahead).toISOString() : '',
    }

    if (this.props.unique) {
      filter.unique = '1'
    }

    if (this.props.campus_id) {
      filter.campus_id = this.props.campus_id.toString()
    } else if (this.props.campus_aware) {
      // campus param first, then cookie
      const campus = getCampus(WCC.currentCampusSlug) || getCampus()

      if (campus) {
        filter.campus_id = campus.event_system_id
      }
    }

    if (this.props.tag_ids) {
      filter.tag_id = this.props.tag_ids.toString()
    }

    if (this.props.series_id) {
      filter.series_id = this.props.series_id.toString()
    }

    if (this.props.open_registration) {
      filter.event_registration_state = 'open'
    }

    return {
      limit: this.props.limit || 20,
      filter,
      order_by: this.props.promote_featured_events !== false && 'featured',
    }
  }
}

function roundDownTo5MinutesBoundary(now: Date): Date {
  now.setSeconds(0, 0)
  // (16 % 5) = 1; 16 - 1 = 15
  const minutes = now.getMinutes() - (now.getMinutes() % 5)
  now.setMinutes(minutes)
  return now
}
