import * as React from 'react'

export class LoadingEvent extends React.PureComponent {

  public render() {
    return <a
      className={
        `a-block card widget-events-list__event-card loading`
      }
    >
      <div className="row">
        <div className="col widget-events-list__event-card__overline">
        </div>
      </div>
      <div className="row">
        <div className="col widget-events-list__event-card__date">
          <div className="widget-events-list__event-card__date-box">
            <h6 className="widget-events-list__event-card__date-box__month"></h6>
            <h6 className="widget-events-list__event-card__date-box__day"></h6>
          </div>
        </div>
        <div className={
          `col widget-events-list__event-card__meta-box`
        }>
          <span className="overline">
          &nbsp;
          </span>
          <h5 className="event-title">
          &nbsp;
          </h5>
          <span className="widget-events-list__event-card__meta-box__data-point event-time">
          </span>
        </div>
        <div className="col col-md-3 d-none d-md-block text-center widget-events-list__event-card__view-details">
        </div>
      </div>
    </a>
  }
}
