import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ICompositePerson } from '../../../models/algolia/composite'
import { BlockViewOptions } from './any-hit'
import { HitBlock } from './hit-block'

interface IBlockViewProps extends BlockViewOptions {
  hit: Hit<ICompositePerson>,
}

export class PersonHitBlockView extends React.PureComponent<IBlockViewProps> {

  public render() {
    const { hit } = this.props
    const { Person } = hit
    // has_profile_page should default to true
    const slug = Person.has_profile_page !== false ? `/people/${Person.slug}` : undefined

    return <HitBlock
      slug={slug}
      thumbnailUrl={Person.profile_image_url}
      thumbnailAlt={Person.profile_image_alt_text}
      aspectRatio="1by1"
      title={<Highlight attribute="Person.full_name" hit={hit} />}
      className="site-search-hit__person"
      {...this.props}
    >
      <span className="meta-data">
        <Highlight attribute="Person.title" hit={hit} />
      </span>
    </HitBlock>
  }
}
