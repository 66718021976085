import {clearRecurring, RecurrenceTimeout, setRecurring} from 'async-toolbox'
import React from 'react'

import { Player, PlayerAPI, SourceConfig, StaticPlayerAPI } from 'bitmovin-player'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { assert, Stringable } from '../../util'
import { AsyncErrorHandler } from '../../util/async-error-handler'
import { ConnectionError, getLiveStream, ILiveStream, IStreamEvent } from '../live-stream'
import { BitmovinPlayer } from './bitmovin-player'
import { Timer } from './timer'
import { CountdownContainer, topOfMinute } from './util'
import { TemplateFormatter } from './timer/formatter'

interface IProps {
  id: Stringable
  labelText?: string
  template?: string

  onRefresh?: () => void

  fetch?: typeof window.fetch
  t: ITranslationsProvided['t']

    /** Inject the Bitmovin API */
  Player?: StaticPlayerAPI
}
interface IState {
  liveStream?: ILiveStream
  currentEvent?: IStreamEvent
  isLive?: boolean

  error?: Error
  persistentError?: boolean
}

export class LiveStreamCountdown extends React.Component<IProps, IState> {
  private interval?: RecurrenceTimeout
  private _fetch: typeof window.fetch
  private errorHandler: AsyncErrorHandler
  private persistentErrorTimeout?: ReturnType<typeof setTimeout>
  private player?: PlayerAPI
  private playerSource: SourceConfig | null

  constructor(props: IProps) {
    super(props)

    const fetchToUse = props.fetch || (typeof window != 'undefined' ? window.fetch : undefined)
    assert(fetchToUse, 'props.fetch is missing, and could not find fetch on window')
    this._fetch = fetchToUse
    this.state = {}
    this.errorHandler = new AsyncErrorHandler(this)
    this.refresh = this.errorHandler.wrap(this, this.refresh)
    this.playerSource = null
  }

  public componentDidMount() {
    const _ = this.refresh()
  }

  public componentWillUnmount() {
    clearRecurring(this.interval)
    clearTimeout(this.persistentErrorTimeout)
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.error && !prevState.error) {
      // start checking every 5 sec instead of every 15
      clearRecurring(this.interval)
      this.interval = setRecurring(this.refresh, 5000)

      // after 15 seconds (2x 5s interval, 3 total errors in a row) notify the user
      this.persistentErrorTimeout = setTimeout(() => {
        if (this.state.error) {
          this.setState({
            persistentError: true,
          })
        }
      }, 15000)
    } else if (!this.state.error && this.state.persistentError) {

      clearTimeout(this.persistentErrorTimeout)
      // go back to checking every 15 seconds
      clearRecurring(this.interval)
      this.interval = setRecurring(this.refresh, 15000)
      this.setState({
        persistentError: false,
      })
    }
  }

  public render() {
    const { t } = this.props
    const { liveStream, currentEvent, error, persistentError } = this.state

    if (error && !(error instanceof ConnectionError)) {
      console.log('error', error)
      // bail on non-connection errors
      throw error
    }
    // if it's a connection error, and it's persistent, say something below.

    if (!liveStream) {
      console.log('no live stream')
      return <CountdownContainer>
        <h2 className="countdown-timer__label-text">{this.props.labelText}</h2>
        {persistentError &&
          <div className="error">{t('connectionError')}</div>}
      </CountdownContainer>
    }

    const { next_event } = liveStream
    const currentOrNextEvent = currentEvent || next_event
    if (!currentOrNextEvent) {
      console.log('no current or next')
      return <CountdownContainer>
        <h2 className="countdown-timer__label-text">
          {t('streamEnded')}
        </h2>
      </CountdownContainer>
    }

    const showPlayer = currentEvent || this.state.isLive
    console.log('show bitmovin player: ', showPlayer)
    return <>
      <div data-testid="player" className="embed-responsive embed-responsive-16by9"
        style={{ display: showPlayer ? 'block' : 'none'}}>
        <BitmovinPlayer
          title={currentOrNextEvent.title}
          description={currentOrNextEvent.description}
          hls={currentOrNextEvent.stream_url}
          poster={currentOrNextEvent.images && currentOrNextEvent.images.wide && currentOrNextEvent.images.wide.url}
          Player={this.props.Player || Player}
          onPlayerCreated={this.onPlayerCreated.bind(this)}
          onPlayerDestroyed={this.onPlayerDestroyed.bind(this)}/>
      </div>

      {!showPlayer && <Timer
        key={`ls-${liveStream.id}-timer-ending-${currentOrNextEvent.starts_at}`}
        target={topOfMinute(new Date(currentOrNextEvent.starts_at))}
        onExpiry={this.refresh}
        onRefresh={this.props.onRefresh}
        labelText={this.props.labelText}
        formatter={this.props.template ? TemplateFormatter(this.props.template, { allowHtml: true }) : undefined}
      >
        {persistentError &&
              <div className="error">{t('connectionError')}</div>}
      </Timer>}
    </>

  }

  public async refresh() {
    const liveStream = await getLiveStream(this.props.id, this._fetch)
    console.log('refresh live stream: ', liveStream)
    if (!liveStream) {
      this.setState({
        error: new Error(`Live stream ${this.props.id} cannot be found!`),
      })
      return
    }

    console.log('Is live? ' + this.state.isLive)

    // This reloads the source
    if (!this.state.isLive && this.player) {
      this.playerSource = this.player.getSource()
      if (this.playerSource) {
        this.player.load(this.playerSource)
        console.log('source reloaded')
      }
    }

    const newState: Partial<IState> = {
      liveStream,
      currentEvent: liveStream.current_event,
      isLive: this.player && this.player.isLive(),
    }

    this.setState(newState)
  }

  private async onPlayerCreated(player: PlayerAPI) {
    console.log('onPlayerCreated')
    this.player = player
    try {
      await this.refresh()
      this.interval = setRecurring(this.refresh, 15000)
    } catch (ex) {
      console.error('Error!', ex)
    }
  }

  private async onPlayerDestroyed() {
    this.player = undefined
    clearRecurring(this.interval)
  }
}

export default withTranslations(LiveStreamCountdown, {
  connectionError: 'We\'re having a little trouble connecting right now. Please doublecheck your internet connection.',
})
