import { DomElement } from 'html-react-parser'
import * as React from 'react'
import { assert } from '.'
import HTMLReactParser from 'html-react-parser'

const fetchSectionHTML = async (id: string | number, index = 0) =>
  (await fetch(`/sections/${id}?index=${index}`)).text()

const renderSection = (section: { html: string }) => {
  const ujs = window.ReactRailsUJS

  return HTMLReactParser(section.html, {
    replace: (domNode: DomElement) => {
      if (domNode.attribs && domNode.attribs[ujs.CLASS_NAME_ATTR]) {
        return hydrate(domNode)
      }
    },
  })
}

function hydrate(node: DomElement) {
  const ujs = window.ReactRailsUJS
  const className = node.attribs && node.attribs[ujs.CLASS_NAME_ATTR]
  assert(className, `Missing attribute ${ujs.CLASS_NAME_ATTR} on ${node.name || 'unknown dom element'}`)
  let constructor = ujs.getConstructor(className) as any
  if ('then' in constructor && typeof constructor.then == 'function') {
    // we're lazily requiring the constructor
    constructor = wrapAsyncRequire(Promise.resolve(constructor))
  }
  const propsJson = node.attribs && node.attribs[ujs.PROPS_ATTR]
  assert(className, `Missing attribute ${ujs.PROPS_ATTR} on ${node.name || 'unknown dom element'}`)
  const props = propsJson && JSON.parse(propsJson)

  if (!constructor) {
    throw new Error('Cannot find component: \'' + className + '\'' +
      '. Make sure your component is available to render.')
  }

  return React.createElement(constructor, props)
}

type ReactModule =
  { default: React.ComponentType } |
  React.ComponentType

function wrapAsyncRequire(
  lazyConstructor: Promise<ReactModule>,
): React.ComponentType {
  return class extends React.Component<any, { Constructor?: React.ComponentType, error?: Error }> {
    public displayName = `wrapAsyncRequire(...)`

    public componentDidMount() {
      lazyConstructor.then((module) => {
        const Constructor = 'default' in module ? (module.default || module) : module
        this.setState({ error: undefined, Constructor })
        this.displayName = `wrapAsyncRequire(${Constructor.displayName || 'unknown'})`
      })
        .catch((err) => this.setState({ error: err, Constructor: undefined }))
    }

    public render() {
      const { error, Constructor } = this.state || ({} as this['state'])
      if (error) {
        // allow the ErrorBoundary to handle it
        throw error
      }

      if (!Constructor) {
        return <div className="loading-bar"></div>
      }

      return <Constructor {...this.props} />
    }
  }
}

export {
  fetchSectionHTML,
  renderSection,
}
